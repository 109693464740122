import { useNotifications as useModuleNotifications } from '@sugg-gestion/common-graph-api-module'
import { setLoading, setNotifications } from 'core/store/common/actions'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

export const useNotifications = (neededPageSize?: number) => {
    const dispatch = useDispatch()

    const pageSize = neededPageSize && neededPageSize > 10 ? neededPageSize : 10
    const { data, loading, hasMore, fetchNext } = useModuleNotifications(pageSize)
    const notifications = useMemo(() => data?.notifications?.notifications ?? [], [data])

    useEffect(() => {
        dispatch(setLoading(loading))
    }, [dispatch, loading])

    useEffect(() => {
        dispatch(setNotifications(notifications))
    }, [dispatch, notifications])

    const setNotificationsPage = (page: number, setPage: (page: number) => void) => {
        const pageCount = Math.ceil(notifications.length / (neededPageSize ?? 10))
        if (page > pageCount) {
            fetchNext().then(() => {
                setPage(page)
            })
        } else {
            setPage(page)
        }
    }

    return { notifications, loading, hasMore, fetchNext, setNotificationsPage }
}
