import { Avatar, CardHeader, CardHeaderProps, IconButton } from '@material-ui/core'
import ShareIcon from '@material-ui/icons/Share'
import clsx from 'clsx'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAvatarColor } from '../helpers/useAvatarColor'
import { useStyles } from './publishOnSelectItem.css'

interface Props {
    accountName: string
    accountGivenName: string
    accountFamilyName: string
    pageName?: string
    shared?: boolean
    pagePicture?: string
}

const PublishOnSelectItem: React.FC<Props> = ({
    accountName,
    accountGivenName,
    accountFamilyName,
    pageName,
    pagePicture,
    shared = false,
}) => {
    const classes = useStyles()
    const { generateHSL, HSLtoString } = useAvatarColor()
    const { t } = useTranslation()

    let header: CardHeaderProps = {
        title: t('common.account', { account: accountName }),
        subheader: undefined,
        action: undefined,
    }
    if (pageName) {
        header = {
            title: t('common.page', { page: pageName }),
            subheader: t('common.account', { account: accountName }),
            action: undefined,
        }
    }
    if (shared) {
        header.action = (
            <IconButton disabled className={classes.action}>
                <ShareIcon />
            </IconButton>
        )
    }
    return (
        <div className={clsx(classes.container)}>
            <CardHeader
                classes={{
                    title: classes.title,
                    subheader: classes.subheader,
                }}
                avatar={
                    <Avatar
                        src={pagePicture}
                        classes={{
                            root: classes.avatar,
                            img: classes.avatarImage,
                        }}
                        style={{ backgroundColor: HSLtoString(generateHSL(accountName)) }}
                    >
                        {accountGivenName.charAt(0).toUpperCase()}
                        {accountFamilyName.charAt(0).toUpperCase()}
                    </Avatar>
                }
                {...header}
            />
        </div>
    )
}

export default PublishOnSelectItem
