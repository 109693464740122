import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Radio,
    RadioGroup,
} from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { AlertContent } from 'core/store/app/types'
import React, { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'

export interface Profile {
    id: string
    name: string
}

interface Props extends Omit<AlertContent, 'callback'> {
    open?: boolean
    onClose: () => void
    possibilities: Profile[]
    callback: (profileId: string) => void
}

const SharingDialog: React.FC<Props> = ({
    open = true,
    title,
    onClose,
    content,
    callback,
    buttons,
    possibilities,
}) => {
    const [value, setValue] = useState<string>(() => {
        return possibilities.length > 0 ? possibilities[0].id : ''
    })
    const confirm = () => {
        callback(value)
        onClose()
    }

    useEffect(() => {
        setValue(possibilities.length > 0 ? possibilities[0].id : '')
    }, [possibilities])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
            {content && (
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                </DialogContent>
            )}
            {possibilities && (
                <DialogContent>
                    <RadioGroup
                        value={value}
                        onChange={(_, value) => {
                            setValue(value)
                        }}
                    >
                        {possibilities.map((profile) => (
                            <FormControlLabel
                                value={profile.id}
                                key={profile.id}
                                control={<Radio />}
                                label={profile.name}
                                checked={profile.id === value}
                            />
                        ))}
                    </RadioGroup>
                </DialogContent>
            )}
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {buttons && buttons.no ? buttons.no : <Trans i18nKey="common.no" />}
                </Button>
                <Button onClick={confirm} color="primary" autoFocus>
                    {buttons && buttons.yes ? buttons.yes : <Trans i18nKey="common.yes" />}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SharingDialog
