import { RestaurantOwnersExportResponse, RestaurantOwnerUpdateRequest } from './types'
import { AxiosResponse } from 'axios'
import Client from '../../../core/client'
import { RestaurantOwnerSignInResponse } from '../auth/types'
import { RestaurantOwnerModel } from '../../models'

export const restaurantOwnerUpdate = (
    token: string,
    id: string,
    parameters: RestaurantOwnerUpdateRequest,
): Promise<AxiosResponse<RestaurantOwnerSignInResponse | RestaurantOwnerModel>> => {
    const axiosInstance = Client.axiosInstance
    const url = '/api/restaurant-owner/:id'.replace(':id', id)
    return axiosInstance.patch(url, parameters, {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    })
}

export const restaurantOwnerDelete = (token: string, id: string): Promise<AxiosResponse<void>> => {
    const axiosInstance = Client.axiosInstance
    const url = '/api/restaurant-owner/:id'.replace(':id', id)
    return axiosInstance.delete(url, {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    })
}

export const restaurantOwnersExport = (
    token: string,
): Promise<AxiosResponse<RestaurantOwnersExportResponse>> => {
    const axiosInstance = Client.axiosInstance

    return axiosInstance.post(
        '/api/restaurant-owners/export',
        {},
        {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        },
    )
}
