import { EstablishmentModel, EstablishmentsGroupsModel, GroupFacebookModel } from '../../models'

export enum AuthActionTypes {
    ADD_ESTABLISHMENT = 'establishments/ADD_ESTABLISHMENT',
    SET_ESTABLISHMENTS = 'establishments/SET_ESTABLISHMENTS',
    SET_ESTABLISHMENT = 'establishments/SET_ESTABLISHMENT',
    SET_ESTABLISHMENT_GROUPS = 'establishments/SET_ESTABLISHMENT_GROUPS',
    UPDATE_ESTABLISHMENT = 'establishments/UPDATE_ESTABLISHMENT',
    DELETE_ESTABLISHMENT = 'establishments/DELETE_ESTABLISHMENT',
    SHARE_PHOTO_ESTABLISHMENT = 'establishments/SHARE_PHOTO_ESTABLISHMENT',

    FETCH_ESTABLISHMENTS = 'establishments/FETCH_ESTABLISHMENTS',
}

export interface EstablishmentsState {
    establishments?: Array<EstablishmentModel>
    establishmentsGroups?: EstablishmentsGroupsModel
}

export interface SetEstablishmentGroupsAction {
    establishmentId: string
    groups: Array<GroupFacebookModel>
}
