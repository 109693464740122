import * as authMutations from './auth/mutations'
import * as adminsMutations from './admins/mutations'
import * as adminsQueries from './admins/queries'
import * as establishmentsMutations from './establishments/mutations'
import * as establishmentsQueries from './establishments/queries'
import * as restaurantOwnersMutations from './restaurant-owners/mutations'
import * as restaurantOwnersQueries from './restaurant-owners/queries'
import * as groupsQueries from './groups/queries'

export const mutations = {
    ...authMutations,
    ...adminsMutations,
    ...establishmentsMutations,
    ...restaurantOwnersMutations,
}

export const queries = {
    ...adminsQueries,
    ...establishmentsQueries,
    ...restaurantOwnersQueries,
    ...groupsQueries,
}
