import { AdminCreateRequest, AdminCreateResponse, AdminUpdateRequest } from './types'
import { AxiosResponse } from 'axios'
import Client from '../../../core/client'
import { AdminSignInResponse } from '../auth/types'
import { AdminModel } from '../../models'

export const adminCreate = (
    token: string,
    parameters: AdminCreateRequest,
): Promise<AxiosResponse<AdminCreateResponse>> => {
    const axiosInstance = Client.axiosInstance

    return axiosInstance.post('/api/admin/', parameters, {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    })
}

export const adminUpdate = (
    token: string,
    id: string,
    parameters: AdminUpdateRequest,
): Promise<AxiosResponse<AdminSignInResponse | AdminModel>> => {
    const axiosInstance = Client.axiosInstance
    const url = '/api/admin/:id'.replace(':id', id)
    return axiosInstance.patch(url, parameters, {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    })
}

export const adminDelete = (token: string, id: string): Promise<AxiosResponse<void>> => {
    const axiosInstance = Client.axiosInstance
    const url = '/api/admin/:id'.replace(':id', id)
    return axiosInstance.delete(url, {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    })
}
