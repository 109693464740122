import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            padding: theme.spacing(0, 6),
            paddingTop: theme.spacing(4),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(0, 2),
                paddingTop: theme.spacing(4),
            },
        },
        titleContainer: {
            [theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(2),
            },
            marginBottom: theme.spacing(2),
        },
        // necessary for content to be below app bar
        toolbar: {
            ...theme.mixins.toolbar,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#FAFAFA',
            borderBottom: '1px solid #DCDCDC',
        },
        toolbarSpacing: theme.mixins.toolbar,
        logoContainer: {
            height: 64,
            [theme.breakpoints.down('xs')]: {
                'height': 56,
                'padding': theme.spacing(0.5),
                '@media (orientation: landscape)': {
                    height: 48,
                },
            },
        },
        listItem: {
            'borderRadius': theme.spacing(3),
            'padding': theme.spacing(0.5, 2),
            'margin': theme.spacing(1, 0),
            'color': theme.palette.common.black,
            '&.Mui-selected': {
                'backgroundColor': theme.palette.background.default,
                '& .MuiListItemIcon-root': {
                    color: theme.palette.primary.main,
                },
            },
            '&:hover': {
                'borderRadius': theme.spacing(3),
                'backgroundColor': theme.palette.background.default,
                '& .MuiListItemIcon-root': {
                    color: theme.palette.primary.main,
                },
            },
            '& .MuiListItemIcon-root': {
                color: '#BDBDBD',
            },
            '& .MuiTypography-body1': {
                [theme.breakpoints.down('sm')]: {
                    fontSize: '0.9rem',
                },
            },
        },
        listTitle: {
            paddingTop: theme.spacing(2),
            fontWeight: theme.fontsWeight.semiBold,
            fontSize: '1rem',
            lineHeight: '1rem',
            paddingLeft: 16,
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.9rem',
            },
        },
        action: {
            margin: theme.spacing(3, 0),
        },
    }),
)
