import { PermissionActions, PermissionModel, PermissionSubjects } from '@sugg-gestion/react-onvaauresto'
import _ from 'lodash'

export class AdminPermissionsValues {
    static Read = {
        action: PermissionActions.Read,
        subject: PermissionSubjects.Admin,
        resource: '*',
    }
    static Create = {
        action: PermissionActions.Create,
        subject: PermissionSubjects.Admin,
        resource: '*',
    }
    static Update = {
        action: PermissionActions.Update,
        subject: PermissionSubjects.Admin,
        resource: '*',
    }
    static Delete = {
        action: PermissionActions.Delete,
        subject: PermissionSubjects.Admin,
        resource: '*',
    }
    static Export = {
        action: PermissionActions.Export,
        subject: PermissionSubjects.Admin,
        resource: '*',
    }
    static Manage = {
        action: PermissionActions.Manage,
        subject: PermissionSubjects.Admin,
        resource: '*',
    }
}

export class EstablishmentPermissionsValues {
    static Read = {
        action: PermissionActions.Read,
        subject: PermissionSubjects.Establishment,
        resource: '*',
    }
    static Create = {
        action: PermissionActions.Create,
        subject: PermissionSubjects.Establishment,
        resource: '*',
    }
    static Update = {
        action: PermissionActions.Update,
        subject: PermissionSubjects.Establishment,
        resource: '*',
    }
    static Delete = {
        action: PermissionActions.Delete,
        subject: PermissionSubjects.Establishment,
        resource: '*',
    }
    static Export = {
        action: PermissionActions.Export,
        subject: PermissionSubjects.Establishment,
        resource: '*',
    }
    static Share = {
        action: PermissionActions.Share,
        subject: PermissionSubjects.Establishment,
        resource: '*',
    }
    static Manage = {
        action: PermissionActions.Manage,
        subject: PermissionSubjects.Establishment,
        resource: '*',
    }
}

export class RestaurantOwnerPermissionsValues {
    static Read = {
        action: PermissionActions.Read,
        subject: PermissionSubjects.RestaurantOwner,
        resource: '*',
    }
    static Create = {
        action: PermissionActions.Create,
        subject: PermissionSubjects.RestaurantOwner,
        resource: '*',
    }
    static Update = {
        action: PermissionActions.Update,
        subject: PermissionSubjects.RestaurantOwner,
        resource: '*',
    }
    static Delete = {
        action: PermissionActions.Delete,
        subject: PermissionSubjects.RestaurantOwner,
        resource: '*',
    }
    static Export = {
        action: PermissionActions.Export,
        subject: PermissionSubjects.RestaurantOwner,
        resource: '*',
    }
    static Manage = {
        action: PermissionActions.Manage,
        subject: PermissionSubjects.RestaurantOwner,
        resource: '*',
    }
}

export class GroupPermissionsValues {
    static Read = {
        action: PermissionActions.Read,
        subject: PermissionSubjects.Group,
        resource: '*',
    }
    static Create = {
        action: PermissionActions.Create,
        subject: PermissionSubjects.Group,
        resource: '*',
    }
    static Update = {
        action: PermissionActions.Update,
        subject: PermissionSubjects.Group,
        resource: '*',
    }
    static Delete = {
        action: PermissionActions.Delete,
        subject: PermissionSubjects.Group,
        resource: '*',
    }
    static Export = {
        action: PermissionActions.Export,
        subject: PermissionSubjects.Group,
        resource: '*',
    }
    static Manage = {
        action: PermissionActions.Manage,
        subject: PermissionSubjects.Group,
        resource: '*',
    }
}

export class FacebookPermissionsValues {
    static Read = (resource: string) => ({
        action: PermissionActions.Read,
        subject: PermissionSubjects.Facebook,
        resource: resource,
    })
    static Link = (resource: string) => ({
        action: PermissionActions.Link,
        subject: PermissionSubjects.Facebook,
        resource: resource,
    })
    static Share = (resource: string) => ({
        action: PermissionActions.Share,
        subject: PermissionSubjects.Facebook,
        resource: resource,
    })
    static Unlink = (resource: string) => ({
        action: PermissionActions.Unlink,
        subject: PermissionSubjects.Facebook,
        resource: resource,
    })
    static PostPage = (resource: string) => ({
        action: PermissionActions.PostPage,
        subject: PermissionSubjects.Facebook,
        resource: resource,
    })
    static PostGroup = (resource: string) => ({
        action: PermissionActions.PostGroup,
        subject: PermissionSubjects.Facebook,
        resource: resource,
    })
    static Manage = (resource: string) => ({
        action: PermissionActions.Manage,
        subject: PermissionSubjects.Facebook,
        resource: resource,
    })
}

interface SubjectPermission {
    [subject: string]: PermissionActionsResource
}

interface PermissionActionsResource {
    actions: Array<PermissionActions>
    resource: string
}

export interface PermissionsFormState {
    permissions?: Array<PermissionModel>
}

export const usePermissions = (resource: string) => {
    const transformPermissions = (permissions: Array<PermissionModel>) => {
        const subjects = permissionsBySubjects(permissions)
        const newPermissions: Array<PermissionModel> = []
        for (const subject in subjects) {
            if (subjects.hasOwnProperty(subject)) {
                const { actions } = subjects[subject]
                switch (subject) {
                    case PermissionSubjects.Admin:
                        actions.forEach((action) => {
                            switch (action) {
                                case PermissionActions.Manage:
                                    newPermissions.push(AdminPermissionsValues.Read)
                                    newPermissions.push(AdminPermissionsValues.Create)
                                    newPermissions.push(AdminPermissionsValues.Update)
                                    newPermissions.push(AdminPermissionsValues.Delete)
                                    newPermissions.push(AdminPermissionsValues.Export)
                                    newPermissions.push(AdminPermissionsValues.Manage)
                                    return
                                case PermissionActions.Export:
                                    newPermissions.push(AdminPermissionsValues.Read)
                                    newPermissions.push(AdminPermissionsValues.Export)
                                    return
                                case PermissionActions.Delete:
                                    newPermissions.push(AdminPermissionsValues.Read)
                                    newPermissions.push(AdminPermissionsValues.Create)
                                    newPermissions.push(AdminPermissionsValues.Update)
                                    newPermissions.push(AdminPermissionsValues.Delete)
                                    return
                                case PermissionActions.Update:
                                    newPermissions.push(AdminPermissionsValues.Read)
                                    newPermissions.push(AdminPermissionsValues.Create)
                                    newPermissions.push(AdminPermissionsValues.Update)
                                    return
                                case PermissionActions.Create:
                                    newPermissions.push(AdminPermissionsValues.Read)
                                    newPermissions.push(AdminPermissionsValues.Create)
                                    return
                                case PermissionActions.Read:
                                    newPermissions.push(AdminPermissionsValues.Read)
                                    return
                            }
                        })
                        break
                    case PermissionSubjects.Establishment:
                        actions.forEach((action) => {
                            switch (action) {
                                case PermissionActions.Manage:
                                    newPermissions.push(EstablishmentPermissionsValues.Read)
                                    newPermissions.push(EstablishmentPermissionsValues.Create)
                                    newPermissions.push(EstablishmentPermissionsValues.Update)
                                    newPermissions.push(EstablishmentPermissionsValues.Delete)
                                    newPermissions.push(EstablishmentPermissionsValues.Export)
                                    newPermissions.push(EstablishmentPermissionsValues.Share)
                                    newPermissions.push(EstablishmentPermissionsValues.Manage)
                                    return
                                case PermissionActions.Export:
                                    newPermissions.push(EstablishmentPermissionsValues.Read)
                                    newPermissions.push(EstablishmentPermissionsValues.Export)
                                    return
                                case PermissionActions.Share:
                                    newPermissions.push(EstablishmentPermissionsValues.Read)
                                    newPermissions.push(EstablishmentPermissionsValues.Share)
                                    return
                                case PermissionActions.Delete:
                                    newPermissions.push(EstablishmentPermissionsValues.Read)
                                    newPermissions.push(EstablishmentPermissionsValues.Create)
                                    newPermissions.push(EstablishmentPermissionsValues.Update)
                                    newPermissions.push(EstablishmentPermissionsValues.Delete)
                                    return
                                case PermissionActions.Update:
                                    newPermissions.push(EstablishmentPermissionsValues.Read)
                                    newPermissions.push(EstablishmentPermissionsValues.Create)
                                    newPermissions.push(EstablishmentPermissionsValues.Update)
                                    return
                                case PermissionActions.Create:
                                    newPermissions.push(EstablishmentPermissionsValues.Read)
                                    newPermissions.push(EstablishmentPermissionsValues.Create)
                                    return
                                case PermissionActions.Read:
                                    newPermissions.push(EstablishmentPermissionsValues.Read)
                                    return
                            }
                        })
                        break
                    case PermissionSubjects.RestaurantOwner:
                        actions.forEach((action) => {
                            switch (action) {
                                case PermissionActions.Manage:
                                    newPermissions.push(RestaurantOwnerPermissionsValues.Read)
                                    newPermissions.push(RestaurantOwnerPermissionsValues.Create)
                                    newPermissions.push(RestaurantOwnerPermissionsValues.Update)
                                    newPermissions.push(RestaurantOwnerPermissionsValues.Delete)
                                    newPermissions.push(RestaurantOwnerPermissionsValues.Export)
                                    newPermissions.push(RestaurantOwnerPermissionsValues.Manage)
                                    return
                                case PermissionActions.Export:
                                    newPermissions.push(RestaurantOwnerPermissionsValues.Read)
                                    newPermissions.push(RestaurantOwnerPermissionsValues.Export)
                                    return
                                case PermissionActions.Delete:
                                    newPermissions.push(RestaurantOwnerPermissionsValues.Read)
                                    newPermissions.push(RestaurantOwnerPermissionsValues.Create)
                                    newPermissions.push(RestaurantOwnerPermissionsValues.Update)
                                    newPermissions.push(RestaurantOwnerPermissionsValues.Delete)
                                    return
                                case PermissionActions.Update:
                                    newPermissions.push(RestaurantOwnerPermissionsValues.Read)
                                    newPermissions.push(RestaurantOwnerPermissionsValues.Create)
                                    newPermissions.push(RestaurantOwnerPermissionsValues.Update)
                                    return
                                case PermissionActions.Create:
                                    newPermissions.push(RestaurantOwnerPermissionsValues.Read)
                                    newPermissions.push(RestaurantOwnerPermissionsValues.Create)
                                    return
                                case PermissionActions.Read:
                                    newPermissions.push(RestaurantOwnerPermissionsValues.Read)
                                    return
                            }
                        })
                        break
                    case PermissionSubjects.Group:
                        actions.forEach((action) => {
                            switch (action) {
                                case PermissionActions.Manage:
                                    newPermissions.push(GroupPermissionsValues.Read)
                                    newPermissions.push(GroupPermissionsValues.Create)
                                    newPermissions.push(GroupPermissionsValues.Update)
                                    newPermissions.push(GroupPermissionsValues.Delete)
                                    newPermissions.push(GroupPermissionsValues.Export)
                                    newPermissions.push(GroupPermissionsValues.Manage)
                                    return
                                case PermissionActions.Export:
                                    newPermissions.push(GroupPermissionsValues.Read)
                                    newPermissions.push(GroupPermissionsValues.Export)
                                    return
                                case PermissionActions.Delete:
                                    newPermissions.push(GroupPermissionsValues.Read)
                                    newPermissions.push(GroupPermissionsValues.Create)
                                    newPermissions.push(GroupPermissionsValues.Update)
                                    newPermissions.push(GroupPermissionsValues.Delete)
                                    return
                                case PermissionActions.Update:
                                    newPermissions.push(GroupPermissionsValues.Read)
                                    newPermissions.push(GroupPermissionsValues.Create)
                                    newPermissions.push(GroupPermissionsValues.Update)
                                    return
                                case PermissionActions.Create:
                                    newPermissions.push(GroupPermissionsValues.Read)
                                    newPermissions.push(GroupPermissionsValues.Create)
                                    return
                                case PermissionActions.Read:
                                    newPermissions.push(GroupPermissionsValues.Read)
                                    return
                            }
                        })
                        break
                    case PermissionSubjects.Facebook:
                        actions.forEach((action) => {
                            switch (action) {
                                case PermissionActions.Manage:
                                    newPermissions.push(FacebookPermissionsValues.Link(resource))
                                    newPermissions.push(FacebookPermissionsValues.Unlink(resource))
                                    newPermissions.push(FacebookPermissionsValues.PostPage(resource))
                                    newPermissions.push(FacebookPermissionsValues.PostGroup(resource))
                                    newPermissions.push(FacebookPermissionsValues.Share(resource))
                                    newPermissions.push(FacebookPermissionsValues.Read(resource))
                                    newPermissions.push(FacebookPermissionsValues.Manage(resource))
                                    return
                                case PermissionActions.Link:
                                    newPermissions.push(FacebookPermissionsValues.Read(resource))
                                    newPermissions.push(FacebookPermissionsValues.Link(resource))
                                    return
                                case PermissionActions.Share:
                                    newPermissions.push(FacebookPermissionsValues.Read(resource))
                                    newPermissions.push(FacebookPermissionsValues.Share(resource))
                                    return
                                case PermissionActions.Unlink:
                                    newPermissions.push(FacebookPermissionsValues.Read(resource))
                                    newPermissions.push(FacebookPermissionsValues.Unlink(resource))
                                    newPermissions.push(FacebookPermissionsValues.Link(resource))
                                    return
                                case PermissionActions.PostPage:
                                    newPermissions.push(FacebookPermissionsValues.PostPage(resource))
                                    return
                                case PermissionActions.PostGroup:
                                    newPermissions.push(FacebookPermissionsValues.PostGroup(resource))
                                    return
                                case PermissionActions.Read:
                                    newPermissions.push(FacebookPermissionsValues.Read(resource))
                                    return
                            }
                        })
                        break
                }
            }
        }
        return newPermissions
    }

    const updatePermissions = (permissions: PermissionModel[], disabledPermission?: PermissionModel) => {
        if (disabledPermission) {
            const removedPermissions: Array<PermissionModel> = []
            switch (disabledPermission.subject) {
                case PermissionSubjects.Admin:
                    switch (disabledPermission.action) {
                        case PermissionActions.Manage:
                            removedPermissions.push(AdminPermissionsValues.Manage)
                            break
                        case PermissionActions.Export:
                            removedPermissions.push(AdminPermissionsValues.Export)
                            removedPermissions.push(AdminPermissionsValues.Manage)
                            break
                        case PermissionActions.Delete:
                            removedPermissions.push(AdminPermissionsValues.Delete)
                            removedPermissions.push(AdminPermissionsValues.Manage)
                            break
                        case PermissionActions.Update:
                            removedPermissions.push(AdminPermissionsValues.Update)
                            removedPermissions.push(AdminPermissionsValues.Delete)
                            removedPermissions.push(AdminPermissionsValues.Manage)
                            break
                        case PermissionActions.Create:
                            removedPermissions.push(AdminPermissionsValues.Create)
                            removedPermissions.push(AdminPermissionsValues.Update)
                            removedPermissions.push(AdminPermissionsValues.Delete)
                            removedPermissions.push(AdminPermissionsValues.Manage)
                            break
                        case PermissionActions.Read:
                            removedPermissions.push(AdminPermissionsValues.Read)
                            removedPermissions.push(AdminPermissionsValues.Create)
                            removedPermissions.push(AdminPermissionsValues.Update)
                            removedPermissions.push(AdminPermissionsValues.Delete)
                            removedPermissions.push(AdminPermissionsValues.Manage)
                            removedPermissions.push(AdminPermissionsValues.Export)
                            break
                    }
                    break
                case PermissionSubjects.Establishment:
                    switch (disabledPermission.action) {
                        case PermissionActions.Manage:
                            removedPermissions.push(EstablishmentPermissionsValues.Manage)
                            break
                        case PermissionActions.Export:
                            removedPermissions.push(EstablishmentPermissionsValues.Export)
                            removedPermissions.push(EstablishmentPermissionsValues.Manage)
                            break
                        case PermissionActions.Share:
                            removedPermissions.push(EstablishmentPermissionsValues.Share)
                            removedPermissions.push(EstablishmentPermissionsValues.Manage)
                            break
                        case PermissionActions.Delete:
                            removedPermissions.push(EstablishmentPermissionsValues.Delete)
                            removedPermissions.push(EstablishmentPermissionsValues.Manage)
                            break
                        case PermissionActions.Update:
                            removedPermissions.push(EstablishmentPermissionsValues.Update)
                            removedPermissions.push(EstablishmentPermissionsValues.Delete)
                            removedPermissions.push(EstablishmentPermissionsValues.Manage)
                            break
                        case PermissionActions.Create:
                            removedPermissions.push(EstablishmentPermissionsValues.Create)
                            removedPermissions.push(EstablishmentPermissionsValues.Update)
                            removedPermissions.push(EstablishmentPermissionsValues.Delete)
                            removedPermissions.push(EstablishmentPermissionsValues.Manage)
                            break
                        case PermissionActions.Read:
                            removedPermissions.push(EstablishmentPermissionsValues.Read)
                            removedPermissions.push(EstablishmentPermissionsValues.Create)
                            removedPermissions.push(EstablishmentPermissionsValues.Update)
                            removedPermissions.push(EstablishmentPermissionsValues.Delete)
                            removedPermissions.push(EstablishmentPermissionsValues.Manage)
                            removedPermissions.push(EstablishmentPermissionsValues.Export)
                            removedPermissions.push(EstablishmentPermissionsValues.Share)
                            break
                    }
                    break
                case PermissionSubjects.RestaurantOwner:
                    switch (disabledPermission.action) {
                        case PermissionActions.Manage:
                            removedPermissions.push(RestaurantOwnerPermissionsValues.Manage)
                            break
                        case PermissionActions.Export:
                            removedPermissions.push(RestaurantOwnerPermissionsValues.Export)
                            removedPermissions.push(RestaurantOwnerPermissionsValues.Manage)
                            break
                        case PermissionActions.Delete:
                            removedPermissions.push(RestaurantOwnerPermissionsValues.Delete)
                            removedPermissions.push(RestaurantOwnerPermissionsValues.Manage)
                            break
                        case PermissionActions.Update:
                            removedPermissions.push(RestaurantOwnerPermissionsValues.Update)
                            removedPermissions.push(RestaurantOwnerPermissionsValues.Delete)
                            removedPermissions.push(RestaurantOwnerPermissionsValues.Manage)
                            break
                        case PermissionActions.Create:
                            removedPermissions.push(RestaurantOwnerPermissionsValues.Create)
                            removedPermissions.push(RestaurantOwnerPermissionsValues.Update)
                            removedPermissions.push(RestaurantOwnerPermissionsValues.Delete)
                            removedPermissions.push(RestaurantOwnerPermissionsValues.Manage)
                            break
                        case PermissionActions.Read:
                            removedPermissions.push(RestaurantOwnerPermissionsValues.Read)
                            removedPermissions.push(RestaurantOwnerPermissionsValues.Create)
                            removedPermissions.push(RestaurantOwnerPermissionsValues.Update)
                            removedPermissions.push(RestaurantOwnerPermissionsValues.Delete)
                            removedPermissions.push(RestaurantOwnerPermissionsValues.Manage)
                            removedPermissions.push(RestaurantOwnerPermissionsValues.Export)
                            break
                    }
                    break
                case PermissionSubjects.Group:
                    switch (disabledPermission.action) {
                        case PermissionActions.Manage:
                            removedPermissions.push(GroupPermissionsValues.Manage)
                            break
                        case PermissionActions.Export:
                            removedPermissions.push(GroupPermissionsValues.Export)
                            removedPermissions.push(GroupPermissionsValues.Manage)
                            break
                        case PermissionActions.Delete:
                            removedPermissions.push(GroupPermissionsValues.Delete)
                            removedPermissions.push(GroupPermissionsValues.Manage)
                            break
                        case PermissionActions.Update:
                            removedPermissions.push(GroupPermissionsValues.Update)
                            removedPermissions.push(GroupPermissionsValues.Delete)
                            removedPermissions.push(GroupPermissionsValues.Manage)
                            break
                        case PermissionActions.Create:
                            removedPermissions.push(GroupPermissionsValues.Create)
                            removedPermissions.push(GroupPermissionsValues.Update)
                            removedPermissions.push(GroupPermissionsValues.Delete)
                            removedPermissions.push(GroupPermissionsValues.Manage)
                            break
                        case PermissionActions.Read:
                            removedPermissions.push(GroupPermissionsValues.Read)
                            removedPermissions.push(GroupPermissionsValues.Create)
                            removedPermissions.push(GroupPermissionsValues.Update)
                            removedPermissions.push(GroupPermissionsValues.Delete)
                            removedPermissions.push(GroupPermissionsValues.Manage)
                            removedPermissions.push(GroupPermissionsValues.Export)
                            break
                    }
                    break
                case PermissionSubjects.Facebook:
                    switch (disabledPermission.action) {
                        case PermissionActions.Manage:
                            removedPermissions.push(FacebookPermissionsValues.Manage(resource))
                            break
                        case PermissionActions.Share:
                            removedPermissions.push(FacebookPermissionsValues.Manage(resource))
                            removedPermissions.push(FacebookPermissionsValues.Share(resource))
                            break
                        case PermissionActions.PostPage:
                            removedPermissions.push(FacebookPermissionsValues.Unlink(resource))
                            removedPermissions.push(FacebookPermissionsValues.PostPage(resource))
                            removedPermissions.push(FacebookPermissionsValues.Manage(resource))
                            break
                        case PermissionActions.PostGroup:
                            removedPermissions.push(FacebookPermissionsValues.Unlink(resource))
                            removedPermissions.push(FacebookPermissionsValues.PostGroup(resource))
                            removedPermissions.push(FacebookPermissionsValues.Manage(resource))
                            break
                        case PermissionActions.Unlink:
                            removedPermissions.push(FacebookPermissionsValues.Unlink(resource))
                            removedPermissions.push(FacebookPermissionsValues.Manage(resource))
                            break
                        case PermissionActions.Link:
                            removedPermissions.push(FacebookPermissionsValues.Link(resource))
                            removedPermissions.push(FacebookPermissionsValues.Unlink(resource))
                            removedPermissions.push(FacebookPermissionsValues.Manage(resource))
                            break
                        case PermissionActions.Read:
                            removedPermissions.push(FacebookPermissionsValues.Share(resource))
                            removedPermissions.push(FacebookPermissionsValues.Link(resource))
                            removedPermissions.push(FacebookPermissionsValues.Unlink(resource))
                            removedPermissions.push(FacebookPermissionsValues.Manage(resource))
                            removedPermissions.push(FacebookPermissionsValues.Read(resource))
                            break
                    }
                    break
            }
            return _.uniqWith(
                _.filter(permissions, (permission) => {
                    return (
                        _.findIndex(removedPermissions, (currentPermission: PermissionModel) =>
                            _.isEqual(permission, currentPermission),
                        ) === -1
                    )
                }),
                _.isEqual,
            )
        } else {
            return _.uniqWith(transformPermissions(permissions), _.isEqual)
        }
    }

    const transformRawPermissions = (permissions: Array<PermissionModel>) => {
        const updatedPermissions = permissions.map((permission) => {
            // Admins
            if (_.isEqual(permission, AdminPermissionsValues.Read)) {
                return AdminPermissionsValues.Read
            }
            if (_.isEqual(permission, AdminPermissionsValues.Create)) {
                return AdminPermissionsValues.Create
            }
            if (_.isEqual(permission, AdminPermissionsValues.Update)) {
                return AdminPermissionsValues.Update
            }
            if (_.isEqual(permission, AdminPermissionsValues.Delete)) {
                return AdminPermissionsValues.Delete
            }
            if (_.isEqual(permission, AdminPermissionsValues.Export)) {
                return AdminPermissionsValues.Export
            }
            if (_.isEqual(permission, AdminPermissionsValues.Manage)) {
                return AdminPermissionsValues.Manage
            }

            // Establishment
            if (_.isEqual(permission, EstablishmentPermissionsValues.Read)) {
                return EstablishmentPermissionsValues.Read
            }
            if (_.isEqual(permission, EstablishmentPermissionsValues.Create)) {
                return EstablishmentPermissionsValues.Create
            }
            if (_.isEqual(permission, EstablishmentPermissionsValues.Update)) {
                return EstablishmentPermissionsValues.Update
            }
            if (_.isEqual(permission, EstablishmentPermissionsValues.Delete)) {
                return EstablishmentPermissionsValues.Delete
            }
            if (_.isEqual(permission, EstablishmentPermissionsValues.Export)) {
                return EstablishmentPermissionsValues.Export
            }
            if (_.isEqual(permission, EstablishmentPermissionsValues.Manage)) {
                return EstablishmentPermissionsValues.Manage
            }

            // RestaurantOwners
            if (_.isEqual(permission, RestaurantOwnerPermissionsValues.Read)) {
                return RestaurantOwnerPermissionsValues.Read
            }
            if (_.isEqual(permission, RestaurantOwnerPermissionsValues.Create)) {
                return RestaurantOwnerPermissionsValues.Create
            }
            if (_.isEqual(permission, RestaurantOwnerPermissionsValues.Update)) {
                return RestaurantOwnerPermissionsValues.Update
            }
            if (_.isEqual(permission, RestaurantOwnerPermissionsValues.Delete)) {
                return RestaurantOwnerPermissionsValues.Delete
            }
            if (_.isEqual(permission, RestaurantOwnerPermissionsValues.Export)) {
                return RestaurantOwnerPermissionsValues.Export
            }
            if (_.isEqual(permission, RestaurantOwnerPermissionsValues.Manage)) {
                return RestaurantOwnerPermissionsValues.Manage
            }

            // Groups
            if (_.isEqual(permission, GroupPermissionsValues.Read)) {
                return GroupPermissionsValues.Read
            }
            if (_.isEqual(permission, GroupPermissionsValues.Create)) {
                return GroupPermissionsValues.Create
            }
            if (_.isEqual(permission, GroupPermissionsValues.Update)) {
                return GroupPermissionsValues.Update
            }
            if (_.isEqual(permission, GroupPermissionsValues.Delete)) {
                return GroupPermissionsValues.Delete
            }
            if (_.isEqual(permission, GroupPermissionsValues.Export)) {
                return GroupPermissionsValues.Export
            }
            if (_.isEqual(permission, GroupPermissionsValues.Manage)) {
                return GroupPermissionsValues.Manage
            }

            // Facebook
            if (_.isEqual(permission, FacebookPermissionsValues.Read(resource))) {
                return FacebookPermissionsValues.Read(resource)
            }
            if (_.isEqual(permission, FacebookPermissionsValues.Link(resource))) {
                return FacebookPermissionsValues.Link(resource)
            }
            if (_.isEqual(permission, FacebookPermissionsValues.Unlink(resource))) {
                return FacebookPermissionsValues.Unlink(resource)
            }
            if (_.isEqual(permission, FacebookPermissionsValues.PostGroup(resource))) {
                return FacebookPermissionsValues.PostGroup(resource)
            }
            if (_.isEqual(permission, FacebookPermissionsValues.PostPage(resource))) {
                return FacebookPermissionsValues.PostPage(resource)
            }
            if (_.isEqual(permission, FacebookPermissionsValues.Share(resource))) {
                return FacebookPermissionsValues.Share(resource)
            }
            if (_.isEqual(permission, FacebookPermissionsValues.Manage(resource))) {
                return FacebookPermissionsValues.Manage(resource)
            }

            return permission
        })
        return _.uniqWith(transformPermissions(updatedPermissions), _.isEqual)
    }

    const permissionsBySubjects = (permissions: Array<PermissionModel>) => {
        const subjects: SubjectPermission = {}
        if (permissions) {
            permissions
                .filter((permission) => permission.resource === resource)
                .forEach((permission) => {
                    if (!subjects[permission.subject]) {
                        subjects[permission.subject] = { resource: permission.resource, actions: [] }
                    }
                    subjects[permission.subject].actions.push(permission.action)
                })
        }
        return subjects
    }

    return {
        updatePermissions,
        transformRawPermissions,
        permissionsBySubjects,
    }
}
