import React, { ComponentType } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const withDnD = <T,>(ComposedComponent: ComponentType<T>) => {
    const WithDnD: React.FC<T> = (props) => {
        return (
            <DndProvider backend={HTML5Backend}>
                <ComposedComponent {...props} />
            </DndProvider>
        )
    }
    return WithDnD
}

export default withDnD
