import { useState } from 'react'
import { mutations } from '../../resources'
import { generateError } from '../error'
import { RestaurantOwnersExportResponse } from '../../resources/restaurant-owners/types'

export const useRestaurantOwnersExport = () => {
    const [inProgress, setInProgress] = useState(false)

    const restaurantOwnersExport = (token: string): Promise<RestaurantOwnersExportResponse> => {
        setInProgress(true)
        return mutations
            .restaurantOwnersExport(token)
            .then(({ data }) => {
                if (data) {
                    setInProgress(false)
                    return data
                }
                setInProgress(false)
                throw generateError()
            })
            .catch((error) => {
                setInProgress(false)
                throw generateError(error)
            })
    }

    return {
        restaurantOwnersExport,
        inProgress,
    }
}
