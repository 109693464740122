import React from 'react'
import withAuthenticateLayout from 'containers/hoc/withAuthenticateLayout'
import { useAdminCreate } from '@sugg-gestion/react-onvaauresto'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'core/store/reducers'
import { useApiErrors } from 'core/services/onvaauresto/useApiErrors'
import { useSnackbar } from 'notistack'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core'
import AdminCreateForm, { AdminCreateFormState } from 'components/forms/adminCreateForm'

const AdminCreate: React.FC = () => {
    const { token } = useSelector(({ onvaauresto: { auth } }: ApplicationState) => ({
        token: auth.token,
    }))
    const { adminCreate, inProgress } = useAdminCreate()
    const { displayError } = useApiErrors()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const history = useHistory()

    const onSubmit = (values: AdminCreateFormState) => {
        if (token) {
            return adminCreate(token, values)
                .then((admin) => {
                    history.push('/admins')
                    enqueueSnackbar(t('admin.create.success', { password: admin?.password }), {
                        variant: 'success',
                        persist: true,
                        key: 'AdminsCreate',
                        action: (
                            <Button
                                variant="outlined"
                                color="inherit"
                                onClick={() => closeSnackbar('AdminsCreate')}
                            >
                                <Trans i18nKey="actions.close" />
                            </Button>
                        ),
                    })
                })
                .catch((error) => {
                    displayError(error)
                })
        }
    }

    return (
        <div>
            <AdminCreateForm
                inProgress={inProgress}
                onSubmit={onSubmit}
                initialValues={{
                    username: '',
                    permissions: [],
                }}
            />
        </div>
    )
}

export default withAuthenticateLayout(AdminCreate)
