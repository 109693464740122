import { clearCache as commonClearCache } from '@sugg-gestion/common-graph-api-module'
import { clearCache as onvaaurestoClearCache } from '@sugg-gestion/onvaauresto-graph-api-module'
import { PermissionSubjects, useAdminSignInByUsername } from '@sugg-gestion/react-onvaauresto'
import { clearCache as socialClearCache } from '@sugg-gestion/social-graph-api-module'
import SignInForm, { SignInFormState } from 'components/forms/signInForm'
import MainLayout from 'components/layouts/mainLayout'
import SignInCard from 'components/views/authentication/signInCard'
import { useApiErrors } from 'core/services/onvaauresto/useApiErrors'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const SignIn: React.FC = () => {
    const { signInAdminByEmail } = useAdminSignInByUsername()
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const { displayError } = useApiErrors()

    const onSubmit = (values: SignInFormState) => {
        signInAdminByEmail({
            username: values.username,
            password: values.password,
            withLongLifeToken: false,
        })
            .then(async (response) => {
                await socialClearCache()
                await commonClearCache()
                await onvaaurestoClearCache()
                return response
            })
            .then((response) => {
                if (
                    _.find(response.admin.permissions, { subject: PermissionSubjects.Establishment }) !==
                    undefined
                ) {
                    history.push('/establishments')
                } else if (
                    _.find(response.admin.permissions, { subject: PermissionSubjects.Facebook }) !== undefined
                ) {
                    history.push('/facebook/profiles')
                }
                enqueueSnackbar(t('sign-in.success'), {
                    variant: 'success',
                })
            })
            .catch((error) => {
                displayError(error)
            })
    }
    return (
        <MainLayout>
            <SignInCard>
                <SignInForm onSubmit={onSubmit} />
            </SignInCard>
        </MainLayout>
    )
}

export default SignIn
