import { AxiosResponse } from 'axios'
import { AdminModel } from '../../models'
import Client from '../../client'

export const adminsList = (token: string): Promise<AxiosResponse<Array<AdminModel>>> => {
    const axiosInstance = Client.axiosInstance

    return axiosInstance.get('/api/admins/', {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    })
}

export const adminGet = (token: string, id: string): Promise<AxiosResponse<AdminModel>> => {
    const axiosInstance = Client.axiosInstance

    const url = '/api/admin/:id'.replace(':id', id)
    return axiosInstance.get(url, {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    })
}
