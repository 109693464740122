import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'core/store/reducers'
import { useCallback, useEffect, useState } from 'react'
import { actions, useRestaurantOwnersList } from '@sugg-gestion/react-onvaauresto'
import { useApiErrors } from 'core/services/onvaauresto/useApiErrors'

export const useRestaurantOwners = () => {
    const { restaurantOwners, token } = useSelector(
        ({ onvaauresto: { auth, restaurantOwners } }: ApplicationState) => ({
            restaurantOwners: restaurantOwners.restaurantOwners,
            token: auth.token,
        }),
    )
    const dispatch = useDispatch()
    const { displayError } = useApiErrors()
    const [inProgress, setInProgress] = useState(false)

    const { restaurantOwnersList } = useRestaurantOwnersList()
    const fetchRestaurantOwners = useCallback(() => {
        setInProgress(true)
        restaurantOwnersList(token)
            .catch((error) => {
                dispatch(actions.setRestaurantOwners([]))
                displayError(error)
            })
            .finally(() => {
                setInProgress(false)
            })
    }, [dispatch, displayError, restaurantOwnersList, token])

    useEffect(() => {
        if (restaurantOwners === undefined && token !== undefined && !inProgress) {
            fetchRestaurantOwners()
        }
    }, [fetchRestaurantOwners, inProgress, restaurantOwners, token])

    const refresh = () => {
        fetchRestaurantOwners()
    }

    return { restaurantOwners, inProgress, refresh }
}
