import { createAction } from 'typesafe-actions'
import { AuthActionTypes } from './types'
import { prefix } from '../../config'
import { AdminModel } from '../../models'

const actionPrefix = '@' + prefix + '/'

export const addAdmin = createAction(actionPrefix + AuthActionTypes.ADD_ADMIN)<AdminModel>()
export const setAdmin = createAction(actionPrefix + AuthActionTypes.SET_ADMIN)<AdminModel>()
export const setAdmins = createAction(actionPrefix + AuthActionTypes.SET_ADMINS)<Array<AdminModel>>()
export const updateAdmin = createAction(actionPrefix + AuthActionTypes.UPDATE_ADMIN)<AdminModel>()
export const deleteAdmin = createAction(actionPrefix + AuthActionTypes.DELETE_ADMIN)<string>()
