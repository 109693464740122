import { APIError } from '@sugg-gestion/react-onvaauresto'
import { useSnackbar, VariantType } from 'notistack'
import { useTranslation } from 'react-i18next'

export const useApiErrors = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const showMessage = (error: APIError | any) => {
        let message = t('error.unexpected')
        let variant: VariantType = 'error'
        console.error(error?.data)
        switch (error?.data) {
            case 'SHARE_FAILED': {
                message = t('error.shareFailed')
                break
            }
            case 'WRONG_CREDENTIALS': {
                message = t('error.wrongPassword')
                break
            }
            case 'FILE_UPLOAD_FAILED': {
                message = t('error.wrongUpload')
                break
            }
        }
        return { message, variant }
    }

    const displayError = (error: APIError | any) => {
        const { message, variant } = showMessage(error)
        enqueueSnackbar(message, {
            variant,
        })
    }

    return {
        showMessage,
        displayError,
    }
}
