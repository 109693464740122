import { onvaaurestoReducer, OnVaAuRestoState } from '@sugg-gestion/react-onvaauresto'
import { connectRouter, RouterState } from 'connected-react-router'
import { History } from 'history'
import { combineReducers } from 'redux'
import { appReducer } from './app/reducer'
import { AppState } from './app/types'
import { commonReducer } from './common/reducer'
import { CommonState } from './common/types'

export interface ApplicationState {
    app: AppState
    onvaauresto: OnVaAuRestoState
    common: CommonState
    router: RouterState
}

export const createRootReducer = (history: History) =>
    combineReducers<ApplicationState>({
        app: appReducer,
        onvaauresto: onvaaurestoReducer,
        common: commonReducer,
        router: connectRouter(history),
    })
