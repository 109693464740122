import { CircularProgress, StandardProps } from '@material-ui/core'
import Button, { ButtonClassKey, ButtonProps } from '@material-ui/core/Button/Button'
import React from 'react'

export interface SubmitButtonProps extends StandardProps<ButtonProps, ButtonClassKey> {
    loading?: boolean
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
    loading = false,
    disabled,
    variant,
    children,
    ...rest
}) => {
    return (
        <Button
            {...rest}
            variant={variant}
            startIcon={
                loading ? (
                    <CircularProgress style={{ color: 'rgba(0, 0, 0, 0.26)' }} size={20} />
                ) : (
                    rest.startIcon
                )
            }
            disabled={disabled || loading}
        >
            <span>{children}</span>
        </Button>
    )
}

export default SubmitButton
