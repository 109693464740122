import {
    EstablishmentCreateByRestaurantOwnerResponse,
    EstablishmentCreateRequest,
    EstablishmentPrepareUploadRequest,
    EstablishmentPrepareUploadResponse,
    EstablishmentsExportResponse,
    EstablishmentUpdateRequest,
} from './types'
import { AxiosResponse } from 'axios'
import Client from '../../../core/client'
import { EstablishmentModel } from '../../models'

export const establishmentPrepareUpload = (
    token: string,
    parameters: EstablishmentPrepareUploadRequest,
): Promise<AxiosResponse<EstablishmentPrepareUploadResponse>> => {
    const axiosInstance = Client.axiosInstance

    return axiosInstance.post('/api/establishment/prepare-upload', parameters, {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    })
}

export const establishmentCreate = (
    token: string,
    parameters: EstablishmentCreateRequest,
): Promise<AxiosResponse<EstablishmentModel | EstablishmentCreateByRestaurantOwnerResponse>> => {
    const axiosInstance = Client.axiosInstance

    return axiosInstance.post('/api/establishment/', parameters, {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    })
}

export const establishmentUpdate = (
    token: string,
    id: string,
    parameters: EstablishmentUpdateRequest,
): Promise<AxiosResponse<EstablishmentModel>> => {
    const axiosInstance = Client.axiosInstance
    const url = '/api/establishment/:id'.replace(':id', id)
    return axiosInstance.patch(url, parameters, {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    })
}

export const establishmentDelete = (token: string, id: string): Promise<AxiosResponse<void>> => {
    const axiosInstance = Client.axiosInstance
    const url = '/api/establishment/:id'.replace(':id', id)
    return axiosInstance.delete(url, {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    })
}

export const putFile = (url: string, file: File): Promise<AxiosResponse> => {
    const axiosInstance = Client.axiosInstance

    return axiosInstance.put(url, file, {
        headers: {
            'Content-Type': file.type,
        },
    })
}

export const establishmentsExport = (token: string): Promise<AxiosResponse<EstablishmentsExportResponse>> => {
    const axiosInstance = Client.axiosInstance

    return axiosInstance.post(
        '/api/establishments/export',
        {},
        {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        },
    )
}

export const establishmentSharePhoto = (token: string, id: string): Promise<AxiosResponse<void>> => {
    const axiosInstance = Client.axiosInstance

    const url = '/api/establishment/share/:id'.replace(':id', id)
    return axiosInstance.post(
        url,
        {},
        {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        },
    )
}
