import { OnVaAuRestoGraphApiProvider } from '@sugg-gestion/onvaauresto-graph-api-module'
import { ApplicationState } from 'core/store/reducers'
import React, { ComponentType } from 'react'
import { useSelector } from 'react-redux'

export interface WithOnVaAuRestoGraphApiProps {
    isOnVaAuRestoGraphApiInitialized: boolean
}

const withOnVaAuRestoGraphApi = <T,>(ComposedComponent: ComponentType<T>) => {
    const WithOnVaAuRestoGraphApi: React.FC<T> = (props) => {
        const { token } = useSelector(({ onvaauresto: { auth } }: ApplicationState) => ({
            token: auth.token,
        }))

        return (
            <OnVaAuRestoGraphApiProvider
                apiEndpoint={process.env.REACT_APP_ONVAAURESTO_GRAPH_API_ENDPOINT ?? ''}
                apiRegion={'eu-west-1'}
                token={token}
            >
                {(isInit) => <ComposedComponent {...props} isOnVaAuRestoGraphApiInitialized={isInit} />}
            </OnVaAuRestoGraphApiProvider>
        )
    }
    return WithOnVaAuRestoGraphApi
}

export default withOnVaAuRestoGraphApi
