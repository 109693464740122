import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import {
    BatchPublishOnFacebookMutation,
    BatchPublishOnGroup,
    BatchPublishOnPage,
    useFacebookProfiles,
    useInstalledGroups,
    usePrepareUpload,
    usePublishOnFacebook,
    useSharedFacebookGroups,
    useSharedFacebookPages,
    useUpload,
} from '@sugg-gestion/social-graph-api-module'
import clsx from 'clsx'
import SubmitButton from 'components/common/submitButton'
import DialogMultipleLoading, { LoadingAction, LoadingState } from 'components/dialogs/dialogMultipleLoading'
import PublishSocialForm, { PublishFrom, PublishSocialFormState } from 'components/forms/publishSocialForm'
import FullscreenFormLayout from 'components/layouts/fullscreenFormLayout'
import Message from 'components/notification/message'
import {
    NotificationActionType,
    NotificationType,
    PublishingNotificationModel,
} from 'core/model/notification.model'
import { useNotifications } from 'core/services/common/useNotifications'
import { useApiErrors } from 'core/services/onvaauresto/useApiErrors'
import { ApplicationState } from 'core/store/reducers'
import { reject } from 'lodash'
import moment from 'moment/moment'
import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import withAuthenticateLayout, { WithAuthenticateLayoutProps } from '../hoc/withAuthenticateLayout'
import withDnD from '../hoc/withDnD'
import withMuiPickersUtilsProvider from '../hoc/withMuiPickersUtilsProvider'
import withRequiredSocialGraphApi from '../hoc/withRequiredSocialGraphApi'

interface PublishPhotoInput {
    key: string
    comment?: string
}

interface PreparePublishState extends Omit<Omit<PublishSocialFormState, 'photos'>, 'publishFrom'> {
    publishFrom: PublishFrom
    photos: PublishPhotoInput[]
}

interface PublicationFinishWithError {
    finish: boolean
    error?: Error
}

interface PublicationNotification {
    id: string
    notificationId: string
}

interface PublicationInProgress {
    photos: PublicationFinishWithError[]
    register: PublicationFinishWithError
    notifications: PublicationNotification[]
}

const FacebookPublish: React.FC<WithAuthenticateLayoutProps> = ({ admin }) => {
    const { ability } = useSelector(({ app }: ApplicationState) => ({
        ability: app.ability,
    }))

    const { notifications } = useNotifications(1)

    const { data: facebookProfilesData, loading: loadingFacebookProfiles } = useFacebookProfiles()
    const { data: sharedFacebookPagesData, loading: loadingSharedFacebookPages } = useSharedFacebookPages()
    const { data: sharedFacebookGroupsData, loading: loadingSharedFacebookGroups } = useSharedFacebookGroups()
    const { data: installedGroups, loading: loadingInstalledGroups } = useInstalledGroups()
    const { displayError } = useApiErrors()

    const [prepareUpload] = usePrepareUpload()
    const { upload } = useUpload()
    const [publishOnFacebook] = usePublishOnFacebook()

    const [publicationInProgress, setPublicationInProgress] = useState<PublicationInProgress>()

    const uploadFile = (id: number, image: File) => {
        return prepareUpload({
            variables: {
                prepareUpload: {
                    id,
                    contentType: image.type,
                    size: image.size,
                },
            },
        })
            .then((response) => {
                return new Promise<string>((resolve, reject) =>
                    upload(response.data.prepareUpload.url, image)
                        .then(() => {
                            setPublicationInProgress((publicationInProgress) => {
                                if (publicationInProgress !== undefined) {
                                    const photosPublication = publicationInProgress.photos
                                    photosPublication[id] = {
                                        finish: true,
                                    }
                                    return {
                                        ...publicationInProgress,
                                        photos: photosPublication,
                                    }
                                }
                                return undefined
                            })
                            resolve(response.data.prepareUpload.key)
                        })
                        .catch((e) => reject(e)),
                )
            })
            .catch((e) => {
                setPublicationInProgress((publicationInProgress) => {
                    if (publicationInProgress !== undefined) {
                        const photosPublication = publicationInProgress.photos
                        photosPublication[id] = {
                            finish: false,
                            error: e,
                        }
                        return {
                            ...publicationInProgress,
                            photos: photosPublication,
                        }
                    }
                    return undefined
                })
                throw e
            })
    }

    const handlePublish = (values: PublishSocialFormState) => {
        if (values.publishFrom === undefined) {
            return
        }
        setPublicationInProgress({
            photos: values.photos.filter((photo) => photo.file !== undefined).map(() => ({ finish: false })),
            register: {
                finish: false,
            },
            notifications: [],
        })
        return new Promise<PreparePublishState>((resolve) => {
            if (values.photos.length > 0) {
                const promises: Promise<any>[] = []
                values.photos
                    .filter((photo) => photo.file !== undefined)
                    .forEach((photo, index) => {
                        const image = photo.file
                        if (image) {
                            promises.push(uploadFile(index, image))
                        }
                    })
                Promise.all<string>(promises)
                    .then((results) => {
                        const photosInput: PublishPhotoInput[] = []
                        results.forEach((key, index) => {
                            photosInput.push({
                                key,
                                comment: values.photos[index].comment,
                            })
                        })
                        setPublicationInProgress({
                            photos: values.photos
                                .filter((photo) => photo.file !== undefined)
                                .map(() => ({ finish: true })),
                            register: {
                                finish: false,
                            },
                            notifications: [],
                        })
                        if (!values.publishFrom) {
                            throw Error('internal error')
                        }
                        resolve({
                            ...values,
                            publishFrom: values.publishFrom,
                            photos: photosInput,
                        })
                    })
                    .catch((e) => {
                        console.log(e)
                        reject(e)
                    })
            } else {
                if (!values.publishFrom) {
                    throw Error('internal error')
                }
                resolve({
                    ...values,
                    publishFrom: values.publishFrom,
                    photos: [],
                })
            }
        })
            .then((state) => {
                const batchPublishOnPages: BatchPublishOnPage[] = []
                const batchPublishOnGroups: BatchPublishOnGroup[] = []
                let publishIndex = 0
                state.myFacebookPages.forEach((page) => {
                    if (!state.publishFrom.shared) {
                        if (state.publishFrom.page) {
                            batchPublishOnPages.push({
                                profileId: state.publishFrom.profile,
                                pageId: page,
                                id: (publishIndex++).toString(),
                            })
                        }
                    }
                })
                state.myFacebookGroups.forEach((group) => {
                    if (!state.publishFrom.shared) {
                        if (state.publishFrom.page) {
                            batchPublishOnGroups.push({
                                profileId: state.publishFrom.profile,
                                pageId: state.publishFrom.page,
                                id: (publishIndex++).toString(),
                                groupId: group,
                            })
                        }
                    }
                })
                state.sharedFacebookPages.forEach((page) => {
                    if (state.publishFrom.shared) {
                        if (state.publishFrom.page) {
                            batchPublishOnPages.push({
                                profileId: state.publishFrom.profile,
                                pageId: page,
                                id: (publishIndex++).toString(),
                            })
                        }
                    }
                })
                state.sharedFacebookGroups.forEach((group) => {
                    if (state.publishFrom.shared) {
                        if (state.publishFrom.page) {
                            batchPublishOnGroups.push({
                                profileId: state.publishFrom.profile,
                                pageId: state.publishFrom.page,
                                id: (publishIndex++).toString(),
                                groupId: group,
                            })
                        }
                    }
                })
                publishOnFacebook({
                    variables: {
                        batchPublishOnPage: batchPublishOnPages,
                        batchPublishOnGroup: batchPublishOnGroups,
                        comment: state.comment,
                        photos: state.photos,
                        publicationTime: state.publicationTime,
                    },
                })
                    .then((result) => {
                        console.log(result)
                        const resultData = result.data as BatchPublishOnFacebookMutation
                        setPublicationInProgress((publicationInProgress) => {
                            if (publicationInProgress !== undefined) {
                                const notifications: PublicationNotification[] =
                                    resultData.batchPublishOnFacebook.map((notification) => ({
                                        id: notification.id,
                                        notificationId: notification.notificationId,
                                    })) ?? []
                                return {
                                    ...publicationInProgress,
                                    register: {
                                        finish: true,
                                    },
                                    notifications,
                                }
                            }
                            return undefined
                        })
                    })
                    .catch((e) => {
                        setPublicationInProgress((publicationInProgress) => {
                            if (publicationInProgress !== undefined) {
                                return {
                                    ...publicationInProgress,
                                    register: {
                                        finish: true,
                                        error: e,
                                    },
                                }
                            }
                            return undefined
                        })
                    })
            })
            .catch((error) => displayError(error))
    }

    const loading = () => {
        const loadingStates: Array<LoadingState> = []
        if (!publicationInProgress) {
            return loadingStates
        }
        if (publicationInProgress.photos.length > 0) {
            loadingStates.push({
                title: <Trans i18nKey={'form.publishPhotosInProgress'} />,
                start: true,
                percent: Math.ceil(
                    (publicationInProgress.photos.filter((photoPublication) => photoPublication.finish)
                        .length /
                        publicationInProgress.photos.length) *
                        100,
                ),
                finish:
                    publicationInProgress.photos.filter(
                        (photoPublication) => photoPublication.finish || photoPublication.error !== undefined,
                    ).length === publicationInProgress.photos.length,
                error: publicationInProgress.photos.find(
                    (photoPublication) => photoPublication.error !== undefined,
                )?.error,
                hasError:
                    publicationInProgress.photos.find(
                        (photoPublication) => photoPublication.error !== undefined,
                    ) !== undefined,
                allowClosing: false,
            })
        }
        loadingStates.push({
            title: <Trans i18nKey={'form.publishRegistering'} />,
            start:
                publicationInProgress.photos.filter(
                    (photoPublication) => photoPublication.finish || photoPublication.error !== undefined,
                ).length === publicationInProgress.photos.length,
            percent: publicationInProgress.register.finish ? 100 : 0,
            finish: publicationInProgress.register.finish,
            error: publicationInProgress.register.error,
            hasError: publicationInProgress.register.error !== undefined,
            allowClosing: false,
        })
        let waitingNotification = false
        publicationInProgress.notifications.forEach((publicationNotification) => {
            const notification = notifications.find(
                (notification) => notification.notificationId === publicationNotification.notificationId,
            )
            if (
                notification &&
                (notification.type === NotificationType.PublishingPage ||
                    notification.type === NotificationType.PublishingGroup)
            ) {
                const publishingNotification = notification as PublishingNotificationModel
                let action: LoadingAction | undefined = undefined
                const notificationAction = publishingNotification.actions?.find(
                    (action) => action?.type === NotificationActionType.Open,
                )
                if (notificationAction && notificationAction.url) {
                    action = {
                        href: notificationAction.url,
                        size: 'small',
                        endIcon: <OpenInNewIcon />,
                        children: <Message message={notificationAction.text ?? undefined} />,
                        target: '_blank',
                        rel: 'noreferrer',
                    }
                }
                loadingStates.push({
                    title: notification.message ? (
                        <Message message={notification.message} />
                    ) : (
                        publicationNotification.id
                    ),
                    start: publishingNotification.started,
                    finish:
                        publishingNotification.succeed ||
                        (publishingNotification.errorMessage !== undefined &&
                            publishingNotification.errorMessage !== null),
                    action,
                    error:
                        publishingNotification.errorMessage !== undefined &&
                        publishingNotification.errorMessage !== null ? (
                            <Message message={publishingNotification.errorMessage ?? undefined} />
                        ) : undefined,
                    hasError:
                        publishingNotification.errorMessage !== undefined &&
                        publishingNotification.errorMessage !== null,
                    allowClosing: true,
                })
            } else {
                waitingNotification = true
            }
        })
        if (
            publicationInProgress.notifications.length === 0 &&
            publicationInProgress.register.finish &&
            publicationInProgress.register.error === undefined
        ) {
            waitingNotification = true
        }
        if (waitingNotification) {
            loadingStates.push({
                title: <Trans i18nKey={'form.publishWaiting'} />,
                start: true,
                percent: undefined,
                finish: false,
                error: undefined,
                hasError: false,
                allowClosing: false,
            })
        }
        return loadingStates
    }

    return (
        <>
            <PublishSocialForm
                inProgress={false}
                myFacebookProfiles={
                    facebookProfilesData?.facebookProfiles.filter(
                        (profile) => profile.users.findIndex((userId) => userId === admin.id) !== -1,
                    ) ?? []
                }
                loadingMyFacebookProfiles={loadingFacebookProfiles}
                sharedFacebookPages={sharedFacebookPagesData?.sharedFacebookPages ?? []}
                loadingSharedFacebookPages={loadingSharedFacebookPages}
                sharedFacebookGroups={sharedFacebookGroupsData?.sharedFacebookGroups ?? []}
                loadingSharedFacebookGroups={loadingSharedFacebookGroups}
                installedGroups={installedGroups?.installedGroups ?? []}
                loadingInstalledGroups={loadingInstalledGroups}
                initialValues={{
                    comment: '',
                    photos: [],
                    myFacebookPages: [],
                    myFacebookGroups: [],
                    sharedFacebookGroups: [],
                    sharedFacebookPages: [],
                    publicationTime: moment().utc().toDate(),
                }}
                publishOnGroups={ability.can('postGroup', 'facebook')}
                publishOnPages={ability.can('postPage', 'facebook')}
                onSubmit={handlePublish}
            >
                {(content, actions) => (
                    <FullscreenFormLayout
                        content={<div>{content}</div>}
                        action={
                            <div>
                                {actions.map((action, index) => (
                                    <SubmitButton
                                        key={index}
                                        variant="contained"
                                        color="primary"
                                        {...action}
                                        className={clsx(action.className)}
                                    />
                                ))}
                            </div>
                        }
                    />
                )}
            </PublishSocialForm>
            {publicationInProgress !== undefined && (
                <DialogMultipleLoading
                    loading={loading()}
                    onClose={() => setPublicationInProgress(undefined)}
                />
            )}
        </>
    )
}

export default withAuthenticateLayout(
    withRequiredSocialGraphApi(withMuiPickersUtilsProvider(withDnD(FacebookPublish))),
)
