import { useGroups } from './useGroups'
import { EstablishmentModel, GroupFacebookModel } from '@sugg-gestion/react-onvaauresto'

export const useEstablishmentGroups = () => {
    const { groups, inProgress } = useGroups()

    // haversin(θ) function
    const hsin = (theta: number) => {
        return Math.pow(Math.sin(theta / 2), 2)
    }

    const distance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
        // convert to radians
        // must cast radius as float to multiply later
        const la1 = (lat1 * Math.PI) / 180
        const lo1 = (lon1 * Math.PI) / 180
        const la2 = (lat2 * Math.PI) / 180
        const lo2 = (lon2 * Math.PI) / 180

        const r = 6378100 // Earth radius in METERS

        // calculate
        const h = hsin(la2 - la1) + Math.cos(la1) * Math.cos(la2) * hsin(lo2 - lo1)

        return 2 * r * Math.asin(Math.sqrt(h))
    }

    const getEstablishmentGroups = (establishment: Pick<EstablishmentModel, 'location'>) => {
        const groupsInRange: Array<GroupFacebookModel> = []
        if (groups && establishment.location) {
            groups.forEach((group) => {
                const distanceToGroup = distance(
                    establishment.location.latitude,
                    establishment.location.longitude,
                    group.latitude,
                    group.longitude,
                )
                if (distanceToGroup < group.radius * 1000) {
                    groupsInRange.push(group)
                }
            })
        }
        return groupsInRange
    }

    return {
        getEstablishmentGroups,
        inProgress,
    }
}
