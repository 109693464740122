import { IconButton, InputAdornment, PropTypes, TextField } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import React, { useState } from 'react'
import { Field } from 'react-final-form'
import { TextFieldProps } from './textField'

interface Props extends TextFieldProps {
    name: string
    label?: string
    placeholder?: string
    autoComplete?: string
    margin?: PropTypes.Margin
    required?: boolean
}

const PasswordField: React.FC<Props> = ({
    name,
    label,
    placeholder,
    autoComplete = 'password',
    margin = 'normal',
    required,
    ...other
}) => {
    const [shouldShowPassword, setShouldShowPassword] = useState(false)

    const handleDownPassword = () => {
        setShouldShowPassword(false)
    }

    const handleShowPassword = () => {
        setShouldShowPassword(true)
    }

    return (
        <Field name={name}>
            {({ input: { name, onChange, value }, meta, InputProps, ...rest }) => {
                const showError =
                    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched
                return (
                    <TextField
                        label={label}
                        name={name}
                        value={value}
                        margin={margin}
                        autoComplete={autoComplete}
                        fullWidth
                        type={shouldShowPassword ? 'text' : 'password'}
                        placeholder={placeholder}
                        onChange={onChange}
                        helperText={showError ? meta.error || meta.submitError : undefined}
                        error={showError}
                        required={required}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleShowPassword}
                                        onMouseDown={handleDownPassword}
                                    >
                                        {shouldShowPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                            ...InputProps,
                        }}
                        {...other}
                        {...rest}
                    />
                )
            }}
        </Field>
    )
}

export default PasswordField
