import actions from '../../../core/store/actions'
import { useState } from 'react'
import { mutations } from '../../resources'
import { generateError } from '../error'
import { useDispatch } from '../redux'
import { EstablishmentModel } from '../../models'
import { EstablishmentUpdateRequest } from '../../resources/establishments/types'

export const useEstablishmentUpdate = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)

    const update = (token: string, id: string, payload: EstablishmentUpdateRequest) => {
        return mutations.establishmentUpdate(token, id, payload).then(({ data }) => {
            setInProgress(false)
            if (data) {
                dispatch(actions.updateEstablishment(data))
                return data
            }
            throw generateError()
        })
    }

    const establishmentUpdate = (
        token: string,
        id: string,
        payload: EstablishmentUpdateRequest,
    ): Promise<EstablishmentModel> => {
        setInProgress(true)
        const image = payload.image
        if (image) {
            return mutations
                .establishmentPrepareUpload(token, {
                    contentType: image.type,
                    size: image.size,
                })
                .then((response) => {
                    const { url, key } = response.data
                    return mutations.putFile(url, image).then(() => {
                        return update(token, id, { ...payload, key })
                    })
                })
                .catch((error) => {
                    setInProgress(false)
                    throw generateError(error)
                })
        }
        return update(token, id, payload).catch((error) => {
            setInProgress(false)
            throw generateError(error)
        })
    }

    return {
        establishmentUpdate,
        inProgress,
    }
}
