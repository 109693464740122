import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js'

export const usePhoneNumber = () => {
    const displayPhone = (phone?: string, country: CountryCode = 'FR') => {
        if (phone === undefined || phone === '' || phone === null) {
            return undefined
        }
        const phoneNumber = parsePhoneNumberFromString(phone, country)
        if (phoneNumber === undefined) {
            return undefined
        }
        return phoneNumber.formatNational()
    }

    return {
        displayPhone,
    }
}
