import actions from '../../../core/store/actions'
import { useState } from 'react'
import { mutations } from '../../resources'
import { generateError } from '../error'
import { useDispatch } from '../redux'
import { AdminModel } from '../../models'
import { AdminUpdateRequest } from '../../resources/admins/types'

export const useAdminUpdate = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)

    const adminUpdate = (token: string, id: string, payload: AdminUpdateRequest): Promise<AdminModel> => {
        setInProgress(true)
        return mutations
            .adminUpdate(token, id, payload)
            .then(({ data }) => {
                setInProgress(false)
                if (data && (data as AdminModel).id) {
                    dispatch(actions.updateAdmin(data as AdminModel))
                    return data as AdminModel
                }
                throw generateError()
            })
            .catch((error) => {
                setInProgress(false)
                throw generateError(error)
            })
    }

    return {
        adminUpdate,
        inProgress,
    }
}
