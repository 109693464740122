import React, { ComponentType, useEffect } from 'react'
import mainSaga from 'core/store/sagas'
import { SagaMiddleware } from '@redux-saga/core'

interface Props {
    sagaMiddleware: SagaMiddleware
}

const withSaga = (ComposedComponent: ComponentType<Props>) => {
    const WithSaga: React.FC<Props> = ({ sagaMiddleware, ...props }) => {
        useEffect(() => {
            sagaMiddleware.run(mainSaga)
        }, [sagaMiddleware])
        return <ComposedComponent sagaMiddleware={sagaMiddleware} {...props} />
    }
    return WithSaga
}

export default withSaga
