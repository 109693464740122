import React from 'react'
import { Button } from '@material-ui/core'
import { Trans } from 'react-i18next'

interface Props {
    url: string
}

const DownloadButton: React.FC<Props> = ({ url }) => {
    return (
        <Button variant="outlined" color="inherit" href={url}>
            <Trans i18nKey="actions.download" />
        </Button>
    )
}

export default DownloadButton
