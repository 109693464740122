import React from 'react'
import StandardLayout from 'components/layouts/standardLayout'
import { Card, CardContent, Container, Link, Typography } from '@material-ui/core'
import { useStyles } from './notFound.css'
import { Trans } from 'react-i18next'
import Logo from 'components/common/logo'

const NotFound: React.FC = () => {
    const classes = useStyles()
    return (
        <StandardLayout>
            <Link href="/" className={classes.logoContainer}>
                <Logo />
            </Link>
            <Container fixed maxWidth="sm">
                <Card>
                    <CardContent className={classes.card}>
                        <Typography className={classes.title} variant="h1">
                            <Trans i18nKey="not-found.title" />
                        </Typography>
                        <Typography className={classes.subTitle} variant="h2">
                            <Trans i18nKey="not-found.subTitle" />
                        </Typography>
                        <div className={classes.action}>
                            <Link href="/">
                                <Trans i18nKey="not-found.action" />
                            </Link>
                        </div>
                    </CardContent>
                </Card>
            </Container>
        </StandardLayout>
    )
}

export default NotFound
