import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'core/store/reducers'
import { useEffect, useState } from 'react'
import { actions, useAdminsList } from '@sugg-gestion/react-onvaauresto'
import { useApiErrors } from '../onvaauresto/useApiErrors'

export const useAdmins = () => {
    const { admins, token } = useSelector(({ onvaauresto: { auth, admins } }: ApplicationState) => ({
        admins: admins.admins,
        token: auth.token,
    }))
    const dispatch = useDispatch()
    const { displayError } = useApiErrors()
    const [inProgress, setInProgress] = useState(false)

    const { adminsList } = useAdminsList()
    useEffect(() => {
        if (admins === undefined && token !== undefined && !inProgress) {
            setInProgress(true)
            adminsList(token)
                .then(() => {
                    setInProgress(false)
                })
                .catch((error) => {
                    dispatch(actions.setAdmins([]))
                    setInProgress(false)
                    displayError(error)
                })
        }
    }, [admins, token, inProgress, adminsList, dispatch, displayError])

    return { admins, inProgress }
}
