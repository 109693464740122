import { Grid, Typography } from '@material-ui/core'
import { GridColDef } from '@material-ui/data-grid'
import clsx from 'clsx'
import { SubmitButtonProps } from 'components/common/submitButton'
import { SharedFacebookGroupModel } from 'core/model/sharedFacebookGroup.model'
import { SharedFacebookPageModel } from 'core/model/sharedFacebookPage.model'
import moment from 'moment/moment'
import React from 'react'
import { Form, FormSpy } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import { FacebookProfileModel } from '../../core/model/facebookProfile.model'
import { InstalledGroupModel } from '../../core/model/installedGroup.model'
import Loader from '../common/loader'
import DataGridField from './components/dataGridField'
import PhotoContainer from './components/photoContainer'
import PublicationTimeField from './components/publicationTimeField'
import SelectField, { SelectValue } from './components/selectField'
import TextField from './components/textField'
import { useFormsStyles } from './forms.css'
import { moveArrayItemToNewIndex } from './helpers/moveArrayItemToNewIndex'
import { countHashtag } from './helpers/slateUtils'
import { Image } from './helpers/useImages'
import { AddFormImageState, AddFormState } from './imageForm'
import PublishOnSelectItem from './publishSocialForm/publishOnSelectItem'

interface Props {
    subscription?: {
        [property: string]: boolean
    }
    onSubmit: (values: PublishSocialFormState) => void
    children: (content: React.ReactNode, actions: Array<SubmitButtonProps>) => React.ReactNode
    formClassName?: string
    inProgress: boolean
    initialValues: PublishSocialFormState
    sharedFacebookPages: SharedFacebookPageModel[]
    sharedFacebookGroups: SharedFacebookGroupModel[]
    installedGroups: InstalledGroupModel[]
    loadingSharedFacebookPages: boolean
    loadingSharedFacebookGroups: boolean
    loadingInstalledGroups: boolean
    publishOnGroups: boolean
    publishOnPages: boolean
    loadingMyFacebookProfiles: boolean
    myFacebookProfiles: FacebookProfileModel[]
}

export interface PublishSocialFormState {
    photos: AddFormImageState[]
    publishFrom?: PublishFrom
    comment?: string
    myFacebookPages: string[]
    myFacebookGroups: string[]
    sharedFacebookGroups: string[]
    sharedFacebookPages: string[]
    publicationTime: Date
}

export interface PublishSocialFormError {
    photos?: string
    publishFrom?: string
    comment?: string
    myFacebookPages?: string
    myFacebookGroups?: string
    sharedFacebookGroups?: string
    sharedFacebookPages?: string
    publicationTime?: string
}

export interface PublishFrom {
    id: string
    profile: string
    page?: string
    groups?: string[]
    shared: boolean
}

const PublishSocialForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    children,
    formClassName,
    inProgress,
    initialValues,
    sharedFacebookPages,
    sharedFacebookGroups,
    loadingSharedFacebookPages,
    loadingSharedFacebookGroups,
    publishOnGroups,
    publishOnPages,
    myFacebookProfiles,
    loadingMyFacebookProfiles,
    installedGroups,
    loadingInstalledGroups,
}) => {
    const formsClasses = useFormsStyles()
    const { t } = useTranslation()

    const pagesColumns = (idField: string = 'id'): GridColDef[] => [
        {
            field: idField,
            headerName: t('common.id'),
            width: 140,
            sortable: false,
        },
        { field: 'name', headerName: t('common.name'), width: 240 },
        { field: 'category', headerName: t('common.category'), width: 240 },
        {
            field: 'fanCount',
            headerName: t('common.memberCount'),
            width: 240,
            type: 'number',
        },
    ]
    const groupsColumns = (idField: string = 'id'): GridColDef[] => [
        {
            field: idField,
            headerName: t('common.id'),
            width: 140,
            sortable: false,
            renderCell: ({ value }) => {
                return (value as string).split('/').pop()
            },
        },
        { field: 'name', headerName: t('common.name'), width: 240 },
        {
            field: 'updatedTime',
            headerName: t('common.updatedTime'),
            width: 240,
            type: 'dateTime',
            renderCell: ({ value }) => {
                if (value === undefined) {
                    return ''
                }
                const date = moment(value as string)
                return <Trans i18nKey="common.dateYear" values={{ date: date.toDate() }} />
            },
        },
        {
            field: 'memberCount',
            headerName: t('common.memberCount'),
            width: 240,
            type: 'number',
            renderCell: ({ value }) => {
                if (value === 0) {
                    return ''
                }
                return value
            },
        },
        {
            field: 'installed',
            headerName: t('common.groupInstalled'),
            width: 240,
            type: 'boolean',
        },
    ]

    const publishFromPossibilities: SelectValue<PublishFrom>[] = []
    publishFromPossibilities.push(
        ...myFacebookProfiles.flatMap((profile) => [
            // {
            //     label: (
            //         <PublishOnSelectItem
            //             accountName={profile.name}
            //             accountGivenName={profile.givenName}
            //             accountFamilyName={profile.familyName}
            //         />
            //     ),
            //     value: {
            //         id: profile.id,
            //         profile: profile.id,
            //         shared: false,
            //     },
            // },
            ...profile.pages.map((page) => ({
                label: (
                    <PublishOnSelectItem
                        pageName={page.name}
                        pagePicture={page.picture ?? undefined}
                        accountName={profile.name}
                        accountGivenName={profile.givenName}
                        accountFamilyName={profile.familyName}
                    />
                ),
                value: {
                    id: page.id,
                    profile: profile.id,
                    page: page.id,
                    groups: page.groups,
                    shared: false,
                },
            })),
        ]),
    )
    publishFromPossibilities.push(
        ...sharedFacebookPages.flatMap((page) => [
            {
                label: (
                    <PublishOnSelectItem
                        shared
                        pageName={page.name}
                        pagePicture={page.picture ?? undefined}
                        accountName={page.profile.name}
                        accountGivenName={page.profile.givenName}
                        accountFamilyName={page.profile.familyName}
                    />
                ),
                value: {
                    id: page.id,
                    profile: page.profile.id,
                    page: page.pageId,
                    groups: page.groups,
                    shared: true,
                },
            },
        ]),
    )

    const validate = (values: PublishSocialFormState) => {
        const errors: PublishSocialFormError = {}

        if (values.comment && countHashtag(values.comment) > 30) {
            errors.comment = t('form-validation.hashtagLimit')
        }
        if (!values.publishFrom) {
            errors.publishFrom = t('form-validation.slatePhoto')
        }
        return errors
    }

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            mutators={{
                moveEntry: (args, state, tools) => {
                    const from = args[0] as number
                    const to = args[1] as number
                    const {
                        formState: { values },
                    } = state
                    let { photos } = values as AddFormState
                    photos = moveArrayItemToNewIndex(photos ?? [], from, to)
                    const newPhotos: Array<AddFormImageState> = []
                    photos.forEach((photo, index) => {
                        newPhotos.push({
                            ...photo,
                            sortOrder: index,
                        })
                    })
                    tools.changeValue(state, 'photos', () => newPhotos)
                },
                deleteImage: (args, state, tools) => {
                    const image = args[0] as Image
                    const {
                        formState: { values },
                    } = state
                    const { photos } = values as AddFormState

                    const imageIndex = photos?.findIndex((photo) => photo.id === image.id) ?? -1
                    photos?.splice(imageIndex, 1)
                    tools.changeValue(state, 'photos', () => photos)
                },
            }}
            // debug={console.log}
            validate={validate}
            onSubmit={onSubmit}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting, form: { mutators } }) => (
                <form onSubmit={handleSubmit} noValidate className={clsx(formsClasses.form, formClassName)}>
                    {children(
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Grid container spacing={4}>
                                    {/* IMAGES */}
                                    <Grid item xs={12}>
                                        <PhotoContainer
                                            defaultProperties={{
                                                id: 0,
                                                url: '',
                                                sortOrder: 0,
                                            }}
                                            mutators={mutators}
                                            xl={2}
                                            xs={6}
                                            lg={3}
                                            sm={4}
                                        />
                                    </Grid>
                                    {/*  COMMENT  */}
                                    <Grid item xs={12}>
                                        <Typography
                                            color="textSecondary"
                                            className={clsx(
                                                formsClasses.formTitle,
                                                formsClasses.noPaddingTop,
                                            )}
                                        >
                                            <Trans i18nKey="form.comment" />
                                        </Typography>
                                        <TextField
                                            name="comment"
                                            multiline={true}
                                            maxRows={24}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography color="textSecondary" className={clsx(formsClasses.formTitle)}>
                                    <Trans i18nKey="form.publicationTime" />
                                </Typography>
                                <PublicationTimeField name="publicationTime" />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography color="textSecondary" className={clsx(formsClasses.formTitle)}>
                                    <Trans i18nKey="form.publishFrom" />
                                </Typography>
                                {loadingMyFacebookProfiles ||
                                loadingSharedFacebookPages ||
                                loadingSharedFacebookGroups ? (
                                    <div className={formsClasses.loading}>
                                        <Loader withLogo={false} />
                                    </div>
                                ) : (
                                    <SelectField
                                        name="publishFrom"
                                        possibilities={publishFromPossibilities}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography color="textSecondary" className={clsx(formsClasses.formTitle)}>
                                    <Trans i18nKey="form.publishOn" />
                                </Typography>

                                <FormSpy subscription={{ values: true }}>
                                    {(props) => {
                                        const publishFrom = props.values.publishFrom as
                                            | PublishFrom
                                            | undefined
                                        return (
                                            <>
                                                {publishFrom === undefined ? (
                                                    <div className={formsClasses.loading}>
                                                        <Typography>
                                                            <Trans i18nKey="form-validation.publishOn" />
                                                        </Typography>
                                                    </div>
                                                ) : publishFrom.shared ? (
                                                    <>
                                                        {publishOnPages && (
                                                            <DataGridField
                                                                label={<Trans i18nKey="form.facebookPage" />}
                                                                rows={sharedFacebookPages.filter(
                                                                    (page) =>
                                                                        page.profile.id ===
                                                                            publishFrom?.profile &&
                                                                        page.pageId === publishFrom?.page,
                                                                )}
                                                                columns={pagesColumns('pageId')}
                                                                loading={loadingSharedFacebookPages}
                                                                name="sharedFacebookPages"
                                                            />
                                                        )}
                                                        {publishOnGroups && (
                                                            <DataGridField
                                                                label={<Trans i18nKey="form.facebookGroup" />}
                                                                rows={sharedFacebookGroups.filter(
                                                                    (group) =>
                                                                        group.profile.id ===
                                                                        publishFrom?.profile,
                                                                )}
                                                                columns={groupsColumns('groupId')}
                                                                loading={loadingSharedFacebookGroups}
                                                                name="sharedFacebookGroups"
                                                            />
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        {publishOnPages && publishFrom.page !== undefined && (
                                                            <DataGridField
                                                                label={<Trans i18nKey="form.facebookPage" />}
                                                                rows={myFacebookProfiles
                                                                    .filter(
                                                                        (profile) =>
                                                                            profile.id ===
                                                                            publishFrom?.profile,
                                                                    )
                                                                    .flatMap((profile) =>
                                                                        profile.pages.filter(
                                                                            (page) =>
                                                                                publishFrom?.page ===
                                                                                    undefined ||
                                                                                publishFrom?.page === page.id,
                                                                        ),
                                                                    )
                                                                    .filter((v, i, a) => a.indexOf(v) === i)}
                                                                columns={pagesColumns()}
                                                                loading={loadingMyFacebookProfiles}
                                                                name="myFacebookPages"
                                                            />
                                                        )}
                                                        {publishOnGroups &&
                                                            (installedGroups.length > 0 ||
                                                                publishFrom.groups !== undefined) && (
                                                                <DataGridField
                                                                    label={
                                                                        <Trans i18nKey="form.facebookGroup" />
                                                                    }
                                                                    rows={myFacebookProfiles
                                                                        .filter(
                                                                            (profile) =>
                                                                                profile.id ===
                                                                                publishFrom?.profile,
                                                                        )
                                                                        .flatMap((profile) =>
                                                                            profile.groups.filter(
                                                                                (group) =>
                                                                                    publishFrom?.groups ===
                                                                                        undefined ||
                                                                                    publishFrom?.groups.findIndex(
                                                                                        (currentGroup) =>
                                                                                            currentGroup ===
                                                                                            group.id,
                                                                                    ) !== -1,
                                                                            ),
                                                                        )
                                                                        .concat(
                                                                            installedGroups.map(
                                                                                (installedGroup) => ({
                                                                                    id: installedGroup.id,
                                                                                    name: installedGroup.name,
                                                                                    installed: true,
                                                                                    memberCount: 0,
                                                                                    cover: installedGroup.cover,
                                                                                }),
                                                                            ),
                                                                        )
                                                                        .filter(
                                                                            (v, i, a) => a.indexOf(v) === i,
                                                                        )}
                                                                    columns={groupsColumns()}
                                                                    loading={loadingMyFacebookProfiles}
                                                                    name="myFacebookGroups"
                                                                />
                                                            )}
                                                    </>
                                                )}
                                            </>
                                        )
                                    }}
                                </FormSpy>
                            </Grid>
                            {/*{publishOnGroups && (*/}
                            {/*    <Grid item xs={12}>*/}
                            {/*        <CheckboxField*/}
                            {/*            name="shareOnGroups"*/}
                            {/*            label={<Trans i18nKey="form.shareOnGroups" />}*/}
                            {/*            value={true}*/}
                            {/*        />*/}
                            {/*    </Grid>*/}
                            {/*)}*/}
                        </Grid>,
                        [
                            {
                                type: 'submit',
                                loading: submitting || inProgress,
                                className: formsClasses.submit,
                                children: <Trans i18nKey="actions.validate" />,
                            },
                        ],
                    )}
                </form>
            )}
        </Form>
    )
}

export default PublishSocialForm
