import actions from '../../../core/store/actions'
import { AdminSignInResponse } from '../../resources/auth/types'
import { useState } from 'react'
import { mutations } from '../../resources'
import { generateError } from '../error'
import { useDispatch } from '../redux'
import { AdminUpdateRequest } from '../../resources/admins/types'

export const useAdminUpdateMe = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)

    const adminUpdateMe = (
        token: string,
        id: string,
        payload: AdminUpdateRequest,
    ): Promise<AdminSignInResponse> => {
        setInProgress(true)
        return mutations
            .adminUpdate(token, id, payload)
            .then(({ data }) => {
                setInProgress(false)
                if (data && (data as AdminSignInResponse).authentication) {
                    dispatch(actions.adminSignIn(data as AdminSignInResponse))
                    return data as AdminSignInResponse
                }
                throw generateError()
            })
            .catch((error) => {
                setInProgress(false)
                throw generateError(error)
            })
    }

    return {
        adminUpdateMe,
        inProgress,
    }
}
