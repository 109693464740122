import { AxiosResponse } from 'axios'
import { EstablishmentModel, GroupFacebookModel } from '../../models'
import Client from '../../client'

export const establishmentsList = (
    token?: string,
    withDisabled?: boolean,
): Promise<AxiosResponse<Array<EstablishmentModel>>> => {
    const axiosInstance = Client.axiosInstance
    let url = '/api/establishments/'
    if (withDisabled !== undefined) {
        url += '?withDisabled=' + (withDisabled ? 'true' : 'false')
    }
    return axiosInstance.get(
        url,
        token
            ? {
                  headers: {
                      Authorization: 'Bearer ' + token,
                  },
              }
            : {},
    )
}

export const establishmentGet = (token: string, id: string): Promise<AxiosResponse<EstablishmentModel>> => {
    const axiosInstance = Client.axiosInstance

    const url = '/api/establishment/:id'.replace(':id', id)
    return axiosInstance.get(url, {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    })
}

export const establishmentGetGroups = (
    token: string,
    id: string,
): Promise<AxiosResponse<Array<GroupFacebookModel>>> => {
    const axiosInstance = Client.axiosInstance

    const url = '/api/establishment/:id/groups'.replace(':id', id)
    return axiosInstance.get(url, {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    })
}
