import { useGroupDelete, useGroups } from '@sugg-gestion/onvaauresto-graph-api-module'
import GroupsDataTable from 'components/views/groups/groupsDataTable'
import withAuthenticateLayout, { WithAuthenticateLayoutProps } from 'containers/hoc/withAuthenticateLayout'
import withOnVaAuRestoGraphApi, { WithOnVaAuRestoGraphApiProps } from 'containers/hoc/withOnVaAuRestoGraphApi'
import { useApiErrors } from 'core/services/onvaauresto/useApiErrors'
import { ApplicationState } from 'core/store/reducers'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const Groups: React.FC<WithAuthenticateLayoutProps & WithOnVaAuRestoGraphApiProps> = () => {
    const { ability } = useSelector(({ app }: ApplicationState) => ({
        ability: app.ability,
    }))
    const history = useHistory()

    const { enqueueSnackbar } = useSnackbar()
    const { displayError } = useApiErrors()
    const { t } = useTranslation()

    const { data, loading, refetch } = useGroups(true)
    const [groupDelete] = useGroupDelete()

    const handleAddGroup = () => {
        history.push('/group/add')
    }

    const handleEditGroup = (id: string) => {
        history.push('/group/:id'.replace(':id', id))
    }

    const handleDeleteGroup = (id: string) => {
        groupDelete({
            variables: {
                id,
            },
        })
            .then(() => {
                enqueueSnackbar(t('groups.delete.success'), {
                    variant: 'success',
                })
            })
            .catch((error) => {
                displayError(error)
            })
    }

    return (
        <GroupsDataTable
            groups={data?.groups ?? []}
            onRefreshGroups={() => refetch()}
            ability={ability}
            loading={loading}
            onAddGroup={handleAddGroup}
            onDeleteGroup={handleDeleteGroup}
            onEditGroup={handleEditGroup}
        />
    )
}

export default withAuthenticateLayout(withOnVaAuRestoGraphApi(Groups))
