import { Reducer } from 'redux'
import { GroupsState } from './types'
import { getType } from 'typesafe-actions'
import actions from '../actions'
import { GroupFacebookModel } from '../../models'

const initialState: GroupsState = {}

const reducer: Reducer<GroupsState> = (state = initialState, action) => {
    switch (action.type) {
        case getType(actions.setGroups): {
            const groups: Array<GroupFacebookModel> = action.payload
            return {
                ...state,
                groups,
            }
        }
        case getType(actions.start): {
            const started = action.payload
            if (!started) {
                return initialState
            }
            return state
        }
        default:
            return state
    }
}

export { reducer as groupsReducer }
