import React from 'react'
import { useStyles } from './mainLayout.css'
import Version from 'components/common/version'
import Logo from '../common/logo'

const MainLayout: React.FC = ({ children }) => {
    const classes = useStyles()
    return (
        <div className={classes.mainLayout}>
            <Logo className={classes.logo} />
            {children}
            <Version />
        </div>
    )
}

export default MainLayout
