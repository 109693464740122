export interface PermissionModel {
    action: PermissionActions
    subject: PermissionSubjects
    resource: string
}

export enum PermissionActions {
    Manage = 'manage',
    Read = 'read',
    Create = 'create',
    Update = 'update',
    Delete = 'delete',
    Export = 'export',
    Share = 'share',
    Link = 'link',
    Unlink = 'unlink',
    PostPage = 'postPage',
    PostGroup = 'postGroup',
}

export enum PermissionSubjects {
    Establishment = 'establishment',
    Admin = 'admin',
    RestaurantOwner = 'restaurant-owner',
    Group = 'group',

    Facebook = 'facebook',
    Instagram = 'instagram',
    Google = 'google',
    Twitter = 'twitter',
}
