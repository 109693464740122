import { createAliasResolver, defineAbility } from '@casl/ability'
import {
    actions as onVaAuRestoActions,
    AdminSignInResponse,
    PermissionModel,
} from '@sugg-gestion/react-onvaauresto'
import { SnackbarKey } from 'notistack'
import { Reducer } from 'redux'
import { getType } from 'typesafe-actions'
import actions from '../actions'
import { AlertContent, AppState, SnackbarNotification } from './types'

const resolveAction = createAliasResolver({
    export: ['read'],
    delete: ['read', 'create'],
    update: ['read', 'create'],
})
const defaultAbility = defineAbility(() => {})

const initialState: AppState = {
    locale: 'fr-FR',
    timezone: 'Europe/Paris',
    init: false,
    notifications: [],
    ability: defaultAbility,
    facebookInitialized: false,
}

const reducer: Reducer<AppState> = (state = initialState, action) => {
    switch (action.type) {
        case getType(actions.setLocale): {
            const locale = action.payload
            return {
                ...state,
                locale,
            }
        }
        case getType(actions.setTimezone): {
            const timezone = action.payload
            return {
                ...state,
                timezone,
            }
        }
        case getType(actions.init): {
            const init: boolean = action.payload
            return {
                ...state,
                init,
            }
        }
        case getType(actions.enqueueSnackbar): {
            const notification: SnackbarNotification = action.payload
            const key = notification.options && notification.options.key
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        ...notification,
                        key: key || new Date().getTime() + Math.random(),
                    },
                ],
            }
        }
        case getType(actions.closeSnackbar): {
            const key: SnackbarKey = action.payload
            const dismissAll = key !== undefined
            return {
                ...state,
                notifications: state.notifications.map((notification) =>
                    dismissAll || notification.key === key
                        ? { ...notification, dismissed: true }
                        : { ...notification },
                ),
            }
        }
        case getType(actions.removeSnackbar): {
            const key: SnackbarKey = action.payload
            return {
                ...state,
                notifications: state.notifications.filter((notification) => notification.key !== key),
            }
        }
        case getType(actions.alert): {
            const alert: AlertContent = action.payload
            return {
                ...state,
                alert,
            }
        }
        case getType(onVaAuRestoActions.adminSignIn): {
            const response: AdminSignInResponse = action.payload
            const { permissions } = response.admin
            const ability = defineAbility(
                (can) => {
                    permissions.forEach((permission: PermissionModel) => {
                        can(permission.action, permission.subject, { resource: permission.resource })
                    })
                },
                { resolveAction },
            )
            return {
                ...state,
                ability,
            }
        }
        case getType(onVaAuRestoActions.adminSignOut): {
            return {
                ...state,
                ability: defaultAbility,
            }
        }
        case getType(actions.setFacebookInitialized): {
            const facebookInitialized = action.payload
            return {
                ...state,
                facebookInitialized,
            }
        }
        default:
            return state
    }
}

export { reducer as appReducer }
