import { EstablishmentModel } from '@sugg-gestion/react-onvaauresto'

export const useWebsite = () => {
    const { REACT_APP_ONVAAURESTO_WEBSITE } = process.env
    const getUrl = (establishment: EstablishmentModel) => {
        return REACT_APP_ONVAAURESTO_WEBSITE + '/restaurant/' + establishment.uri + '/'
    }

    return {
        getUrl,
    }
}
