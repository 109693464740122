import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import date from './date'

i18next
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            fr: {
                translation: require('./languages/french.json'),
            },
            en: {
                translation: require('./languages/english.json'),
            },
        },
        // lng: 'fr',
        fallbackLng: 'en',

        interpolation: {
            escapeValue: false,
            format(value, format, locale) {
                if (format === 'currency') {
                    return new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR' }).format(value)
                } else if (value instanceof Date) {
                    return date.format(value, format)
                } else if (value === undefined) {
                    return format
                }
                return value
            },
        },
    })
    .then(() => {
        date.init(i18next.language.split('-')[0] || 'en')
    })
