import actions from '../../../core/store/actions'
import { useState } from 'react'
import { mutations } from '../../resources'
import { generateError } from '../error'
import { useDispatch, useSelector } from '../redux'
import { EstablishmentModel } from '../../models'
import {
    EstablishmentCreateByRestaurantOwnerResponse,
    EstablishmentCreateRequest,
} from '../../resources/types'
import { ApplicationState } from '../../store'

export const useEstablishmentCreate = () => {
    const dispatch = useDispatch()
    const { restaurantOwner } = useSelector(({ onvaauresto: { auth } }: ApplicationState) => ({
        restaurantOwner: auth.restaurantOwner,
    }))
    const [inProgress, setInProgress] = useState(false)

    const internalEstablishmentCreate = (token: string, payload: EstablishmentCreateRequest) => {
        return mutations.establishmentCreate(token, payload).then(({ data }) => {
            setInProgress(false)
            if (data) {
                if (restaurantOwner) {
                    const response = data as EstablishmentCreateByRestaurantOwnerResponse
                    dispatch(actions.addEstablishment(response.establishment))
                    dispatch(
                        actions.restaurantOwnerSignIn({
                            restaurantOwner: response.restaurantOwner,
                            authentication: response.authentication,
                        }),
                    )
                } else {
                    const response = data as EstablishmentModel
                    dispatch(actions.addEstablishment(response))
                }
                return data
            }
            throw generateError()
        })
    }

    const establishmentCreate = (
        token: string,
        payload: EstablishmentCreateRequest,
    ): Promise<EstablishmentModel | EstablishmentCreateByRestaurantOwnerResponse> => {
        setInProgress(true)
        const image = payload.image
        if (image) {
            return mutations
                .establishmentPrepareUpload(token, {
                    contentType: image.type,
                    size: image.size,
                })
                .then((response) => {
                    const { url, key } = response.data
                    return mutations.putFile(url, image).then(() => {
                        return internalEstablishmentCreate(token, { ...payload, key })
                    })
                })
                .catch((error) => {
                    setInProgress(false)
                    throw generateError(error)
                })
        }
        return internalEstablishmentCreate(token, payload).catch((error) => {
            setInProgress(false)
            throw generateError(error)
        })
    }

    return {
        establishmentCreate,
        inProgress,
    }
}
