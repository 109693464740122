import { CommonGraphApiProvider } from '@sugg-gestion/common-graph-api-module'
import { ApplicationState } from 'core/store/reducers'
import React, { ComponentType } from 'react'
import { useSelector } from 'react-redux'

export interface WithCommonGraphApiProps {
    isCommonGraphApiInitialized: boolean
}

const withCommonGraphApi = <T extends WithCommonGraphApiProps>(ComposedComponent: ComponentType<T>) => {
    const WithCommonGraphApi: React.FC<T> = ({ ...props }) => {
        const { token } = useSelector(({ onvaauresto: { auth } }: ApplicationState) => ({
            token: auth.token,
        }))

        return (
            <CommonGraphApiProvider
                apiEndpoint={process.env.REACT_APP_COMMON_GRAPH_API_ENDPOINT ?? ''}
                apiRegion={'eu-west-1'}
                token={token}
            >
                {(isInit) => <ComposedComponent {...props} isCommonGraphApiInitialized={isInit} />}
            </CommonGraphApiProvider>
        )
    }
    return WithCommonGraphApi
}

export default withCommonGraphApi
