// export interface FacebookUserInfo {
//     id: string
//     first_name: string
//     last_name: string
//     email: string
// }

export const useFacebookProfile = () => {
    // const { REACT_APP_FACEBOOK_LOGIN_CONFIG } = process.env
    // if (!REACT_APP_FACEBOOK_LOGIN_CONFIG) {
    //     throw Error()
    // }
    const login = () => {
        return new Promise<string>((resolve, reject) => {
            const facebookRequiredPermissions = [
                'public_profile',
                'email',
                'pages_show_list',
                'pages_manage_posts',
                'pages_read_engagement',
                'business_management',
                'publish_to_groups',
                // 'pages_manage_metadata',
                // 'pages_read_user_content',
                // 'pages_manage_engagement',
                // 'read_insights',
                // 'pages_messaging',
            ]

            FB.login(
                (response: facebook.StatusResponse) => {
                    // console.log('FB.login', response)
                    const accessToken = response?.authResponse?.accessToken
                    if (accessToken) {
                        resolve(accessToken)
                    } else {
                        reject()
                    }
                },

                {
                    scope: facebookRequiredPermissions.join(','),
                    return_scopes: true,
                    auth_type: 'reauthorize',
                },
            )
        })
    }

    return {
        login,
    }
}
