import React from 'react'
import 'core/i18n'

const withTranslation = (ComposedComponent: any) => {
    const WithTranslation: React.FC = (props) => {
        return <ComposedComponent {...props} />
    }
    return WithTranslation
}

export default withTranslation
