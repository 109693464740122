import { Card, CardContent, CardHeader, Grid, LinearProgress, MenuItem } from '@material-ui/core'
import { LinearProgressProps } from '@material-ui/core/LinearProgress/LinearProgress'
import DoneIcon from '@material-ui/icons/Done'
import ErrorIcon from '@material-ui/icons/Error'
import RefreshIcon from '@material-ui/icons/Refresh'
import { Alert } from '@material-ui/lab'
import clsx from 'clsx'
import { RefreshingNotificationModel } from 'core/model/notification.model'
import moment from 'moment'
import React from 'react'
import { Trans } from 'react-i18next'
import Message from './message'
import { useStyles } from './notification.css'

interface Props {
    notification: RefreshingNotificationModel
}

const RefreshNotification: React.FC<Props> = React.forwardRef<any, Props>(({ notification }, ref) => {
    const classes = useStyles()
    const progress = (): LinearProgressProps => {
        if (notification.succeed || notification.errorMessage) {
            if (notification.errorMessage) {
                return {
                    variant: 'determinate',
                    value: notification.percent ?? 100,
                    className: classes.failed,
                }
            }
            return {
                variant: 'determinate',
                value: notification.percent ?? 100,
                className: classes.completed,
            }
        }
        if (notification.percent !== undefined && notification.percent !== null) {
            return {
                variant: 'determinate',
                value: notification.percent,
            }
        }
        return {
            variant: 'indeterminate',
            // color: 'primary',
        }
    }
    const date = moment(notification.createdAt)
    return (
        <MenuItem key={notification.id} ref={ref} button={false} dense divider>
            <Card
                elevation={0}
                classes={{
                    root: classes.root,
                }}
            >
                <CardHeader
                    avatar={
                        <RefreshIcon
                            className={clsx({
                                [classes.refreshIconRotation]: !(notification.percent === 100),
                                [classes.iconSuccess]: notification.succeed,
                                [classes.iconError]: notification.errorMessage,
                            })}
                        />
                    }
                    title={<Message message={notification.message ?? undefined} />}
                    titleTypographyProps={{
                        variant: 'body1',
                    }}
                    subheader={<Trans i18nKey="common.dateTime" values={{ date: date.toDate() }} />}
                    subheaderTypographyProps={{
                        variant: 'body2',
                    }}
                />
                <CardContent>
                    <Grid container alignItems="center">
                        <Grid item style={{ flexGrow: 1 }}>
                            <LinearProgress {...progress()} />
                        </Grid>
                        <Grid item style={{ width: 32, height: 32, textAlign: 'right' }}>
                            {notification.succeed && <DoneIcon color="inherit" className={classes.done} />}
                            {notification.errorMessage && (
                                <ErrorIcon color="inherit" className={classes.error} />
                            )}
                        </Grid>
                        {notification.errorMessage && (
                            <Grid item xs={12}>
                                <Alert severity="error" className={classes.alert}>
                                    <Message message={notification.errorMessage ?? undefined} />
                                </Alert>
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
            </Card>
        </MenuItem>
    )
})

export default RefreshNotification
