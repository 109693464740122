import { EstablishmentModel, useEstablishmentGet } from '@sugg-gestion/react-onvaauresto'
import { ApplicationState } from 'core/store/reducers'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { useApiErrors } from '../onvaauresto/useApiErrors'

export const useEstablishment = () => {
    const { establishments, token } = useSelector(
        ({ onvaauresto: { establishments, auth }, app }: ApplicationState) => ({
            establishments: establishments.establishments,
            token: auth.token,
        }),
    )
    const { displayError } = useApiErrors()

    const { establishmentGet } = useEstablishmentGet()
    const getEstablishment = (id: string) => {
        if (establishments !== undefined) {
            const establishment = _.find(establishments, { id })
            if (establishment) {
                if (establishment.isDisabled) {
                    return Promise.reject('DISABLED')
                }
                return Promise.resolve<EstablishmentModel | undefined>(establishment)
            }
        }
        if (token !== undefined) {
            return establishmentGet(token, id)
                .catch((error) => displayError(error))
                .then((establishment) => {
                    if (establishment) {
                        if (establishment.isDisabled) {
                            throw new Error('DISABLED')
                        }
                        return establishment
                    }
                    throw new Error('NOT_EXIST')
                })
        } else {
            return Promise.reject('NOT_EXIST')
        }
    }
    return { getEstablishment }
}
