import { createAction } from 'typesafe-actions'
import { AuthActionTypes, SetEstablishmentGroupsAction } from './types'
import { prefix } from '../../config'
import { EstablishmentModel } from '../../models'

const actionPrefix = '@' + prefix + '/'

export const addEstablishment = createAction(
    actionPrefix + AuthActionTypes.ADD_ESTABLISHMENT,
)<EstablishmentModel>()
export const setEstablishments = createAction(actionPrefix + AuthActionTypes.SET_ESTABLISHMENTS)<
    Array<EstablishmentModel>
>()
export const setEstablishment = createAction(
    actionPrefix + AuthActionTypes.SET_ESTABLISHMENT,
)<EstablishmentModel>()
export const setEstablishmentGroups = createAction(
    actionPrefix + AuthActionTypes.SET_ESTABLISHMENT_GROUPS,
)<SetEstablishmentGroupsAction>()
export const updateEstablishment = createAction(
    actionPrefix + AuthActionTypes.UPDATE_ESTABLISHMENT,
)<EstablishmentModel>()
export const deleteEstablishment = createAction(actionPrefix + AuthActionTypes.DELETE_ESTABLISHMENT)<string>()
export const sharePhotoEstablishment = createAction(
    actionPrefix + AuthActionTypes.SHARE_PHOTO_ESTABLISHMENT,
)<string>()

export const fetchEstablishments = createAction(actionPrefix + AuthActionTypes.FETCH_ESTABLISHMENTS)<
    string | undefined
>()
