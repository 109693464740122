import { createStyles, Theme } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import clsx from 'clsx'
import React from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            outline: 0,
            position: 'relative',
        },
        fullWidth: {
            width: '100%',
        },
        paper: {
            'position': 'absolute',
            'inset': 0,
            'width': '100%',
            'height': '100%',
            'display': 'flex',
            'justifyContent': 'center',
            'alignItems': 'center',
            'flexDirection': 'column',
            'outline': 0,
            'borderStyle': 'dashed',
            'borderWidth': 2,
            'borderColor': theme.palette.primary.main,
            'borderRadius': theme.spacing(2),
            'padding': theme.spacing(),
            '&:hover': {
                borderColor: theme.palette.primary.dark,
            },
            '& > img': {
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
            },
        },
        icon: {
            fontSize: '4rem',
        },
        outline: {
            outlineColor: theme.palette.primary.main,
            cursor: 'pointer',
        },
    }),
)

interface Props {
    files?: Array<File | string>
    onChange: (files: Array<File | string>) => void
    accept: string[]
    maxSize: number
    maxFiles?: number
    isInvalidFiles: (files: Array<FileRejection>) => boolean
    multiple?: boolean
    classes?: {
        root?: string
        container?: string
    }
}

const PhotoUpload: React.FC<Props> = ({
    onChange,
    files,
    accept,
    maxSize,
    maxFiles,
    isInvalidFiles,
    multiple = false,
    children,
    classes,
}) => {
    const { getRootProps, getInputProps } = useDropzone({
        multiple,
        accept,
        maxSize,
        maxFiles,
        onDropAccepted: (newFiles) => {
            if (multiple) {
                const filesToAdd = [...(files || []), ...newFiles]
                onChange(filesToAdd)
            } else {
                onChange([...newFiles])
            }
        },
        onDropRejected: (files) => {
            isInvalidFiles(files)
        },
    })
    const { ref, ...rootProps } = getRootProps()
    const internalClasses = useStyles()

    return (
        <div className={clsx(internalClasses.root, classes?.root)}>
            <div ref={ref} className={internalClasses.fullWidth}>
                <div
                    {...rootProps}
                    className={clsx(internalClasses.outline, classes?.container ?? internalClasses.paper)}
                >
                    <input {...getInputProps()} />
                    {children !== undefined ? (
                        children
                    ) : (
                        <React.Fragment>
                            <AddCircleIcon className={internalClasses.icon} color="primary" />
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PhotoUpload
