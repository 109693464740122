import actions from '../../../core/store/actions'
import { useState } from 'react'
import { queries } from '../../resources'
import { generateError } from '../error'
import { useDispatch } from '../redux'
import { AdminModel } from '../../models'

export const useAdminsList = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)

    const adminsList = (token: string): Promise<Array<AdminModel>> => {
        setInProgress(true)
        return queries
            .adminsList(token)
            .then(({ data }) => {
                setInProgress(false)
                if (data) {
                    dispatch(actions.setAdmins(data))
                    return data
                }
                throw generateError()
            })
            .catch((error) => {
                setInProgress(false)
                throw generateError(error)
            })
    }

    return {
        adminsList,
        inProgress,
    }
}
