import { FormControl, FormHelperText, PropTypes, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import _ from 'lodash'
import React from 'react'
import { Field } from 'react-final-form'

export interface SelectSearchValue<T> {
    label: string
    value: T
}

interface Props<T> {
    name: string
    label?: string | React.ReactNode
    possibilities: SelectSearchValue<T>[]
    required?: boolean
    autoComplete?: string
    variant?: 'standard' | 'outlined' | 'filled'
    fullWidth?: boolean
    margin?: PropTypes.Margin
}

const SelectSearchField = <T,>({
    name,
    label,
    possibilities,
    required,
    fullWidth,
    variant = 'outlined',
    margin = 'normal',
}: Props<T>) => {
    return (
        <Field<T> name={name} type="select" isEqual={_.isEqual}>
            {({ input: { name, onChange, value }, meta }) => {
                const hasError =
                    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched
                return (
                    <FormControl
                        component="fieldset"
                        fullWidth={fullWidth}
                        margin={margin}
                        required={required}
                        error={hasError}
                    >
                        <Autocomplete
                            value={_.find(possibilities, ['value', value]) || null}
                            options={possibilities}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                                onChange(newValue?.value)
                            }}
                            fullWidth={fullWidth}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    InputLabelProps={{ shrink: true }}
                                    name={name}
                                    label={label}
                                    variant={variant}
                                />
                            )}
                        />
                        {hasError && <FormHelperText error={hasError}>{meta.error}</FormHelperText>}
                    </FormControl>
                )
            }}
        </Field>
    )
}

export default SelectSearchField
