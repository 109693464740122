import React, { ComponentType } from 'react'
import { useParams } from 'react-router-dom'
import Loader from 'components/common/loader'
import NotFound from 'components/views/main/notFound'
import { RestaurantOwnerModel } from '@sugg-gestion/react-onvaauresto'
import { useRestaurantOwners } from 'core/services/restaurantOwners/useRestaurantOwners'

interface RestaurantOwnerParams {
    id: string
}

const withRestaurantOwner = (ComposedComponent: ComponentType<any>) => {
    const WithRestaurantOwner: React.FC = ({ ...props }) => {
        const { id } = useParams<RestaurantOwnerParams>()
        const { restaurantOwners } = useRestaurantOwners()

        let restaurantOwner = undefined
        if (restaurantOwners !== undefined) {
            restaurantOwner = restaurantOwners.find(
                (restaurantOwner: RestaurantOwnerModel) => restaurantOwner.id === id,
            )
        }
        if (restaurantOwner) {
            return <ComposedComponent restaurantOwner={restaurantOwner} {...props} />
        } else if (restaurantOwners === undefined) {
            return <Loader />
        } else {
            return <NotFound />
        }
    }
    return WithRestaurantOwner
}

export default withRestaurantOwner
