import { AnyAbility } from '@casl/ability'
import { Can } from '@casl/react'
import { Button, Typography } from '@material-ui/core'
import { DataGrid, GridColDef, GridSortModel, GridValueFormatterParams } from '@material-ui/data-grid'
import AddIcon from '@material-ui/icons/Add'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import GetAppIcon from '@material-ui/icons/GetApp'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import RefreshIcon from '@material-ui/icons/Refresh'
import { AddressModel, EstablishmentModel, PhotoModel } from '@sugg-gestion/react-onvaauresto'
import clsx from 'clsx'
import AlertDialog from 'components/dialogs/alertDialog'
import { useAddress } from 'core/services/address/useAddress'
import { useGridTranslation } from 'core/services/grid/useGidTranslation'
import { useWebsite } from 'core/services/webssite/useWebsite'
import _ from 'lodash'
import moment from 'moment'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import SubmitButton from '../../common/submitButton'
import { useStyles } from './establishmentsDataTable.css'

interface Props {
    establishments: Array<EstablishmentModel>
    onRefreshEstablishments: () => void
    onAddEstablishment: () => void
    onExportEstablishments: () => void
    onEditEstablishment: (id: string) => void
    onConfirmEstablishment: (id: string) => void
    onEditRestaurantOwner: (id: string) => void
    onDeleteEstablishment: (id: string) => void
    ability: AnyAbility
    loading: boolean
    exportInProgress: boolean
}

const EstablishmentsDataTable: React.FC<Props> = ({
    establishments,
    onAddEstablishment,
    onExportEstablishments,
    onEditEstablishment,
    onConfirmEstablishment,
    onEditRestaurantOwner,
    onDeleteEstablishment,
    ability,
    loading,
    exportInProgress,
    onRefreshEstablishments,
}) => {
    const classes = useStyles()
    const [deleteDialog, setDeleteDialog] = useState<string>()
    const [enableDialog, setEnableDialog] = useState<string>()
    const { displayAddress, addressValue } = useAddress()
    const { t } = useTranslation()
    const gridTranslation = useGridTranslation()
    const { getUrl } = useWebsite()
    const [pageSize, setPageSize] = useState(50)

    const deleteEstablishment = (establishment: EstablishmentModel) => {
        setDeleteDialog(establishment.id)
    }

    const enableEstablishment = (establishment: EstablishmentModel) => {
        setEnableDialog(establishment.id)
    }

    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
            field: 'createdAt',
            sort: 'desc',
        },
    ])

    const handleUpdateSortModel = (model: GridSortModel) => {
        if (!_.isEqual(model, sortModel)) {
            setSortModel(model)
        }
    }

    const columns: GridColDef[] = [
        {
            field: 'createdAt',
            headerName: t('common.createdAt'),
            width: 100,
            type: 'dateTime',
            renderCell: ({ value }) => {
                const date = moment(value as string)
                return <Trans i18nKey="common.date" values={{ date: date.toDate() }} />
            },
        },
        { field: 'id', headerName: t('common.id'), width: 300, sortable: false },
        { field: 'name', headerName: t('common.establishmentName'), width: 250 },
        {
            field: 'address',
            headerName: t('common.address'),
            width: 250,
            renderCell: ({ value }) => {
                const address = value as AddressModel
                return (
                    <Typography className={classes.address} component="span">
                        {displayAddress(address)}
                    </Typography>
                )
            },
            sortComparator: (v1, v2) => {
                const address1 = v1 as AddressModel
                const result1 = addressValue(address1)
                const address2 = v2 as AddressModel
                const result2 = addressValue(address2)
                return result1.localeCompare(result2)
            },
        },
        {
            field: 'photo',
            headerName: t('common.author'),
            width: 120,
            valueGetter: ({ value }) => {
                const photo = value as PhotoModel | undefined
                return photo?.admin?.givenName ?? photo?.admin?.username ?? null
            },
            renderCell: ({ value }) => {
                const photo = value as PhotoModel | undefined
                return <span>{photo?.admin?.givenName ?? photo?.admin?.username ?? null}</span>
            },
        },
        {
            field: 'restaurantOwner',
            filterable: false,
            headerName: t('common.restaurantOwner'),
            width: 150,
            renderCell: ({ value }) => {
                const restaurantOwnerId = value as string
                if (restaurantOwnerId) {
                    return (
                        <>
                            <Can ability={ability} I="update" a="restaurant-owner">
                                <Button
                                    onClick={() => onEditRestaurantOwner(restaurantOwnerId)}
                                    variant="outlined"
                                    size="small"
                                >
                                    <Trans i18nKey="actions.edit" />
                                </Button>
                            </Can>
                            <Can not ability={ability} I="update" a="restaurant-owner">
                                <div className={classes.restaurantOwner}>
                                    <CheckBoxIcon color="primary" />
                                </div>
                            </Can>
                        </>
                    )
                } else {
                    return <></>
                }
            },
        },
        {
            field: 'actions',
            filterable: false,
            sortable: false,
            headerName: t('common.actions'),
            width: 320,
            renderCell: (params: GridValueFormatterParams) => {
                const establishment = params.row as EstablishmentModel
                return (
                    <div className={classes.actions}>
                        {establishment.isDisabled ? (
                            <Can ability={ability} I="update" a="establishment">
                                <Button
                                    color="primary"
                                    onClick={() => enableEstablishment(establishment)}
                                    variant="contained"
                                    size="small"
                                >
                                    <Trans i18nKey="actions.enable" />
                                </Button>
                            </Can>
                        ) : (
                            <Button
                                href={getUrl(establishment)}
                                variant="outlined"
                                size="small"
                                endIcon={<OpenInNewIcon />}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Trans i18nKey="actions.check" />
                            </Button>
                        )}
                        <Can ability={ability} I="update" a="establishment">
                            <Button
                                onClick={() => onEditEstablishment(establishment.id)}
                                variant="outlined"
                                size="small"
                            >
                                <Trans i18nKey="actions.edit" />
                            </Button>
                        </Can>

                        <Can ability={ability} I="delete" a="establishment">
                            <Button
                                onClick={() => deleteEstablishment(establishment)}
                                variant="outlined"
                                color="inherit"
                                size="small"
                                className={classes.delete}
                            >
                                <Trans i18nKey="actions.delete" />
                            </Button>
                        </Can>
                    </div>
                )
            },
        },
    ]

    return (
        <div className={classes.root}>
            <div className={clsx(classes.header, classes.actions)}>
                <SubmitButton
                    loading={loading}
                    onClick={onRefreshEstablishments}
                    variant="outlined"
                    startIcon={<RefreshIcon />}
                >
                    <Trans i18nKey="actions.refresh" />
                </SubmitButton>
                <Can ability={ability} I="export" a="establishment">
                    <SubmitButton
                        loading={exportInProgress}
                        onClick={onExportEstablishments}
                        variant="outlined"
                        startIcon={<GetAppIcon />}
                    >
                        <Trans i18nKey="actions.exportEstablishment" />
                    </SubmitButton>
                </Can>
                <Can ability={ability} I="create" a="establishment">
                    <Button
                        onClick={onAddEstablishment}
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                    >
                        <Trans i18nKey="actions.addEstablishment" />
                    </Button>
                </Can>
            </div>
            <div className={classes.tableContainer}>
                <DataGrid
                    className={classes.grid}
                    rows={establishments}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(pageSize) => setPageSize(pageSize)}
                    rowsPerPageOptions={[10, 50, 100, 1000]}
                    loading={loading}
                    localeText={gridTranslation}
                    disableSelectionOnClick
                    sortModel={sortModel}
                    onSortModelChange={handleUpdateSortModel}
                    getRowClassName={(params) => {
                        return params.row.isDisabled
                            ? classes.disabled
                            : params.row.remplisVert
                            ? classes.remplisVert
                            : ''
                    }}
                />
            </div>
            <AlertDialog
                open={deleteDialog !== undefined}
                title={t('establishment.delete.question')}
                buttons={{ yes: t('common.yes'), no: t('common.no') }}
                onClose={() => setDeleteDialog(undefined)}
                callback={() => {
                    if (deleteDialog) {
                        onDeleteEstablishment(deleteDialog)
                    }
                }}
            />
            <AlertDialog
                open={enableDialog !== undefined}
                title={t('establishment.enable.question')}
                buttons={{ yes: t('common.yes'), no: t('common.no') }}
                onClose={() => setEnableDialog(undefined)}
                callback={() => {
                    if (enableDialog) {
                        onConfirmEstablishment(enableDialog)
                    }
                }}
            />
        </div>
    )
}

export default EstablishmentsDataTable
