export const isValidUrl = (text: string) => {
    let url

    try {
        url = new URL(text)
    } catch (_) {
        return false
    }

    return url.protocol === 'http:' || url.protocol === 'https:'
}
