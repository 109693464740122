import { FormControl, FormControlLabel, Grid, Switch } from '@material-ui/core'
import SubmitButton from 'components/common/submitButton'
import { SharedFacebookGroupModel } from 'core/model/sharedFacebookGroup.model'
import { SharedFacebookPageModel } from 'core/model/sharedFacebookPage.model'
import React from 'react'
import { Field, Form, FormSpy } from 'react-final-form'
import SelectSearchField, { SelectSearchValue } from './components/selectSearchField'
import TextField from './components/textField'
import { useFormsStyles } from './forms.css'

export interface GroupFormState {
    name: string
    latitude: number
    longitude: number
    radius: number
    public: boolean
    sharedFacebookPageId?: string
    sharedFacebookGroupId?: string
}

export interface GroupFormStateInternal {
    name: string
    latitude: number
    longitude: number
    radius: number
    public: boolean
    sharedFacebookPage?: SharedFacebookPageModel
    sharedFacebookGroup?: SharedFacebookGroupModel
}

interface GroupFormErrors {
    name?: string
    latitude?: string
    longitude?: string
    radius?: string
    public?: string
    sharedFacebookPage?: string
    sharedFacebookGroup?: string
}

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: GroupFormState) => void
    inProgress: boolean
    initialValues: GroupFormState
    sharedFacebookPages: SharedFacebookPageModel[]
    sharedFacebookGroups: SharedFacebookGroupModel[]
}

const GroupForm: React.FC<Props> = ({
    subscription,
    onSubmit,
    initialValues,
    inProgress,
    sharedFacebookPages,
    sharedFacebookGroups,
}) => {
    const formsClasses = useFormsStyles()

    const validate = (values: GroupFormStateInternal) => {
        const errors: GroupFormErrors = {}
        if (!values.sharedFacebookGroup) {
            errors.sharedFacebookGroup = 'Vous devez choisir un groupe !'
        }
        if (!values.sharedFacebookPage) {
            errors.sharedFacebookPage = 'Vous devez choisir une page !'
        }
        if (!values.latitude) {
            errors.latitude = 'Vous devez renseigner la latitude !'
        }
        if (!values.longitude) {
            errors.longitude = 'Vous devez renseigner la longitude !'
        }
        if (!values.radius) {
            errors.radius = 'Vous devez renseigner le rayon !'
        }
        return errors
    }

    const sharedFacebookPagesPossibilities: SelectSearchValue<SharedFacebookPageModel>[] = sharedFacebookPages
        .map((sharedFacebookPage) => ({
            label: sharedFacebookPage.name,
            value: sharedFacebookPage,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))

    const handleSubmit = (values: GroupFormStateInternal) => {
        return onSubmit({
            name: values.sharedFacebookGroup?.name ?? '',
            sharedFacebookPageId: values.sharedFacebookPage?.id,
            sharedFacebookGroupId: values.sharedFacebookGroup?.id,
            radius: parseInt(String(values.radius)),
            latitude: parseFloat(String(values.latitude)),
            longitude: parseFloat(String(values.longitude)),
            public: values.public,
        })
    }

    const internalInitialValues: GroupFormStateInternal = {
        sharedFacebookPage: sharedFacebookPages.find(
            (sharedFacebookPage) => sharedFacebookPage.id === initialValues.sharedFacebookPageId,
        ),
        sharedFacebookGroup: sharedFacebookGroups.find(
            (sharedFacebookGroup) => sharedFacebookGroup.id === initialValues.sharedFacebookGroupId,
        ),
        ...initialValues,
    }

    return (
        <Form
            initialValues={internalInitialValues}
            subscription={subscription} //46.71109 1.7191036
            // debug={console.log}
            validate={validate}
            onSubmit={handleSubmit}
        >
            {({ handleSubmit, submitting, form: { reset } }) => {
                return (
                    <form noValidate>
                        <Grid container spacing={2} alignItems="flex-end">
                            <Grid item xs={12}>
                                <SelectSearchField
                                    name="sharedFacebookPage"
                                    label="Page Facebook"
                                    possibilities={sharedFacebookPagesPossibilities}
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormSpy subscription={{ values: true }}>
                                    {(props) => {
                                        const sharedFacebookPage = props.values.sharedFacebookPage as
                                            | SharedFacebookPageModel
                                            | undefined
                                        return (
                                            <SelectSearchField
                                                name="sharedFacebookGroup"
                                                label="Groupe Facebook"
                                                possibilities={sharedFacebookGroups
                                                    .filter(
                                                        (group) =>
                                                            (group.from === 'INSTALL' &&
                                                                group.profile.facebookId ===
                                                                    sharedFacebookPage?.profile.facebookId) ||
                                                            (group.from === 'PROFILE' &&
                                                                sharedFacebookPage?.groups.findIndex(
                                                                    (allowedGroup) =>
                                                                        allowedGroup === group.groupId,
                                                                ) !== -1),
                                                    )
                                                    .map((group) => ({
                                                        label: group.name,
                                                        value: group,
                                                    }))
                                                    .sort((a, b) => a.label.localeCompare(b.label))}
                                                required
                                                fullWidth
                                            />
                                        )
                                    }}
                                </FormSpy>
                            </Grid>

                            <Grid item xs={4}>
                                <TextField label="Latitude" name="latitude" required type="number" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField label="Longitude" name="longitude" required type="number" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField label="Rayon (en km)" name="radius" required type="number" />
                            </Grid>

                            <Grid item xs={4}>
                                <FormControl>
                                    <Field name="public">
                                        {({ input: { value, onChange } }) => (
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        color="primary"
                                                        checked={value}
                                                        onChange={() => onChange(!value)}
                                                    />
                                                }
                                                label="Est public"
                                            />
                                        )}
                                    </Field>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div className={formsClasses.actions}>
                            <SubmitButton
                                variant="contained"
                                color="primary"
                                type="submit"
                                loading={submitting || inProgress}
                                onClick={(event) => {
                                    handleSubmit(event)?.then(() => {
                                        reset()
                                    })
                                }}
                            >
                                Valider
                            </SubmitButton>
                        </div>
                    </form>
                )
            }}
        </Form>
    )
}

export default GroupForm
