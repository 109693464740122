import { call, put, takeLatest } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'
import actions from '../actions'
import { queries } from '../../resources'
import { EstablishmentModel } from '../../models'
import { generateError } from '../../services/error'
import { AxiosResponse } from 'axios'

function* establishmentsSaga() {
    yield takeLatest(getType(actions.fetchEstablishments), fetchEstablishments)
}

function* fetchEstablishments(action: ActionType<typeof actions.fetchEstablishments>) {
    const token = action.payload
    try {
        const { data }: AxiosResponse<Array<EstablishmentModel>> = yield call(
            queries.establishmentsList,
            token,
        )
        if (data) {
            yield put(actions.setEstablishments(data))
        } else {
            yield put(actions.setError(generateError()))
        }
    } catch (error) {
        yield put(actions.setError(generateError(error)))
    }
}

export default establishmentsSaga
