import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        photoField: {
            maxWidth: 240,
            margin: 'auto',
        },
        photoFieldAdd: {
            fontSize: '4rem',
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        action: {
            [theme.breakpoints.down('md')]: {
                position: 'static',
                top: 'inherit',
                right: 'inherit',
                transform: 'inherit',
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: theme.spacing(0.5),
            },
        },
        facebookIcon: {
            color: theme.socialColors.facebook,
        },
    }),
)
