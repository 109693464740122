import { Grid } from '@material-ui/core'
import { PermissionModel } from '@sugg-gestion/react-onvaauresto'
import {
    AdminPermissionsValues,
    EstablishmentPermissionsValues,
    FacebookPermissionsValues,
    GroupPermissionsValues,
    RestaurantOwnerPermissionsValues,
} from 'components/forms/helpers/usePermissions'
import React from 'react'
import { useTranslation } from 'react-i18next'
import CheckboxGroupField, { CheckboxValue } from './checkboxGroupField'

type Options = 'admins' | 'establishments' | 'restaurantOwners' | 'groups' | 'facebook'

interface Props {
    name: string
    label: string
    resource: string
    possibilities: Options[]
    required?: boolean
    onUpdate?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

const PermissionsField: React.FC<Props> = ({ resource, possibilities, ...props }) => {
    const { t } = useTranslation()

    const adminPermissions: Array<CheckboxValue<PermissionModel>> = [
        {
            label: t('permissions.display', {
                action: t('permissions.actions.read'),
                subject: t('permissions.subjects.admin'),
            }),
            value: AdminPermissionsValues.Read,
        },
        {
            label: t('permissions.display', {
                action: t('permissions.actions.create'),
                subject: t('permissions.subjects.admin'),
            }),
            value: AdminPermissionsValues.Create,
        },
        {
            label: t('permissions.display', {
                action: t('permissions.actions.update'),
                subject: t('permissions.subjects.admin'),
            }),
            value: AdminPermissionsValues.Update,
        },
        {
            label: t('permissions.display', {
                action: t('permissions.actions.delete'),
                subject: t('permissions.subjects.admin'),
            }),
            value: AdminPermissionsValues.Delete,
        },
        {
            label: t('permissions.display', {
                action: t('permissions.actions.export'),
                subject: t('permissions.subjects.admin'),
            }),
            value: AdminPermissionsValues.Export,
        },
        {
            label: t('permissions.display', {
                action: t('permissions.actions.manage'),
                subject: t('permissions.subjects.admin'),
            }),
            value: AdminPermissionsValues.Manage,
        },
    ]

    const establishmentPermissions: Array<CheckboxValue<PermissionModel>> = [
        {
            label: t('permissions.display', {
                action: t('permissions.actions.read'),
                subject: t('permissions.subjects.establishment'),
            }),
            value: EstablishmentPermissionsValues.Read,
        },
        {
            label: t('permissions.display', {
                action: t('permissions.actions.create'),
                subject: t('permissions.subjects.establishment'),
            }),
            value: EstablishmentPermissionsValues.Create,
        },
        {
            label: t('permissions.display', {
                action: t('permissions.actions.update'),
                subject: t('permissions.subjects.establishment'),
            }),
            value: EstablishmentPermissionsValues.Update,
        },
        {
            label: t('permissions.display', {
                action: t('permissions.actions.delete'),
                subject: t('permissions.subjects.establishment'),
            }),
            value: EstablishmentPermissionsValues.Delete,
        },
        {
            label: t('permissions.display', {
                action: t('permissions.actions.shareFacebook'),
                subject: t('permissions.subjects.establishment'),
            }),
            value: EstablishmentPermissionsValues.Share,
        },
        {
            label: t('permissions.display', {
                action: t('permissions.actions.export'),
                subject: t('permissions.subjects.establishment'),
            }),
            value: EstablishmentPermissionsValues.Export,
        },
        {
            label: t('permissions.display', {
                action: t('permissions.actions.manage'),
                subject: t('permissions.subjects.establishment'),
            }),
            value: EstablishmentPermissionsValues.Manage,
        },
    ]

    const restaurantOwnerPermissions: Array<CheckboxValue<PermissionModel>> = [
        {
            label: t('permissions.display', {
                action: t('permissions.actions.read'),
                subject: t('permissions.subjects.restaurant-owner'),
            }),
            value: RestaurantOwnerPermissionsValues.Read,
        },
        {
            label: t('permissions.display', {
                action: t('permissions.actions.create'),
                subject: t('permissions.subjects.restaurant-owner'),
            }),
            value: RestaurantOwnerPermissionsValues.Create,
        },
        {
            label: t('permissions.display', {
                action: t('permissions.actions.update'),
                subject: t('permissions.subjects.restaurant-owner'),
            }),
            value: RestaurantOwnerPermissionsValues.Update,
        },
        {
            label: t('permissions.display', {
                action: t('permissions.actions.delete'),
                subject: t('permissions.subjects.restaurant-owner'),
            }),
            value: RestaurantOwnerPermissionsValues.Delete,
        },
        {
            label: t('permissions.display', {
                action: t('permissions.actions.export'),
                subject: t('permissions.subjects.restaurant-owner'),
            }),
            value: RestaurantOwnerPermissionsValues.Export,
        },
        {
            label: t('permissions.display', {
                action: t('permissions.actions.manage'),
                subject: t('permissions.subjects.restaurant-owner'),
            }),
            value: RestaurantOwnerPermissionsValues.Manage,
        },
    ]

    const groupPermissions: Array<CheckboxValue<PermissionModel>> = [
        {
            label: t('permissions.display', {
                action: t('permissions.actions.read'),
                subject: t('permissions.subjects.group'),
            }),
            value: GroupPermissionsValues.Read,
        },
        {
            label: t('permissions.display', {
                action: t('permissions.actions.create'),
                subject: t('permissions.subjects.group'),
            }),
            value: GroupPermissionsValues.Create,
        },
        {
            label: t('permissions.display', {
                action: t('permissions.actions.update'),
                subject: t('permissions.subjects.group'),
            }),
            value: GroupPermissionsValues.Update,
        },
        {
            label: t('permissions.display', {
                action: t('permissions.actions.delete'),
                subject: t('permissions.subjects.group'),
            }),
            value: GroupPermissionsValues.Delete,
        },
        {
            label: t('permissions.display', {
                action: t('permissions.actions.export'),
                subject: t('permissions.subjects.group'),
            }),
            value: GroupPermissionsValues.Export,
        },
        {
            label: t('permissions.display', {
                action: t('permissions.actions.manage'),
                subject: t('permissions.subjects.group'),
            }),
            value: GroupPermissionsValues.Manage,
        },
    ]

    const facebookPermissions: Array<CheckboxValue<PermissionModel>> = [
        {
            label: t('permissions.display', {
                action: t('permissions.actions.read'),
                subject: t('permissions.subjects.facebook'),
            }),
            value: FacebookPermissionsValues.Read(resource),
        },
        {
            label: t('permissions.display', {
                action: t('permissions.actions.link'),
                subject: t('permissions.subjects.facebook'),
            }),
            value: FacebookPermissionsValues.Link(resource),
        },
        {
            label: t('permissions.display', {
                action: t('permissions.actions.unlink'),
                subject: t('permissions.subjects.facebook'),
            }),
            value: FacebookPermissionsValues.Unlink(resource),
        },
        {
            label: t('permissions.display', {
                action: t('permissions.actions.postPage'),
                subject: t('permissions.subjects.facebook'),
            }),
            value: FacebookPermissionsValues.PostPage(resource),
        },
        {
            label: t('permissions.display', {
                action: t('permissions.actions.postGroup'),
                subject: t('permissions.subjects.facebook'),
            }),
            value: FacebookPermissionsValues.PostGroup(resource),
        },
        {
            label: t('permissions.display', {
                action: t('permissions.actions.share'),
                subject: t('permissions.subjects.facebookProfile'),
            }),
            value: FacebookPermissionsValues.Share(resource),
        },
        {
            label: t('permissions.display', {
                action: t('permissions.actions.manage'),
                subject: t('permissions.subjects.facebook'),
            }),
            value: FacebookPermissionsValues.Manage(resource),
        },
    ]

    return (
        <Grid container spacing={4}>
            {possibilities.findIndex((possibility) => possibility === 'admins') !== -1 && (
                <Grid item sm={4} xs={12}>
                    <CheckboxGroupField {...props} values={adminPermissions} label={t('drawer.users')} />
                </Grid>
            )}
            {possibilities.findIndex((possibility) => possibility === 'establishments') !== -1 && (
                <Grid item sm={4} xs={12}>
                    <CheckboxGroupField
                        {...props}
                        values={establishmentPermissions}
                        label={t('drawer.establishments')}
                    />
                </Grid>
            )}
            {possibilities.findIndex((possibility) => possibility === 'restaurantOwners') !== -1 && (
                <Grid item sm={4} xs={12}>
                    <CheckboxGroupField
                        {...props}
                        values={restaurantOwnerPermissions}
                        label={t('drawer.restaurantOwners')}
                    />
                </Grid>
            )}
            {possibilities.findIndex((possibility) => possibility === 'groups') !== -1 && (
                <Grid item sm={4} xs={12}>
                    <CheckboxGroupField {...props} values={groupPermissions} label={t('drawer.groups')} />
                </Grid>
            )}
            {possibilities.findIndex((possibility) => possibility === 'facebook') !== -1 && (
                <Grid item sm={4} xs={12}>
                    <CheckboxGroupField
                        {...props}
                        values={facebookPermissions}
                        label={t('drawer.facebook')}
                    />
                </Grid>
            )}
        </Grid>
    )
}

export default PermissionsField
