import { Button, CardActions } from '@material-ui/core'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { NotificationAction, NotificationActionType } from 'core/model/notification.model'
import React from 'react'
import Message from './message'
import { useStyles } from './notification.css'

interface Props {
    actions: NotificationAction[]
}

const NotificationActions: React.FC<Props> = ({ actions }) => {
    const classes = useStyles()
    return (
        <CardActions
            classes={{
                root: classes.actions,
            }}
        >
            {actions.map((action, index) => {
                switch (action.type) {
                    case NotificationActionType.Open: {
                        return (
                            <Button
                                variant="outlined"
                                classes={{
                                    label: classes.action,
                                }}
                                href={action.url ?? ''}
                                endIcon={<OpenInNewIcon />}
                                target="_blank"
                                rel="noreferrer"
                                key={'action-' + index}
                            >
                                <Message message={action.text ?? undefined} />
                            </Button>
                        )
                    }
                    default: {
                        return null
                    }
                }
            })}
        </CardActions>
    )
}

export default NotificationActions
