import { CardActions, Divider } from '@material-ui/core'
import HiddenCss from '@material-ui/core/Hidden/HiddenCss'
import clsx from 'clsx'
import React from 'react'
import { useStyles } from './fullscreenFormLayout.css'

interface Props {
    content: React.ReactNode
    action: React.ReactNode
}

const FullscreenFormLayout: React.FC<Props> = ({ content, action }) => {
    const classes = useStyles()
    return (
        <div className={classes.section}>
            <div className={clsx(classes.cardContent, classes.withAction)}>{content}</div>

            <div className={classes.actionsContainer}>
                <HiddenCss smUp>
                    <Divider />
                </HiddenCss>
                <CardActions className={clsx(classes.actions)}>{action}</CardActions>
            </div>
        </div>
    )
}

export default FullscreenFormLayout
