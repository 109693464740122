import { Card, CardContent, CardHeader, Grid, LinearProgress, MenuItem } from '@material-ui/core'
import { LinearProgressProps } from '@material-ui/core/LinearProgress/LinearProgress'
import DoneIcon from '@material-ui/icons/Done'
import ErrorIcon from '@material-ui/icons/Error'
import PublishIcon from '@material-ui/icons/Publish'
import { Alert } from '@material-ui/lab'
import clsx from 'clsx'
import { NotificationAction, PublishingNotificationModel } from 'core/model/notification.model'
import moment from 'moment'
import React from 'react'
import { Trans } from 'react-i18next'
import Message from './message'
import { useStyles } from './notification.css'
import NotificationActions from './notificationActions'
import NotificationPost from './notificationPost'

interface Props {
    notification: PublishingNotificationModel
}

const PublishNotification: React.FC<Props> = React.forwardRef<any, Props>(({ notification }, ref) => {
    const classes = useStyles()
    const progress = (): LinearProgressProps => {
        if (notification.succeed || notification.errorMessage) {
            if (notification.errorMessage) {
                return {
                    variant: 'determinate',
                    value: 100,
                    className: classes.failed,
                }
            }
            return {
                variant: 'determinate',
                value: 100,
                className: classes.completed,
            }
        }
        if (notification.started) {
            return {
                variant: 'indeterminate',
            }
        }
        return {
            variant: 'determinate',
            value: 0,
            color: 'secondary',
        }
    }
    const actions: NotificationAction[] = []
    if (notification.actions) {
        ;(notification.actions ?? []).forEach((action) => {
            if (action) {
                actions.push(action)
            }
        })
    }

    const date = moment(notification.createdAt)
    return (
        <MenuItem key={notification.id} ref={ref} button={false} dense divider>
            <Card
                elevation={0}
                classes={{
                    root: classes.root,
                }}
            >
                <CardHeader
                    avatar={
                        <PublishIcon
                            className={clsx({
                                [classes.publishIconRise]: notification.started,
                                [classes.iconSuccess]: notification.succeed,
                                [classes.iconError]: notification.errorMessage,
                            })}
                        />
                    }
                    title={<Message message={notification.message ?? undefined} />}
                    titleTypographyProps={{
                        variant: 'body1',
                    }}
                    subheader={<Trans i18nKey="common.dateTime" values={{ date: date.toDate() }} />}
                    subheaderTypographyProps={{
                        variant: 'body2',
                    }}
                    classes={{
                        avatar: classes.avatarOverflow,
                    }}
                />
                <CardContent>
                    <Grid container alignItems="center">
                        <Grid item style={{ flexGrow: 1 }}>
                            <LinearProgress {...progress()} />
                        </Grid>
                        <Grid item style={{ width: 32, height: 32, textAlign: 'right' }}>
                            {notification.succeed && <DoneIcon color="inherit" className={classes.done} />}
                            {notification.errorMessage && (
                                <ErrorIcon color="inherit" className={classes.error} />
                            )}
                        </Grid>
                        {notification.errorMessage && (
                            <Grid item xs={12}>
                                <Alert severity="error" className={classes.alert}>
                                    <Message message={notification.errorMessage ?? undefined} />
                                </Alert>
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
                {notification.post && <NotificationPost post={notification.post} />}
                {actions && <NotificationActions actions={actions} />}
            </Card>
        </MenuItem>
    )
})

export default PublishNotification
