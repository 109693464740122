import actions from '../../../core/store/actions'
import { useState } from 'react'
import { queries } from '../../resources'
import { generateError } from '../error'
import { useDispatch } from '../redux'
import { EstablishmentModel } from '../../models'

export const useEstablishmentsList = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)

    const establishmentsList = (
        token?: string,
        withDisabled?: boolean,
    ): Promise<Array<EstablishmentModel>> => {
        setInProgress(true)
        return queries
            .establishmentsList(token, withDisabled)
            .then(({ data }) => {
                if (data) {
                    dispatch(actions.setEstablishments(data))
                    setInProgress(false)
                    return data
                }
                setInProgress(false)
                throw generateError()
            })
            .catch((error) => {
                setInProgress(false)
                throw generateError(error)
            })
    }

    return {
        establishmentsList,
        inProgress,
    }
}
