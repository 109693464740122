// import { call, put, takeLatest } from 'redux-saga/effects'
// import { getType } from 'typesafe-actions'
// import { AxiosResponse } from 'axios'
// import actions from '../actions'

function* authSaga() {
    // yield takeLatest(getType(actions.signIn), getProfile)
    // yield takeLatest(getType(actions.refresh), scheduleRefreshToken)
}

// let renewTimeout: NodeJS.Timeout | undefined = undefined
// const delay = (ms: number) =>
//     new Promise(res => {
//         renewTimeout = setTimeout(res, ms)
//     })

// function* scheduleRefreshToken(action: ActionType<typeof actions.signIn>) {
//     const { token } = action.payload
//     let nextTimeRenew = getNextTimeRenew(token)
//     if (nextTimeRenew > 0) {
//         if (renewTimeout !== undefined) {
//             clearTimeout(renewTimeout)
//         }
//         yield delay(nextTimeRenew)
//         yield refreshToken(action)
//     }
// }
//
// function* refreshToken(action: ActionType<typeof actions.signIn>) {
//     try {
//         const response: FetchResult<{
//             refreshToken: UserResponse
//         }> = yield call(mutations.refreshToken, action.payload)
//         if (response.data) {
//             yield put(actions.refresh(response.data.refreshToken))
//         }
//     } catch (ignore) {
//         // ignore refresh token error
//     }
// }

// export function* getProfile() {
//     try {
//         const response: AxiosResponse<User> = yield call(queries.getProfile)
//         if (response.data) {
//             yield put(actions.setUser(response.data))
//         } else {
//             yield put(actions.signOut())
//         }
//     } catch (ignore) {
//         yield put(actions.signOut())
//     }
// }

export default authSaga
