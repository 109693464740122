import { Reducer } from 'redux'
import { EstablishmentsState, SetEstablishmentGroupsAction } from './types'
import { getType } from 'typesafe-actions'
import actions from '../actions'
import { EstablishmentModel, RestaurantOwnerModel } from '../../models'

const initialState: EstablishmentsState = {}

const reducer: Reducer<EstablishmentsState> = (state = initialState, action) => {
    switch (action.type) {
        case getType(actions.addEstablishment): {
            const admin: EstablishmentModel = action.payload
            const { establishments } = state
            if (establishments !== undefined) {
                establishments.push(admin)
            }
            return {
                ...state,
                establishments,
            }
        }
        case getType(actions.setEstablishments): {
            const establishments: Array<EstablishmentModel> = action.payload
            return {
                ...state,
                establishments,
            }
        }
        case getType(actions.updateEstablishment): {
            const establishment: EstablishmentModel = action.payload
            let { establishments, establishmentsGroups } = state
            if (establishmentsGroups) {
                delete establishmentsGroups[establishment.id]
            }
            if (establishments !== undefined) {
                establishments = establishments.map((searchEstablishment) => {
                    if (searchEstablishment.id === establishment.id) {
                        return establishment
                    }
                    return searchEstablishment
                })
            }
            return {
                ...state,
                establishments,
            }
        }
        case getType(actions.setEstablishment): {
            const establishment: EstablishmentModel = action.payload
            let { establishments } = state
            let found = false
            if (establishments !== undefined) {
                establishments = establishments.map((searchEstablishment) => {
                    if (searchEstablishment.id === establishment.id) {
                        found = true
                        return establishment
                    }
                    return searchEstablishment
                })
            }
            if (!found) {
                if (establishments !== undefined) {
                    establishments.push(establishment)
                } else {
                    establishments = [establishment]
                }
            }
            return {
                ...state,
                establishments,
            }
        }
        case getType(actions.deleteEstablishment): {
            const id: string = action.payload
            const { establishments } = state
            if (establishments !== undefined) {
                const establishmentIndex = establishments.findIndex(
                    (establishment) => establishment.id === id,
                )
                if (establishmentIndex !== -1) {
                    establishments.splice(establishmentIndex, 1)
                }
            }
            return {
                ...state,
                establishments,
            }
        }
        case getType(actions.sharePhotoEstablishment): {
            const id: string = action.payload
            const { establishments } = state
            if (establishments !== undefined) {
                const establishmentIndex = establishments.findIndex(
                    (establishment) => establishment.id === id,
                )
                if (establishmentIndex !== -1) {
                    const photo = establishments[establishmentIndex].photo
                    if (photo) {
                        establishments[establishmentIndex].photo = {
                            ...photo,
                            isFacebookShared: true,
                        }
                    }
                }
            }
            return {
                ...state,
                establishments,
            }
        }
        case getType(actions.setEstablishmentGroups): {
            const payload: SetEstablishmentGroupsAction = action.payload
            let { establishmentsGroups } = state
            if (establishmentsGroups) {
                establishmentsGroups[payload.establishmentId] = payload.groups
            } else {
                establishmentsGroups = {
                    [payload.establishmentId]: payload.groups,
                }
            }
            return {
                ...state,
                establishmentsGroups,
            }
        }
        case getType(actions.updateRestaurantOwner): {
            const restaurantOwner: RestaurantOwnerModel = action.payload
            const { establishments } = state
            if (establishments !== undefined) {
                // remove association establishment -> restaurantOwner
                const establishmentIndex = establishments.findIndex(
                    (establishment) => establishment.restaurantOwner === restaurantOwner.id,
                )
                if (establishmentIndex !== -1) {
                    establishments[establishmentIndex].restaurantOwner = undefined
                }
                // add association establishment -> restaurantOwner
                if (restaurantOwner.establishment) {
                    const establishmentIndex = establishments.findIndex(
                        (establishment) => establishment.id === restaurantOwner.establishment,
                    )
                    if (establishmentIndex !== -1) {
                        establishments[establishmentIndex].restaurantOwner = restaurantOwner.id
                    }
                }
            }
            return {
                ...state,
                establishments,
            }
        }
        case getType(actions.start): {
            const started = action.payload
            if (!started) {
                return initialState
            }
            return state
        }
        case getType(actions.adminSignOut): {
            return {
                ...state,
                establishments: undefined,
            }
        }
        default:
            return state
    }
}

export { reducer as establishmentsReducer }
