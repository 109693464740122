import { AnyAbility } from '@casl/ability'
import { Can } from '@casl/react'
import { Button, ListSubheader, Typography } from '@material-ui/core'
import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import AddIcon from '@material-ui/icons/Add'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import FacebookIcon from '@material-ui/icons/Facebook'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import PeopleIcon from '@material-ui/icons/People'
import PostAddIcon from '@material-ui/icons/PostAdd'
import RestaurantIcon from '@material-ui/icons/Restaurant'
import { AdminModel } from '@sugg-gestion/react-onvaauresto'
import Logo from 'components/common/logo'
import ChiefIcon from 'components/icons/chiefIcon'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { useStyles } from './authenticateDrawer.css'

interface Props {
    admin: AdminModel
    signOut: () => void
    ability: AnyAbility
}

const AuthenticateDrawer: React.FC<Props> = ({ signOut, admin, ability }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const history = useHistory()

    return (
        <div>
            <Hidden xsDown implementation="css">
                <div className={classes.toolbar}>
                    <Logo className={classes.logoContainer} spacing={0} />
                </div>
            </Hidden>
            <div className={classes.content}>
                <div className={classes.titleContainer}>
                    <Typography variant="h1" color="textSecondary">
                        <Trans i18nKey="drawer.title" />
                    </Typography>
                    <Typography color="textSecondary">
                        <Trans
                            i18nKey="drawer.welcome"
                            values={{
                                name: admin.givenName ?? admin.username,
                            }}
                            components={[<Typography component="span" color="primary" />]}
                        />
                    </Typography>
                </div>
                <div className={classes.action}>
                    <Button
                        component={Link}
                        to="/establishment/add"
                        variant="contained"
                        color="primary"
                        endIcon={
                            history.location.pathname === '/establishment/add' ? (
                                <NavigateNextIcon />
                            ) : (
                                <AddIcon />
                            )
                        }
                    >
                        <Trans i18nKey="actions.addEstablishment" />
                    </Button>
                </div>
                <List
                    component="nav"
                    subheader={
                        <ListSubheader
                            component={Typography}
                            classes={{
                                root: classes.listTitle,
                            }}
                        >
                            <Trans i18nKey="drawer.information" />
                        </ListSubheader>
                    }
                >
                    <Can I="read" a="restaurant-owner" ability={ability}>
                        <ListItem
                            button
                            to="/restaurant-owners"
                            component={Link}
                            className={classes.listItem}
                            selected={history.location.pathname === '/restaurant-owners'}
                        >
                            <ListItemIcon>
                                <ChiefIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('drawer.restaurantOwners')} />
                        </ListItem>
                    </Can>
                    <Can I="read" a="establishment" ability={ability}>
                        <ListItem
                            button
                            to="/establishments"
                            component={Link}
                            className={classes.listItem}
                            selected={history.location.pathname === '/establishments'}
                        >
                            <ListItemIcon>
                                <RestaurantIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('drawer.establishments')} />
                        </ListItem>
                    </Can>
                    <Can I="read" a="group" ability={ability}>
                        <ListItem
                            button
                            to="/groups"
                            component={Link}
                            className={classes.listItem}
                            selected={history.location.pathname === '/groups'}
                        >
                            <ListItemIcon>
                                <GroupWorkIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('drawer.groups')} />
                        </ListItem>
                    </Can>
                    <Can I="read" a="admin" ability={ability}>
                        <ListItem
                            button
                            to="/admins"
                            component={Link}
                            className={classes.listItem}
                            selected={history.location.pathname === '/admins'}
                        >
                            <ListItemIcon>
                                <PeopleIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('drawer.users')} />
                        </ListItem>
                    </Can>
                    <Can I="postPage" a="facebook" ability={ability}>
                        <ListItem
                            button
                            to="/facebook/page"
                            component={Link}
                            className={classes.listItem}
                            selected={history.location.pathname === '/facebook/page'}
                        >
                            <ListItemIcon>
                                <PostAddIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('drawer.facebookPublishPage')} />
                        </ListItem>
                    </Can>
                    <Can I="link" a="facebook" ability={ability}>
                        <ListItem
                            button
                            to="/facebook/profiles"
                            component={Link}
                            className={classes.listItem}
                            selected={history.location.pathname === '/facebook/profiles'}
                        >
                            <ListItemIcon>
                                <FacebookIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('drawer.facebookProfiles')} />
                        </ListItem>
                    </Can>
                </List>
                <List
                    component="nav"
                    subheader={
                        <ListSubheader
                            component={Typography}
                            classes={{
                                root: classes.listTitle,
                            }}
                        >
                            <Trans i18nKey="drawer.settings" />
                        </ListSubheader>
                    }
                >
                    <ListItem
                        button
                        to="/account"
                        component={Link}
                        className={classes.listItem}
                        selected={history.location.pathname === '/account'}
                    >
                        <ListItemIcon>
                            <AccountCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('drawer.account')} />
                    </ListItem>
                    <ListItem button className={classes.listItem} onClick={signOut}>
                        <ListItemIcon>
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('drawer.logOut')} />
                    </ListItem>
                </List>
            </div>
        </div>
    )
}

export default AuthenticateDrawer
