import { Reducer } from 'redux'
import { getType } from 'typesafe-actions'
import actions from '../actions'
import { CommonState } from './types'

const initialState: CommonState = {
    loading: false,
    notifications: [],
}

const reducer: Reducer<CommonState> = (state = initialState, action) => {
    switch (action.type) {
        case getType(actions.setLoading): {
            const loading = action.payload
            return {
                ...state,
                loading,
            }
        }
        case getType(actions.setNotifications): {
            const notifications = action.payload
            return {
                ...state,
                notifications,
            }
        }
        default:
            return state
    }
}

export { reducer as commonReducer }
