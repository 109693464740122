import {
    ButtonBase,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    Hidden,
    Switch,
    Typography,
    useMediaQuery,
} from '@material-ui/core'
import { GridSize } from '@material-ui/core/Grid/Grid'
import { useTheme } from '@material-ui/core/styles'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import clsx from 'clsx'
import _ from 'lodash'
import React, { useState } from 'react'
import { FormSpy } from 'react-final-form'
import { Trans } from 'react-i18next'
import { useFormsStyles } from '../forms.css'
import { useFlippable } from '../helpers/useFlippable'
import { SortedImage } from '../helpers/useSortedImages'
import DraggableImage from './draggableImage'
import SortedImagesField from './sortedImagesField'
import TextField from './textField'

interface Props<T extends SortedImage> extends Partial<Record<Breakpoint, boolean | GridSize>> {
    mutators: Record<string, (...args: any[]) => any>
    defaultProperties: T
}

const PhotoContainer = <T extends SortedImage>({ mutators, defaultProperties, ...gridSize }: Props<T>) => {
    const theme = useTheme()
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true })

    const formsClasses = useFormsStyles()
    const [displayOptions, setDisplayOptions] = useState(false)
    const { onDisplayImagesOptions, shouldDisplayImagesOptions } = useFlippable()

    return (
        <FormSpy subscription={{ values: true }}>
            {(props) => (
                <SortedImagesField<T>
                    name="photos"
                    errorName="photo"
                    maxFiles={10}
                    defaultProperties={defaultProperties}
                >
                    {(images, Uploader, hasError, error) => (
                        <>
                            <Typography
                                color="textSecondary"
                                className={clsx(formsClasses.formTitle, formsClasses.noPaddingTop, {
                                    [formsClasses.error]: hasError,
                                })}
                            >
                                <Trans i18nKey="form.photosImage" values={{ count: images.length }} />
                                <Hidden smUp>
                                    {images.length > 1 && (
                                        <FormControlLabel
                                            className={formsClasses.options}
                                            control={
                                                <Switch
                                                    size="small"
                                                    color="primary"
                                                    checked={displayOptions}
                                                    onChange={(event, checked) => setDisplayOptions(checked)}
                                                />
                                            }
                                            label={<Trans i18nKey="form.photosImageOptions" />}
                                        />
                                    )}
                                </Hidden>
                            </Typography>
                            <FormControl margin="normal" error={hasError}>
                                <Grid container spacing={2}>
                                    {_.sortBy(images, ['sortOrder']).map((image, index) => (
                                        <DraggableImage
                                            error={hasError}
                                            key={index}
                                            index={index}
                                            url={image.url}
                                            moveEntry={mutators.moveEntry}
                                            deleteEntry={() => mutators.deleteImage(image)}
                                            title=""
                                            {...gridSize}
                                            ImageContainer={({ className, children }) => (
                                                <div className={className}>
                                                    {shouldDisplayImagesOptions(image.id) ||
                                                    images.length === 1 ? (
                                                        <div
                                                            className={formsClasses.imageContainer}
                                                            children={children}
                                                        />
                                                    ) : (
                                                        <ButtonBase
                                                            className={formsClasses.imageContainer}
                                                            onClick={() =>
                                                                onDisplayImagesOptions(
                                                                    image.id,
                                                                    !shouldDisplayImagesOptions(image.id),
                                                                )
                                                            }
                                                            children={children}
                                                        />
                                                    )}
                                                </div>
                                            )}
                                            containerClassName={clsx({
                                                [formsClasses.flippableContainer]: !isMobileScreen,
                                                [formsClasses.flip]: shouldDisplayImagesOptions(image.id),
                                            })}
                                            imageContainerClassName={clsx(
                                                formsClasses.flippable,
                                                formsClasses.flipFront,
                                            )}
                                            beforeImage={
                                                <Hidden xsDown>
                                                    {images.length > 1 && (
                                                        <FormControlLabel
                                                            className={clsx(
                                                                formsClasses.options,
                                                                formsClasses.imageOptions,
                                                            )}
                                                            control={
                                                                <Switch
                                                                    color="primary"
                                                                    checked={shouldDisplayImagesOptions(
                                                                        image.id,
                                                                    )}
                                                                    onChange={(event, checked) =>
                                                                        onDisplayImagesOptions(
                                                                            image.id,
                                                                            checked,
                                                                        )
                                                                    }
                                                                />
                                                            }
                                                            label={
                                                                <Trans i18nKey="form.photosImageOptions" />
                                                            }
                                                        />
                                                    )}
                                                </Hidden>
                                            }
                                        >
                                            {images.length > 1 && (
                                                <div
                                                    className={clsx(
                                                        formsClasses.flippable,
                                                        formsClasses.flipBack,
                                                        {
                                                            [formsClasses.show]: displayOptions,
                                                        },
                                                    )}
                                                >
                                                    <Divider />

                                                    {/*  COMMENT  */}
                                                    <FormControl
                                                        margin="normal"
                                                        className={formsClasses.share}
                                                        fullWidth
                                                    >
                                                        <FormLabel>
                                                            <Trans i18nKey="form.imageComment" />
                                                        </FormLabel>
                                                        <TextField
                                                            name={'photos[' + index + '].comment'}
                                                            multiline={true}
                                                            maxRows={9}
                                                            variant="outlined"
                                                        />
                                                    </FormControl>
                                                </div>
                                            )}
                                        </DraggableImage>
                                    ))}
                                    {images.length < 10 && (
                                        <Grid item {...gridSize}>
                                            <Uploader
                                                className={clsx(formsClasses.uploader, {
                                                    [formsClasses.uploaderMargin]: images.length > 1,
                                                })}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                                {hasError && <FormHelperText error={hasError}>{error}</FormHelperText>}
                            </FormControl>
                        </>
                    )}
                </SortedImagesField>
            )}
        </FormSpy>
    )
}

export default PhotoContainer
