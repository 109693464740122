import { RestaurantOwnerModel } from '../../models'

export enum RestaurantOwnersActionTypes {
    ADD_RESTAURANT_OWNER = 'admins/ADD_RESTAURANT_OWNER',
    SET_RESTAURANT_OWNER = 'admins/SET_RESTAURANT_OWNER',
    SET_RESTAURANT_OWNERS = 'admins/SET_RESTAURANT_OWNERS',
    UPDATE_RESTAURANT_OWNER = 'admins/UPDATE_RESTAURANT_OWNER',
    DELETE_RESTAURANT_OWNER = 'admins/DELETE_RESTAURANT_OWNER',
}

export interface RestaurantOwnersState {
    restaurantOwners?: Array<RestaurantOwnerModel>
}
