import Account from 'containers/account/account'
import AdminCreate from 'containers/admins/adminCreate'
import Admins from 'containers/admins/admins'
import AdminUpdate from 'containers/admins/adminUpdate'
import SignIn from 'containers/authentication/signIn'
import EstablishmentCreate from 'containers/establishments/establishmentCreate'
import Establishments from 'containers/establishments/establishments'
import EstablishmentUpdate from 'containers/establishments/establishmentUpdate'
import FacebookProfiles from 'containers/facebook/facebookProfiles'
import FacebookPublish from 'containers/facebook/facebookPublish'
import GroupCreate from 'containers/groups/groupCreate'
import Groups from 'containers/groups/groups'
import GroupUpdate from 'containers/groups/groupUpdate'
import RestaurantOwners from 'containers/restaurantOwners/restaurantOwners'
import RestaurantOwnerUpdate from 'containers/restaurantOwners/restaurantOwnerUpdate'
import React from 'react'

export interface InternalRoute {
    path: string
    component?: React.FC
    children?: Array<InternalRoute>
}

export const routes: Array<InternalRoute> = [
    {
        path: 'sign-in',
        component: SignIn,
    },
    {
        path: 'account',
        component: Account,
    },
    {
        path: 'establishment',
        children: [
            {
                path: 'add',
                component: EstablishmentCreate,
            },
            {
                path: ':id',
                component: EstablishmentUpdate,
            },
        ],
    },
    {
        path: 'establishments',
        component: Establishments,
    },
    {
        path: 'facebook',
        children: [
            {
                path: 'profiles',
                component: FacebookProfiles,
            },
            {
                path: 'page',
                component: FacebookPublish,
            },
        ],
    },
    {
        path: 'admins',
        component: Admins,
    },
    {
        path: 'admin',
        children: [
            {
                path: 'add',
                component: AdminCreate,
            },
            {
                path: ':id',
                component: AdminUpdate,
            },
        ],
    },
    {
        path: 'restaurant-owners',
        component: RestaurantOwners,
    },
    {
        path: 'restaurant-owner',
        children: [
            {
                path: ':id',
                component: RestaurantOwnerUpdate,
            },
        ],
    },
    {
        path: 'groups',
        component: Groups,
    },
    {
        path: 'group',
        children: [
            {
                path: 'add',
                component: GroupCreate,
            },
            {
                path: ':id',
                component: GroupUpdate,
            },
        ],
    },
]
