import { Link, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import React from 'react'

interface PropsSocialText {
    text: string
    hashtagColorVariant?: boolean
}

const SocialText: React.FC<PropsSocialText> = ({ text, hashtagColorVariant }) => {
    const classes = useStyles()

    let arr = text.replaceAll('\n', '\n ').split(' ')
    return (
        <>
            {arr.map((element, i) => {
                if (element.startsWith('#')) {
                    return (
                        <React.Fragment key={i}>
                            {' '}
                            <Typography
                                component="strong"
                                className={clsx(classes.hashtagText, {
                                    [classes.hashtagTextColor]: hashtagColorVariant,
                                })}
                            >
                                {element}
                            </Typography>
                        </React.Fragment>
                    )
                } else if (element.startsWith('http') || element.startsWith('https')) {
                    return (
                        <React.Fragment key={i}>
                            {' '}
                            <Link color="inherit" href={element} target="_blank" rel="noreferrer">
                                {element}
                            </Link>
                        </React.Fragment>
                    )
                } else {
                    return <React.Fragment key={i}>{' ' + element} </React.Fragment>
                }
            })}
        </>
    )
}

export default SocialText

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        hashtagTextColor: {
            color: theme.palette.primary.main,
        },
        hashtagText: {
            fontWeight: theme.fontsWeight.semiBold,
        },
        conversationPlatformIcon: {
            zIndex: 10,
            position: 'absolute',
            bottom: -4,
            left: '56%',
            width: 25,
            height: 25,
            [theme.breakpoints.down('xs')]: {
                width: 25,
                height: 25,
            },
        },
        conversationPostPlatformIcon: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        mediaContentRemove: {
            fontSize: '15px',
            textAlign: 'center',
            color: theme.palette.grey['600'],
        },
    }),
)
