import { setFacebookInitialized } from 'core/store/app/actions'
import { ApplicationState } from 'core/store/reducers'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SocialWindow } from 'types/window'

declare let window: SocialWindow

const useFacebookAuth = () => {
    const { REACT_APP_FACEBOOK_APP_ID } = process.env
    if (!REACT_APP_FACEBOOK_APP_ID) {
        throw Error()
    }
    const dispatch = useDispatch()
    const { facebookInitialized } = useSelector(({ app }: ApplicationState) => ({
        facebookInitialized: app.facebookInitialized,
    }))
    const [hasRun, setHasRun] = useState(facebookInitialized)

    useEffect(() => {
        if (!hasRun && !facebookInitialized) {
            setHasRun(true)
            window.fbAsyncInit = () => {
                FB.init({
                    appId: REACT_APP_FACEBOOK_APP_ID,
                    cookie: true,
                    xfbml: false,
                    autoLogAppEvents: true,
                    version: 'v18.0',
                })
                dispatch(setFacebookInitialized(true))
            }
            ;(function (d, s, id) {
                let js,
                    fjs = d.getElementsByTagName(s)[0]
                if (d.getElementById(id)) return
                js = d.createElement(s) as HTMLScriptElement
                js.id = id
                js.src = 'https://connect.facebook.net/en_US/sdk.js'
                js.onerror = () => {
                    dispatch(setFacebookInitialized(true))
                }
                js.addEventListener('load', function () {
                    setTimeout(() => {
                        if (!facebookInitialized) {
                            window.fbAsyncInit()
                        }
                    }, 400)
                })
                const a = fjs.parentNode
                if (a) {
                    a.insertBefore(js, fjs)
                }
            })(document, 'script', 'facebook-jssdk')
        }
    }, [facebookInitialized, REACT_APP_FACEBOOK_APP_ID, dispatch, hasRun])

    return facebookInitialized
}

export default useFacebookAuth
