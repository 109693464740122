import actions from '../../../core/store/actions'
import { useState } from 'react'
import { mutations } from '../../resources'
import { generateError } from '../error'
import { useDispatch } from '../redux'
import { AdminCreateRequest, AdminCreateResponse } from '../../resources/admins/types'

export const useAdminCreate = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)

    const adminCreate = (token: string, payload: AdminCreateRequest): Promise<AdminCreateResponse> => {
        setInProgress(true)
        return mutations
            .adminCreate(token, payload)
            .then(({ data }) => {
                setInProgress(false)
                if (data) {
                    dispatch(actions.addAdmin(data))
                    return data
                }
                throw generateError()
            })
            .catch((error) => {
                setInProgress(false)
                throw generateError(error)
            })
    }

    return {
        adminCreate,
        inProgress,
    }
}
