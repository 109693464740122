import React from 'react'
import { FormControl, InputLabel, MenuItem, PropTypes, Select } from '@material-ui/core'
import { Trans } from 'react-i18next'
import TextField from './textField'
import { Field } from 'react-final-form'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        hide: {
            'height': 0,
            'width': 0,
            '& input': {
                padding: 0,
            },
        },
    }),
)

interface Props {
    name: string
    margin?: PropTypes.Margin
    label?: React.ReactNode | string
    required?: boolean
}

const CountryField: React.FC<Props> = ({ name, label, required, margin = 'normal' }) => {
    const classes = useStyles()

    return (
        <Field name={name}>
            {({ input: { name, onChange, value, ...restInput }, meta }) => {
                const showError =
                    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched
                return (
                    <FormControl fullWidth margin={margin} required={required}>
                        <InputLabel id="company-address-country">{label}</InputLabel>
                        <Select
                            labelId="company-address-country"
                            value={value}
                            onChange={onChange}
                            inputProps={{
                                ...restInput,
                            }}
                            error={showError}
                        >
                            {[
                                'AT',
                                'BE',
                                'BG',
                                'CY',
                                'CZ',
                                'DE',
                                'DK',
                                'EE',
                                'GR',
                                'ES',
                                'FI',
                                'FR',
                                'HR',
                                'HU',
                                'IE',
                                'IT',
                                'LT',
                                'LU',
                                'LV',
                                'MT',
                                'NL',
                                'PL',
                                'PT',
                                'RO',
                                'SE',
                                'SI',
                                'SK',
                            ].map((countryCode) => (
                                <MenuItem key={countryCode} value={countryCode}>
                                    <Trans i18nKey={'countries.' + countryCode} />
                                </MenuItem>
                            ))}
                        </Select>
                        <TextField
                            margin="none"
                            name="address_country_autocomplete"
                            autoComplete="billing country"
                            className={classes.hide}
                            inputProps={{
                                tabIndex: -1,
                            }}
                            onChange={(e) => onChange(e.currentTarget.value)}
                        />
                    </FormControl>
                )
            }}
        </Field>
    )
}

export default CountryField
