import { Language, MessageByLanguage } from 'core/model/notification.model'
import { ApplicationState } from 'core/store/reducers'
import React from 'react'
import { useSelector } from 'react-redux'

interface Props {
    message?: MessageByLanguage[]
}

const Message: React.FC<Props> = ({ message: messagesByLanguage }) => {
    const { locale } = useSelector(({ app }: ApplicationState) => ({
        locale: app.locale,
    }))
    const message = messagesByLanguage?.find((messageByLanguage) => {
        switch (locale) {
            case 'fr-FR':
                return messageByLanguage.language === Language.Fr
            default:
                return messageByLanguage.language === Language.En
        }
    })

    return message ? <>{message.text}</> : null
}

export default Message
