import { useTranslation } from 'react-i18next'
import { GridLocaleText } from '@material-ui/data-grid'

export const useGridTranslation = () => {
    const { t } = useTranslation()

    const gridTranslation: Partial<GridLocaleText> = {
        // Root
        noRowsLabel: t('grid.noRowsLabel'),
        errorOverlayDefaultLabel: t('grid.errorOverlayDefaultLabel'),

        // Density selector toolbar button text
        toolbarDensity: t('grid.toolbarDensity'),
        toolbarDensityLabel: t('grid.toolbarDensityLabel'),
        toolbarDensityCompact: t('grid.toolbarDensityCompact'),
        toolbarDensityStandard: t('grid.toolbarDensityStandard'),
        toolbarDensityComfortable: t('grid.toolbarDensityComfortable'),

        // Columns selector toolbar button text
        toolbarColumns: t('grid.toolbarColumns'),
        toolbarColumnsLabel: t('grid.toolbarColumnsLabel'),

        // Filters toolbar button text
        toolbarFilters: t('grid.toolbarFilters'),
        toolbarFiltersLabel: t('grid.toolbarFiltersLabel'),
        toolbarFiltersTooltipHide: t('grid.toolbarFiltersTooltipHide'),
        toolbarFiltersTooltipShow: t('grid.toolbarFiltersTooltipShow'),
        toolbarFiltersTooltipActive: (count) => t('grid.toolbarFiltersTooltipActive', { count }),

        // Columns panel text
        columnsPanelTextFieldLabel: t('grid.columnsPanelTextFieldLabel'),
        columnsPanelTextFieldPlaceholder: t('grid.columnsPanelTextFieldPlaceholder'),
        columnsPanelDragIconLabel: t('grid.columnsPanelDragIconLabel'),
        columnsPanelShowAllButton: t('grid.columnsPanelShowAllButton'),
        columnsPanelHideAllButton: t('grid.columnsPanelHideAllButton'),

        // Filter panel text
        filterPanelAddFilter: t('grid.filterPanelAddFilter'),
        filterPanelDeleteIconLabel: t('grid.filterPanelDeleteIconLabel'),
        filterPanelOperators: t('grid.filterPanelOperators'),
        filterPanelOperatorAnd: t('grid.filterPanelOperatorAnd'),
        filterPanelOperatorOr: t('grid.filterPanelOperatorOr'),
        filterPanelColumns: t('grid.filterPanelColumns'),

        // Column menu text
        columnMenuLabel: t('grid.columnMenuLabel'),
        columnMenuShowColumns: t('grid.columnMenuShowColumns'),
        columnMenuFilter: t('grid.columnMenuFilter'),
        columnMenuHideColumn: t('grid.columnMenuHideColumn'),
        columnMenuUnsort: t('grid.columnMenuUnsort'),
        columnMenuSortAsc: t('grid.columnMenuSortAsc'),
        columnMenuSortDesc: t('grid.columnMenuSortDesc'),

        // Column header text
        columnHeaderFiltersTooltipActive: (count) => t('grid.columnHeaderFiltersTooltipActive', { count }),
        columnHeaderFiltersLabel: t('grid.columnHeaderFiltersLabel'),
        columnHeaderSortIconLabel: t('grid.columnHeaderSortIconLabel'),

        // Rows selected footer text
        footerRowSelected: (count) => t('grid.footerRowSelected', { count }),

        // Total rows footer text
        footerTotalRows: t('grid.footerTotalRows'),
    }

    return gridTranslation
}
