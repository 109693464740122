import { useGroupUpdate } from '@sugg-gestion/onvaauresto-graph-api-module'
import { useSharedFacebookGroups, useSharedFacebookPages } from '@sugg-gestion/social-graph-api-module'
import GroupForm, { GroupFormState } from 'components/forms/groupForm'
import withAuthenticateLayout from 'containers/hoc/withAuthenticateLayout'
import withGroup from 'containers/hoc/withGroup'
import withOnVaAuRestoGraphApi from 'containers/hoc/withOnVaAuRestoGraphApi'
import withSocialGraphApi from 'containers/hoc/withSocialGraphApi'
import { GroupModel } from 'core/model/group.model'
import { useApiErrors } from 'core/services/onvaauresto/useApiErrors'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

interface Props {
    group: GroupModel
}

const GroupUpdate: React.FC<Props> = ({ group }) => {
    const history = useHistory()

    const { enqueueSnackbar } = useSnackbar()
    const { displayError } = useApiErrors()
    const { t } = useTranslation()

    const { data: sharedFacebookPages } = useSharedFacebookPages()
    const { data: sharedFacebookGroups } = useSharedFacebookGroups()

    const [groupUpdate, { loading }] = useGroupUpdate()

    const handleUpdate = (values: GroupFormState) => {
        groupUpdate({
            variables: {
                id: group.id,
                group: {
                    latitude: values.latitude,
                    longitude: values.longitude,
                    radius: values.radius,
                    public: values.public,
                    sharedFacebookGroupId: values.sharedFacebookGroupId,
                    sharedFacebookPageId: values.sharedFacebookPageId,
                },
                updateFields: [
                    'latitude',
                    'longitude',
                    'radius',
                    'public',
                    'sharedFacebookGroupId',
                    'sharedFacebookPageId',
                ],
            },
        })
            .then(() => {
                enqueueSnackbar(t('groups.update.success'), {
                    variant: 'success',
                })
                history.push('/groups')
            })
            .catch((error) => {
                displayError(error)
            })
    }

    return (
        <GroupForm
            sharedFacebookGroups={sharedFacebookGroups?.sharedFacebookGroups ?? []}
            sharedFacebookPages={sharedFacebookPages?.sharedFacebookPages ?? []}
            initialValues={{
                name: group.name,
                latitude: group.latitude,
                longitude: group.longitude,
                radius: group.radius,
                public: group.public,
                sharedFacebookGroupId: group.sharedFacebookGroupId ?? undefined,
                sharedFacebookPageId: group.sharedFacebookPageId ?? undefined,
            }}
            inProgress={loading}
            onSubmit={handleUpdate}
        />
    )
}

export default withAuthenticateLayout(withOnVaAuRestoGraphApi(withSocialGraphApi(withGroup(GroupUpdate))))
