import { createAction } from 'typesafe-actions'
import { RestaurantOwnersActionTypes } from './types'
import { prefix } from '../../config'
import { RestaurantOwnerModel } from '../../models'

const actionPrefix = '@' + prefix + '/'

export const setRestaurantOwner = createAction(
    actionPrefix + RestaurantOwnersActionTypes.SET_RESTAURANT_OWNER,
)<RestaurantOwnerModel>()
export const setRestaurantOwners = createAction(
    actionPrefix + RestaurantOwnersActionTypes.SET_RESTAURANT_OWNERS,
)<Array<RestaurantOwnerModel>>()
export const updateRestaurantOwner = createAction(
    actionPrefix + RestaurantOwnersActionTypes.UPDATE_RESTAURANT_OWNER,
)<RestaurantOwnerModel>()
export const deleteRestaurantOwner = createAction(
    actionPrefix + RestaurantOwnersActionTypes.DELETE_RESTAURANT_OWNER,
)<string>()
