import actions from '../../../core/store/actions'
import { useState } from 'react'
import { queries } from '../../resources'
import { generateError } from '../error'
import { useDispatch } from '../redux'
import { RestaurantOwnerModel } from '../../models'

export const useRestaurantOwnersList = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)

    const restaurantOwnersList = (token?: string): Promise<Array<RestaurantOwnerModel>> => {
        setInProgress(true)
        return queries
            .restaurantOwnersList(token)
            .then(({ data }) => {
                setInProgress(false)
                if (data) {
                    dispatch(actions.setRestaurantOwners(data))
                    return data
                }
                throw generateError()
            })
            .catch((error) => {
                setInProgress(false)
                throw generateError(error)
            })
    }

    return {
        restaurantOwnersList,
        inProgress,
    }
}
