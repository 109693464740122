import SubmitButton from 'components/common/submitButton'
import React from 'react'
import { Form } from 'react-final-form'
import PasswordField from './components/passwordField'
import { Trans, useTranslation } from 'react-i18next'
import { useFormsStyles } from './forms.css'
import TextField from './components/textField'
import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import _ from 'lodash'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: AccountFormState) => void
    initialValues: AccountFormState
    inProgress?: boolean
}

export interface AccountFormState {
    password?: string
    passwordConfirmation?: string
    givenName?: string
    familyName?: string
    email?: string
}

interface AccountFormErrors {
    password?: string
    passwordConfirmation?: string
}

const AccountForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    initialValues,
    inProgress = false,
}) => {
    const { t } = useTranslation()
    const classes = useFormsStyles()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const submit = (values: AccountFormState) => {
        if (_.isEmpty(values.password)) {
            values.password = undefined
        }
        return onSubmit(values)
    }

    const strongPasswordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\\W)(?=.{8,})')
    const validate = (values: AccountFormState) => {
        const errors: AccountFormErrors = {}

        if (values.password && !strongPasswordRegex.test(values.password)) {
            errors.password = t('form-validation.passwordStrength')
        }

        if (
            (values.passwordConfirmation || values.password) &&
            values.password !== values.passwordConfirmation
        ) {
            errors.passwordConfirmation = t('form-validation.passwordConfirmationNotMatch')
        }

        return errors
    }

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={submit}
            validate={validate}
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div>
                        <Grid container spacing={isMobile ? 0 : 6}>
                            <Grid item sm={6} xs={12}>
                                <TextField name="givenName" label={t('form.givenName')} />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextField name="familyName" label={t('form.familyName')} />
                            </Grid>
                        </Grid>
                        <TextField name="email" label={t('form.email')} />

                        <div className={classes.updatePassword}>
                            <Typography>
                                <Trans i18nKey="form.passwordUpdate" />
                            </Typography>
                            <PasswordField
                                margin="none"
                                name="password"
                                label={t('form.newPassword')}
                                autoComplete="new-password"
                            />
                            <PasswordField
                                name="passwordConfirmation"
                                label={t('form.passwordConfirmation')}
                                autoComplete="new-password"
                            />
                        </div>
                    </div>
                    <div className={classes.actions}>
                        <SubmitButton
                            variant="contained"
                            color="primary"
                            type="submit"
                            loading={submitting || inProgress}
                            className={classes.submit}
                        >
                            <Trans i18nKey="actions.validate" />
                        </SubmitButton>
                    </div>
                </form>
            )}
        </Form>
    )
}
export default AccountForm
