import { createAction } from 'typesafe-actions'
import { AuthActionTypes } from './types'
import { prefix } from '../../config'
import { AdminSignInResponse, RestaurantOwnerSignInResponse } from '../../resources/auth/types'

const actionPrefix = '@' + prefix + '/'

export const adminSignIn = createAction(actionPrefix + AuthActionTypes.ADMIN_SIGN_IN)<AdminSignInResponse>()
export const adminSignOut = createAction(actionPrefix + AuthActionTypes.ADMIN_SIGN_OUT)()

export const restaurantOwnerSignIn = createAction(
    actionPrefix + AuthActionTypes.RESTAURANT_OWNER_SIGN_IN,
)<RestaurantOwnerSignInResponse>()
export const restaurantOwnerSignOut = createAction(actionPrefix + AuthActionTypes.RESTAURANT_OWNER_SIGN_OUT)()
