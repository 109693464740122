import { Avatar, ButtonBase, Card, CardContent, Grid, IconButton, Link, Typography } from '@material-ui/core'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import clsx from 'clsx'
import { PostModel, PostPhotoModel } from 'core/model/notification.model'
import moment from 'moment'
import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import SocialText from '../common/socialText'
import ImageDialog from '../dialogs/imageDialog'
import { useStyles } from './notification.css'

interface Props {
    post: PostModel
}

const NotificationPost: React.FC<Props> = ({ post }) => {
    const classes = useStyles()
    const [zoom, setZoom] = useState<PostPhotoModel | undefined>(undefined)

    const handleZoom = (image: PostPhotoModel) => {
        setZoom(image)
    }
    const handleClose = () => {
        setZoom(undefined)
    }
    return (
        <Card variant="outlined">
            <CardContent
                classes={{
                    root: classes.postContainer,
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Avatar src={post.fromPicture ?? undefined} className={classes.fromPicture} />
                            </Grid>
                            <Grid item>
                                <Grid container>
                                    <Grid
                                        item
                                        classes={{
                                            root: classes.titleLine,
                                        }}
                                        xs={12}
                                    >
                                        {post.from && (
                                            <>
                                                <Typography className={classes.title}>
                                                    {post.fromUrl ? (
                                                        <Link
                                                            color="inherit"
                                                            href={post.fromUrl}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {post.from}
                                                        </Link>
                                                    ) : (
                                                        post.from
                                                    )}
                                                </Typography>
                                                <ArrowRightIcon />
                                            </>
                                        )}
                                        <Typography className={classes.title}>
                                            {post.toUrl ? (
                                                <Link
                                                    color="inherit"
                                                    href={post.toUrl}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {post.to}
                                                </Link>
                                            ) : (
                                                post.to
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {post.publishedAt && (
                                            <Typography className={classes.titleDate}>
                                                <Trans
                                                    i18nKey="common.dateTime"
                                                    values={{ date: moment(post.publishedAt).toDate() }}
                                                />
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {post.message && (
                        <Grid item xs={12}>
                            <Typography>
                                <SocialText text={post.message} />
                            </Typography>
                        </Grid>
                    )}
                    {post.photos && post.photos.length > 0 && (
                        <Grid item xs={12}>
                            <Grid container spacing={1} className={classes.photos}>
                                {/* Browse media */}
                                {post.photos.map((photo, mediaIndex) => {
                                    const photos = post.photos ?? []
                                    if (!photo) {
                                        return null
                                    }
                                    return (
                                        <Grid
                                            item
                                            sm={photos.length > 1 ? 6 : 12}
                                            xs={photos.length > 1 ? 6 : 12}
                                            key={mediaIndex}
                                        >
                                            <ButtonBase
                                                onClick={() => handleZoom(photo)}
                                                itemProp="photo"
                                                className={clsx(classes.photoButton)}
                                            >
                                                <img
                                                    src={photo.url}
                                                    className={classes.photo}
                                                    alt={'post media ' + mediaIndex}
                                                />
                                            </ButtonBase>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>
                    )}
                    {post.shared && (
                        <Grid item xs={12}>
                            <Card elevation={0} variant="outlined">
                                <Grid container>
                                    <Grid item className={classes.sharedContainer}>
                                        <Link
                                            className={classes.shared}
                                            href={post.shared}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {post.shared}
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <IconButton href={post.shared} target="_blank" rel="noreferrer">
                                            <OpenInNewIcon fontSize="small" />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    )}
                </Grid>
                {zoom && <ImageDialog open={true} imageUrl={zoom.url} onClose={handleClose} />}
            </CardContent>
        </Card>
    )
}

export default NotificationPost
