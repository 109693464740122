import { AddressModel } from '@sugg-gestion/react-onvaauresto'

export const useAddress = () => {
    const displayAddress = (address: AddressModel) => {
        let addressLine = address.line1
        if (address.line2) {
            addressLine += '\n' + address.line2
        }
        addressLine += '\n' + address.zipCode + ' ' + address.city
        return addressLine
    }

    const addressValue = (address: AddressModel) => {
        let addressLine = address.country + ' ' + address.zipCode + ' ' + address.city
        if (address.line2) {
            addressLine += ' ' + address.line2
        }
        addressLine += ' ' + address.line1
        return addressLine
    }

    return {
        displayAddress,
        addressValue,
    }
}
