import actions from '../../../core/store/actions'
import { useState } from 'react'
import { queries } from '../../resources'
import { generateError } from '../error'
import { useDispatch } from '../redux'
import { EstablishmentModel } from '../../models'

export const useEstablishmentGet = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)

    const establishmentGet = (token: string, establishmentId: string): Promise<EstablishmentModel> => {
        setInProgress(true)
        return queries
            .establishmentGet(token, establishmentId)
            .then(({ data }) => {
                setInProgress(false)
                if (data) {
                    dispatch(actions.setEstablishment(data))
                    return data
                }
                throw generateError()
            })
            .catch((error) => {
                setInProgress(false)
                throw generateError(error)
            })
    }

    return {
        establishmentGet,
        inProgress,
    }
}
