import { put, takeLatest } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'
import actions from '../actions'
import { actions as OnVaAuRestoActions } from '@sugg-gestion/react-onvaauresto'

// const delay = (ms: number) =>
//     new Promise((res) => {
//         setTimeout(res, ms)
//     })

function* appSagas() {
    yield takeLatest(getType(OnVaAuRestoActions.start), init)
}

function* init(action: ActionType<typeof OnVaAuRestoActions.start>) {
    if (action.payload) {
        yield put(actions.init(true))
    }
}

export default appSagas
