import React from 'react'
import { SvgIcon } from '@material-ui/core'

const ChiefIcon: React.FC = (props) => {
    return (
        <SvgIcon viewBox="0 0 64 64" {...props}>
            <path d="m51.991 8h-8.01c-2.828-3.757-7.253-6-11.972-6-4.726 0-9.147 2.244-11.963 6h-8.038c-6.616.001-11.998 5.383-11.998 12 0 6.279 4.85 11.438 11 11.949v25.051c0 2.757 2.243 5 5 5h31.98c.004 0 .006-.002.01-.002s.006.002.01.002c.002 0 .003-.001.005-.001v.001c2.743-.012 4.976-2.26 4.976-5.01v-25.041c6.15-.511 11-5.67 11-11.949-.001-6.617-5.383-11.999-12-12zm-3.018 51.818c-.088-.463-.475-.818-.963-.818-.553 0-1 .447-1 1h-2c0-.553-.447-1-1-1s-1 .447-1 1h-2c0-.553-.447-1-1-1s-1 .447-1 1h-2c0-.553-.447-1-1-1s-1 .447-1 1h-2c0-.553-.447-1-1-1s-1 .447-1 1h-2c0-.553-.447-1-1-1s-1 .447-1 1h-2c0-.553-.447-1-1-1s-1 .447-1 1h-2c0-.553-.447-1-1-1s-1 .447-1 1h-2c0-.553-.447-1-1-1-.491 0-.881.36-.965.826-1.18-.404-2.035-1.512-2.035-2.826h37.979c-.004 1.304-.845 2.406-2.016 2.818zm3.017-29.818c-.553 0-1 .447-1 1v24h-37.98v-24c0-.553-.447-1-1-1-5.514 0-10-4.486-10-10s4.486-10 10-10h6.748c-1.142 2.148-1.748 4.533-1.748 7 0 .553.447 1 1 1s1-.447 1-1c0-2.674.809-5.241 2.342-7.43 2.42-3.488 6.405-5.57 10.658-5.57 4.249 0 8.238 2.083 10.67 5.571 1.524 2.189 2.33 4.759 2.33 7.429 0 .553.447 1 1 1s1-.447 1-1c0-2.464-.604-4.85-1.742-7h6.723c5.514 0 10 4.486 10 10s-4.487 10-10.001 10z" />
            <path d="m32 26.997c-.553 0-1 .447-1 1v18.993c0 .553.447 1 1 1s1-.447 1-1v-18.993c0-.553-.447-1-1-1z" />
            <path d="m23 26.997c-.553 0-1 .447-1 1v18.993c0 .553.447 1 1 1s1-.447 1-1v-18.993c0-.553-.447-1-1-1z" />
            <path d="m41 26.997c-.553 0-1 .447-1 1v18.993c0 .553.447 1 1 1s1-.447 1-1v-18.993c0-.553-.447-1-1-1z" />
        </SvgIcon>
    )
}

export default ChiefIcon
