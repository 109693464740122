import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100%',
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
        cardInscription: {
            marginBottom: '1%',
            minWidth: '20%',
            padding: '1%',
        },
        btnSubmit: {
            fontSize: 20,
        },
        labelEmail: {
            marginTop: 5,
            fontSize: 25,
        },
        labelInfo: {
            marginLeft: 10,
            fontSize: '18px',
        },
        formContent: {
            marginBottom: 50,
        },
    }),
)
