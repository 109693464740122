import React from 'react'
import withAuthenticateLayout from 'containers/hoc/withAuthenticateLayout'
import AccountForm, { AccountFormState } from 'components/forms/accountForm'
import { useAdminUpdateMe } from '@sugg-gestion/react-onvaauresto'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'core/store/reducers'
import ContainerLeft from 'components/common/containerLeft'
import { Typography } from '@material-ui/core'
import { Trans, useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { useApiErrors } from 'core/services/onvaauresto/useApiErrors'

const Account: React.FC = () => {
    const { admin, token } = useSelector(({ onvaauresto: { auth } }: ApplicationState) => ({
        admin: auth.admin,
        token: auth.token,
    }))
    const { adminUpdateMe, inProgress } = useAdminUpdateMe()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const { displayError } = useApiErrors()

    const onSubmit = (values: AccountFormState) => {
        if (token && admin) {
            const updateFields = ['givenName', 'familyName', 'email']
            if (values.password) {
                updateFields.push('password')
            }
            adminUpdateMe(token, admin.id, {
                ...values,
                updateFields,
            })
                .then(() => {
                    enqueueSnackbar(t('account.success'), {
                        variant: 'success',
                    })
                })
                .catch((error) => {
                    displayError(error)
                })
        }
    }
    return (
        <ContainerLeft maxWidth="sm">
            <Typography variant="h1" color="textSecondary">
                <Trans i18nKey="account.title" />
            </Typography>
            <AccountForm
                initialValues={{
                    ...admin,
                }}
                onSubmit={onSubmit}
                inProgress={inProgress}
            />
        </ContainerLeft>
    )
}

export default withAuthenticateLayout(Account)
