import { AnyAbility } from '@casl/ability'
import { Can } from '@casl/react'
import { Typography } from '@material-ui/core'
import { DataGrid, GridColDef, GridValueFormatterParams } from '@material-ui/data-grid'
import AddIcon from '@material-ui/icons/Add'
import RefreshIcon from '@material-ui/icons/Refresh'
import clsx from 'clsx'
import SubmitButton from 'components/common/submitButton'
import AlertDialog from 'components/dialogs/alertDialog'
import { FacebookProfileModel } from 'core/model/facebookProfile.model'
import { useGridTranslation } from 'core/services/grid/useGidTranslation'
import moment from 'moment/moment'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useStyles } from './facebookProfilesDataTable.css'

interface Props {
    facebookProfiles: FacebookProfileModel[]
    onRegisterFacebookProfile: () => void
    onRefreshFacebookProfiles: () => void
    onUnlinkFacebookProfile: (id: string) => void
    ability: AnyAbility
    loading: boolean
    loadingRegisterFacebookProfile: boolean
    loadingUnlinkFacebookProfile: boolean
    loadingRefreshFacebookProfiles: boolean
}

const FacebookProfilesDataTable: React.FC<Props> = ({
    facebookProfiles,
    onRegisterFacebookProfile,
    onRefreshFacebookProfiles,
    onUnlinkFacebookProfile,
    ability,
    loading,
    loadingRegisterFacebookProfile,
    loadingUnlinkFacebookProfile,
    loadingRefreshFacebookProfiles,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const gridTranslation = useGridTranslation()
    const [unlinkDialog, setUnlinkDialog] = useState<string>()
    const [pageSize, setPageSize] = useState(50)

    const unlinkFacebookProfile = (facebookProfile: FacebookProfileModel) => {
        setUnlinkDialog(facebookProfile.id)
    }

    const columns: GridColDef[] = [
        {
            field: 'facebookId',
            headerName: t('common.id'),
            width: 140,
            sortable: false,
        },
        { field: 'name', headerName: t('common.name'), width: 240 },
        { field: 'email', headerName: t('common.email'), width: 300 },
        {
            field: 'expiresAt',
            headerName: t('common.expiresAt'),
            width: 210,
            type: 'dateTime',
            renderCell: ({ value }) => {
                const date = moment(value as string)
                return <Trans i18nKey="common.dateYear" values={{ date: date.toDate() }} />
            },
        },
        {
            field: 'actions',
            filterable: false,
            sortable: false,
            headerName: t('common.actions'),
            width: 340,
            renderCell: (params: GridValueFormatterParams) => {
                const facebookProfile = params.row as FacebookProfileModel
                return (
                    <div className={classes.actions}>
                        <Can ability={ability} I="link" a="facebook">
                            <SubmitButton
                                disabled={loadingRefreshFacebookProfiles}
                                loading={loadingRegisterFacebookProfile}
                                onClick={() => onRegisterFacebookProfile()}
                                variant="outlined"
                                size="small"
                            >
                                <Trans i18nKey="actions.linkAgain" />
                            </SubmitButton>
                        </Can>

                        <Can ability={ability} I="unlink" a="facebook">
                            <SubmitButton
                                loading={loadingUnlinkFacebookProfile}
                                onClick={() => unlinkFacebookProfile(facebookProfile)}
                                variant="outlined"
                                color="inherit"
                                size="small"
                                className={classes.delete}
                            >
                                <Trans i18nKey="actions.unlinkFacebookProfile" />
                            </SubmitButton>
                        </Can>
                    </div>
                )
            },
        },
    ]

    return (
        <div className={classes.root}>
            <div className={classes.headerContainer}>
                <Typography variant="h1">
                    <Trans i18nKey="facebookProfiles.profiles" />
                </Typography>
                <Can ability={ability} I="link" a="facebook">
                    <div className={clsx(classes.header, classes.actions)}>
                        <SubmitButton
                            loading={loadingRefreshFacebookProfiles}
                            onClick={() => onRefreshFacebookProfiles()}
                            variant="outlined"
                            startIcon={<RefreshIcon />}
                        >
                            <Trans i18nKey="actions.refreshFacebookProfiles" />
                        </SubmitButton>
                        <SubmitButton
                            disabled={loadingRefreshFacebookProfiles}
                            loading={loadingRegisterFacebookProfile}
                            onClick={onRegisterFacebookProfile}
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                        >
                            <Trans i18nKey="actions.registerFacebookProfile" />
                        </SubmitButton>
                    </div>
                </Can>
            </div>
            <div className={classes.tableContainer}>
                <DataGrid
                    rows={facebookProfiles}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(pageSize) => setPageSize(pageSize)}
                    rowsPerPageOptions={[10, 50, 100]}
                    loading={loading}
                    localeText={gridTranslation}
                    disableSelectionOnClick
                />
            </div>
            <AlertDialog
                open={unlinkDialog !== undefined}
                title={t('facebookProfiles.unlink.question')}
                buttons={{ yes: t('common.yes'), no: t('common.no') }}
                onClose={() => setUnlinkDialog(undefined)}
                callback={() => {
                    if (unlinkDialog) {
                        onUnlinkFacebookProfile(unlinkDialog)
                    }
                }}
            />
        </div>
    )
}

export default FacebookProfilesDataTable
