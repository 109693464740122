import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev'
import { useGroupRegister } from '@sugg-gestion/onvaauresto-graph-api-module'
import { useSharedFacebookGroups, useSharedFacebookPages } from '@sugg-gestion/social-graph-api-module'
import GroupForm, { GroupFormState } from 'components/forms/groupForm'
import withAuthenticateLayout from 'containers/hoc/withAuthenticateLayout'
import withOnVaAuRestoGraphApi from 'containers/hoc/withOnVaAuRestoGraphApi'
import withSocialGraphApi from 'containers/hoc/withSocialGraphApi'
import { useApiErrors } from 'core/services/onvaauresto/useApiErrors'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

loadDevMessages()
loadErrorMessages()

const GroupCreate: React.FC = () => {
    const history = useHistory()

    const { enqueueSnackbar } = useSnackbar()
    const { displayError } = useApiErrors()
    const { t } = useTranslation()

    const { data: sharedFacebookPages } = useSharedFacebookPages()
    const { data: sharedFacebookGroups } = useSharedFacebookGroups()

    const [groupRegister, { loading }] = useGroupRegister()

    const handleCreate = (values: GroupFormState) => {
        groupRegister({
            variables: {
                group: {
                    ...values,
                },
            },
        })
            .then(() => {
                enqueueSnackbar(t('groups.create.success'), {
                    variant: 'success',
                })
                history.push('/groups')
            })
            .catch((error) => {
                displayError(error)
            })
    }

    return (
        <GroupForm
            sharedFacebookGroups={sharedFacebookGroups?.sharedFacebookGroups ?? []}
            sharedFacebookPages={sharedFacebookPages?.sharedFacebookPages ?? []}
            initialValues={{
                name: '',
                latitude: 0,
                longitude: 0,
                radius: 20,
                public: true,
            }}
            inProgress={loading}
            onSubmit={handleCreate}
        />
    )
}

export default withAuthenticateLayout(withOnVaAuRestoGraphApi(withSocialGraphApi(GroupCreate)))
