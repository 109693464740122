import React, { ComponentType } from 'react'
import { OnVaAuRestoProvider, StorageInterface } from '@sugg-gestion/react-onvaauresto'
import { useStore } from 'react-redux'

export class WebStorage implements StorageInterface {
    storage: Storage

    constructor(storage: Storage) {
        this.storage = storage
    }

    getItem(key: string): Promise<string | null> {
        return new Promise<string | null>((resolve) => {
            resolve(this.storage.getItem(key))
        })
    }

    setItem(key: string, value: string): Promise<void> {
        return new Promise<void>((resolve) => {
            resolve(this.storage.setItem(key, value))
        })
    }

    removeItem(key: string): Promise<void> {
        return new Promise<void>((resolve) => {
            resolve(this.storage.removeItem(key))
        })
    }
}

const withOnVaAuResto = (ComposedComponent: ComponentType) => {
    const WithOnVaAuResto: React.FC = ({ ...props }) => {
        const store = useStore()
        const { REACT_APP_ONVAAURESTO_ENDPOINT } = process.env
        if (REACT_APP_ONVAAURESTO_ENDPOINT === undefined) {
            throw new Error()
        }
        return (
            <OnVaAuRestoProvider
                store={store}
                options={{
                    apiEndpoint: REACT_APP_ONVAAURESTO_ENDPOINT,
                    persistentStorage: new WebStorage(localStorage),
                    sessionStorage: new WebStorage(localStorage),
                }}
            >
                <ComposedComponent {...props} />
            </OnVaAuRestoProvider>
        )
    }
    return WithOnVaAuResto
}

export default withOnVaAuResto
