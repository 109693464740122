import React from 'react'
import { compose } from 'redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import withMuiTheme from 'containers/hoc/withMuiTheme'
import withHistory from 'containers/hoc/withHistory'
import withNotistack from 'containers/hoc/withNotistack'
import withStoreProvider from 'containers/hoc/withStoreProvider'
import withConnectedRouter from 'containers/hoc/withConnectedRouter'
import withInit from 'containers/hoc/withInit'
import { InternalRoute, routes } from 'core/config/routes'
import StandardLayout from 'components/layouts/standardLayout'
import withTranslation from 'containers/hoc/withTranslation'
import withAlert from 'containers/hoc/withAlert'
import NotFound from 'components/views/main/notFound'
import withOnVaAuResto from 'containers/hoc/withOnVaAuResto'
import withSaga from '../hoc/withSaga'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../core/store/reducers'

const App: React.FC = () => {
    const { admin } = useSelector(({ onvaauresto: { auth } }: ApplicationState) => ({
        admin: auth.admin,
    }))
    const path = (routes: Array<InternalRoute>) => {
        let path = '/'
        for (let i = 0; i < routes.length; i++) {
            const route = routes[i]
            path = path + route.path + '/'
        }
        path = path.substring(0, path.length - 1)
        return path
    }

    const displayRoutes = (route: InternalRoute, parents: Array<InternalRoute>): React.ReactNode => {
        const routes = []
        if (route.component !== undefined) {
            const Page = route.component
            routes.push(
                <Route path={path([...parents, route])} key={path([...parents, route])} exact>
                    <Page />
                </Route>,
            )
        }
        if (route.children !== undefined && route.children.length > 0) {
            for (let i = 0; i < route.children.length; i++) {
                const childRoute = route.children[i]
                routes.push(displayRoutes(childRoute, [...parents, route]))
            }
        }
        return routes
    }

    return (
        <StandardLayout>
            <Switch>
                <Route
                    exact
                    path="/"
                    render={() => {
                        if (admin) {
                            return <Redirect to="/establishments" />
                        }
                        return <Redirect to="/sign-in" />
                    }}
                />
                {routes.map((mainRoute) => {
                    return displayRoutes(mainRoute, [])
                })}
                <Route component={NotFound} />
            </Switch>
        </StandardLayout>
    )
}

export default compose<React.FC>(
    withHistory,
    withTranslation,
    withStoreProvider,
    withOnVaAuResto,
    withSaga,
    withConnectedRouter,
    withMuiTheme,
    withInit,
    withNotistack,
    withAlert,
)(App)
