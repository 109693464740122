import { CircularProgress, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { Pagination } from '@material-ui/lab'
import PublishNotification from 'components/notification/publishNotification'
import RefreshNotification from 'components/notification/refreshNotification'
import {
    NotificationModel,
    NotificationType,
    PublishingNotificationModel,
    RefreshingNotificationModel,
} from 'core/model/notification.model'
import React from 'react'
import { useStyles } from './notificationLayout.css'

interface Props {
    notifications: NotificationModel[]
    loading: boolean
    page: number
    pageCount: number
    setPage: (page: number) => void
}

const NotificationLayout: React.FC<Props> = ({ notifications, loading, page, pageCount, setPage }) => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div>
            <IconButton onClick={handleClick} disabled={loading}>
                {loading ? (
                    <CircularProgress style={{ color: 'rgba(0, 0, 0, 0.26)' }} size={20} />
                ) : (
                    <NotificationsIcon />
                )}
            </IconButton>
            <Menu
                id="notification-center"
                anchorEl={anchorEl}
                disableScrollLock
                disablePortal
                keepMounted
                getContentAnchorEl={null}
                open={open}
                onClose={handleClose}
                // elevation={0}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{
                    className: classes.paper,
                }}
            >
                <MenuItem button={false} divider>
                    <ListItemText primary="Notifications" />
                    <ListItemIcon
                        classes={{
                            root: classes.close,
                        }}
                    >
                        <IconButton onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </ListItemIcon>
                </MenuItem>
                {notifications.map((notification) => {
                    switch (notification.type) {
                        case NotificationType.Refreshing: {
                            return (
                                <RefreshNotification
                                    key={notification.id}
                                    notification={notification as RefreshingNotificationModel}
                                />
                            )
                        }
                        case NotificationType.PublishingPage:
                        case NotificationType.PublishingGroup: {
                            return (
                                <PublishNotification
                                    key={notification.id}
                                    notification={notification as PublishingNotificationModel}
                                />
                            )
                        }
                    }
                    return null
                })}
                <MenuItem button={false}>
                    <Pagination count={pageCount} page={page} onChange={(_, value) => setPage(value)} />
                </MenuItem>
            </Menu>
        </div>
    )
}

export default NotificationLayout
