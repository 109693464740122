import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        '@keyframes spin': {
            from: {
                transform: 'rotate(0deg)',
            },
            to: {
                transform: 'rotate(360deg)',
            },
        },
        'root': {
            'width': '100%',
            '& .MuiCardHeader-root': {
                padding: theme.spacing(1, 0),
            },
            '& > .MuiCardContent-root': {
                padding: theme.spacing(1, 0),
            },
        },
        'loadingView': {
            minHeight: 300,
            position: 'relative',
        },
        'completed': {
            '& .MuiLinearProgress-bar': {
                backgroundColor: theme.palette.success.main,
            },
        },
        'failed': {
            '& .MuiLinearProgress-bar': {
                backgroundColor: theme.palette.error.main,
            },
        },
        'done': {
            color: theme.palette.success.main,
        },
        'error': {
            color: theme.palette.error.main,
        },
        'alert': {
            marginBottom: theme.spacing(1),
        },
        'row': {
            marginBottom: theme.spacing(2),
        },
        'refreshIconRotation': {
            animation: '$spin 1s infinite linear',
        },
        '@keyframes rise': {
            from: {
                transform: 'translateY(24px)',
            },
            to: {
                transform: 'translateY(0)',
            },
        },
        'publishIconRise': {
            animation: '$rise 1.5s infinite ease-out',
        },
        'avatarOverflow': {
            overflow: 'hidden',
        },
        'iconSuccess': {
            color: theme.palette.success.main,
        },
        'iconError': {
            color: theme.palette.error.main,
        },
        'action': {
            textTransform: 'uppercase',
        },
        'actions': {
            justifyContent: 'flex-end',
        },
        'titleLine': {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        'title': {
            fontWeight: theme.fontsWeight.semiBold,
        },
        'titleDate': {
            fontSize: '0.8rem',
        },
        'fromPicture': {
            width: 50,
            height: 50,
            [theme.breakpoints.down('xs')]: {
                width: 35,
                height: 35,
            },
        },
        'photos': {
            'margin': '6px 0',
            '& img': {
                width: '100%',
            },
        },
        'photoButton': {
            width: '100%',
            height: '100%',
        },
        'photo': {
            aspectRatio: '1/1',
            objectFit: 'cover',
        },
        'sharedContainer': {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
        },
        'shared': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
            display: 'inline-flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
        },
        'postContainer': {
            padding: theme.spacing(1, 1),
        },
    }),
)
