import React, { ComponentType } from 'react'
import Loader from '../../components/common/loader'
import withSocialGraphApi, { WithSocialGraphApiProps } from './withSocialGraphApi'

const withRequiredSocialGraphApi = <T extends WithSocialGraphApiProps>(
    ComposedComponent: ComponentType<any>,
) => {
    const WithRequiredSocialGraphApi: React.FC<T> = ({ isSocialGraphApiInitialized, ...props }) => {
        if (!isSocialGraphApiInitialized) {
            return <Loader />
        }

        return <ComposedComponent {...props} />
    }
    return withSocialGraphApi(WithRequiredSocialGraphApi)
}

export default withRequiredSocialGraphApi
