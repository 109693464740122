import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    PropTypes,
    Radio,
    RadioGroup,
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import clsx from 'clsx'
import moment, { Moment } from 'moment'
import React, { useState } from 'react'
import { Field } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        checkboxEnable: {},
        hidden: {
            visibility: 'hidden',
        },
        checkbox: {
            'width': '100%',
            'border': '1px solid #EBE6EF',
            'borderRadius': theme.spacing(0.5),
            'padding': theme.spacing(0.5, 2),
            'margin': 0,
            '& .MuiFormControlLabel-label': {
                padding: theme.spacing(0, 0, 0, 2),
                fontWeight: theme.fontsWeight.semiBold,
                flexGrow: 1,
            },
        },
        checkboxSelected: {
            backgroundColor: '#F0F0F0',
        },
        label: {
            flexGrow: 1,
            fontWeight: theme.fontsWeight.semiBold,
            marginBottom: theme.spacing(2),
        },
        other: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        dateSelector: {
            marginLeft: theme.spacing(2),
        },
    }),
)

interface Props {
    name: string
    label?: string
    margin?: PropTypes.Margin
}

interface DayOptionProps {
    checked: boolean
    onChange: () => void
    label: string
}

const DayOption: React.FC<DayOptionProps> = ({ checked, onChange, label }) => {
    const classes = useStyles()
    return (
        <Grid item lg={6} xs={12}>
            <FormControlLabel
                className={clsx(classes.checkbox, {
                    [classes.checkboxSelected]: checked,
                })}
                control={
                    <Radio
                        edge="start"
                        color="primary"
                        checked={checked}
                        className={!checked ? classes.hidden : undefined}
                        onChange={onChange}
                    />
                }
                label={label}
                labelPlacement={'end'}
            />
        </Grid>
    )
}

const PublicationTimeField: React.FC<Props> = ({ name, label, margin = 'normal' }) => {
    const today = moment().utc()
    const classes = useStyles()
    const { t } = useTranslation()

    const [open, setOpen] = useState<boolean>(false)

    const openDialog = () => {
        setOpen(true)
    }
    const closeDialog = () => {
        setOpen(false)
    }
    return (
        <Field name={name}>
            {({ input: { value, onChange } }) => {
                const isNow = moment(value).isSameOrBefore(today, 'minutes')
                const isOther = !isNow
                return (
                    <FormControl fullWidth margin={margin}>
                        {label && (
                            <FormLabel component="legend" className={classes.label}>
                                {label}
                            </FormLabel>
                        )}
                        <RadioGroup>
                            <Grid container spacing={2}>
                                <DayOption
                                    onChange={() => onChange(today.toDate())}
                                    checked={isNow}
                                    label={t('form.now')}
                                />
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        // onClick={openDialog}
                                        className={clsx(classes.checkbox, {
                                            [classes.checkboxSelected]: isOther,
                                        })}
                                        control={
                                            <Radio
                                                edge="start"
                                                color="primary"
                                                checked={isOther}
                                                className={!isOther ? classes.hidden : undefined}
                                                onClick={openDialog}
                                            />
                                        }
                                        label={
                                            <div className={classes.other}>
                                                <Trans i18nKey="form.at" values={{ date: null }} />
                                                <KeyboardDateTimePicker
                                                    onClick={openDialog}
                                                    value={moment(value)}
                                                    onChange={(value) => {
                                                        const moment = value as Moment
                                                        if (moment !== null && moment.isValid()) {
                                                            onChange(moment.toDate())
                                                        }
                                                    }}
                                                    onClose={closeDialog}
                                                    onAccept={closeDialog}
                                                    open={open}
                                                    disablePast
                                                    format="DD/MM/YYYY à HH:mm"
                                                    fullWidth
                                                    className={classes.dateSelector}
                                                />
                                            </div>
                                        }
                                        labelPlacement={'end'}
                                    />
                                </Grid>
                            </Grid>
                        </RadioGroup>
                    </FormControl>
                )
            }}
        </Field>
    )
}

export default PublicationTimeField
