import { actions, useEstablishmentsList } from '@sugg-gestion/react-onvaauresto'
import { ApplicationState } from 'core/store/reducers'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useApiErrors } from '../onvaauresto/useApiErrors'

export const useEstablishments = () => {
    const { establishments, token } = useSelector(
        ({ onvaauresto: { establishments, auth } }: ApplicationState) => ({
            establishments: establishments.establishments,
            token: auth.token,
        }),
    )
    const dispatch = useDispatch()
    const { displayError } = useApiErrors()
    const [inProgress, setInProgress] = useState(false)

    const { establishmentsList } = useEstablishmentsList()

    const fetchEstablishments = useCallback(() => {
        setInProgress(true)
        establishmentsList(token, true)
            .catch((error) => {
                dispatch(actions.setEstablishments([]))
                displayError(error)
            })
            .finally(() => {
                setInProgress(false)
            })
    }, [dispatch, displayError, establishmentsList, token])

    useEffect(() => {
        if (establishments === undefined && token !== undefined && !inProgress) {
            fetchEstablishments()
        }
    }, [establishments, fetchEstablishments, inProgress, token])

    const refresh = () => {
        fetchEstablishments()
    }

    return { establishments, inProgress, refresh }
}
