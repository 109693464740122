import React from 'react'
import { Provider } from 'react-redux'
import { Store } from 'redux'
import { OnVaAuRestoContext } from '../core/store/context'
import { ClientOptions } from '../core/client/options'
import Client from '../core/client'

interface Props {
    store: Store
    options?: ClientOptions
}

export const OnVaAuRestoProvider: React.FC<Props> = ({ store, options, children }) => {
    if (options) {
        Client.init(options)
    }
    return (
        <Provider store={store} context={OnVaAuRestoContext}>
            {children}
        </Provider>
    )
}
