const hRange = [0, 360]
const sRange = [50, 75]
const lRange = [25, 60]

type HSL = number[]

// https://dev.to/admitkard/auto-generate-avatar-colors-randomly-138j
export const useAvatarColor = () => {
    const getHashOfString = (str: string) => {
        let hash = 0
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash)
        }
        hash = Math.abs(hash)
        return hash
    }

    const normalizeHash = (hash: number, min: number, max: number) => {
        return Math.floor((hash % (max - min)) + min)
    }

    const generateHSL = (name: string): HSL => {
        const hash = getHashOfString(name)
        const h = normalizeHash(hash, hRange[0], hRange[1])
        const s = normalizeHash(hash, sRange[0], sRange[1])
        const l = normalizeHash(hash, lRange[0], lRange[1])
        return [h, s, l]
    }

    const HSLtoString = (hsl: HSL) => {
        return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`
    }

    return {
        generateHSL,
        HSLtoString,
    }
}
