import { AxiosResponse } from 'axios'
import { GroupFacebookModel } from '../../models'
import Client from '../../client'

export const groupsGet = (token: string): Promise<AxiosResponse<Array<GroupFacebookModel>>> => {
    const axiosInstance = Client.axiosInstance

    const url = '/api/groups/'
    return axiosInstance.get(url, {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    })
}
