import actions from '../../../core/store/actions'
import { AdminSignInByUsernameRequest, AdminSignInResponse } from '../../resources/auth/types'
import { useState } from 'react'
import { mutations } from '../../resources'
import { generateError } from '../error'
import { useDispatch } from '../redux'

export const useAdminSignInByUsername = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)

    const signInAdminByEmail = (payload: AdminSignInByUsernameRequest): Promise<AdminSignInResponse> => {
        setInProgress(true)
        return mutations
            .adminSignInByUsername(payload)
            .then(({ data }) => {
                setInProgress(false)
                if (data) {
                    dispatch(actions.adminSignIn(data))
                    return data
                }
                throw generateError()
            })
            .catch((error) => {
                setInProgress(false)
                dispatch(actions.adminSignOut())
                throw generateError(error)
            })
    }

    return {
        signInAdminByEmail,
        inProgress,
    }
}
