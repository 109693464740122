import { AnyMongoAbility } from '@casl/ability'
import { APIError } from '@sugg-gestion/react-onvaauresto'
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'

export enum AppActionTypes {
    INIT = '@@app/INIT',

    SET_LOCALE = '@@app/SET_LOCALE',
    SET_TIMEZONE = '@@app/SET_TIMEZONE',

    ENQUEUE_SNACKBAR = '@@app/ENQUEUE_SNACKBAR',
    ENQUEUE_ERROR = '@@app/ENQUEUE_ERROR',
    CLOSE_SNACKBAR = '@@app/CLOSE_SNACKBAR',
    REMOVE_SNACKBAR = '@@app/REMOVE_SNACKBAR',

    ALERT = '@@app/ALERT',

    SET_FACEBOOK_INITIALIZED = '@@app/SET_FACEBOOK_INITIALIZED',
}

export interface AppState {
    init: boolean

    locale: string
    timezone: string

    notifications: Array<SnackbarNotification>
    alert?: AlertContent

    ability: AnyMongoAbility

    facebookInitialized: boolean
}

export interface SnackbarNotification {
    key: SnackbarKey
    message: SnackbarMessage
    options?: OptionsObject
    dismissed?: boolean
    actionType?: NotificationActionTypes
}

export interface SnackbarNotificationAction {
    message: SnackbarMessage
    options?: OptionsObject
    actionType?: NotificationActionTypes
}

export interface SnackbarErrorNotification {
    error: APIError
    errorType?: NotificationErrorTypes
    actionType?: NotificationActionTypes
}

export enum NotificationErrorTypes {
    SIGN_IN,
    OTHER,
}

export enum NotificationActionTypes {
    FACEBOOK_REAUTH,
    GOOGLE_REAUTH,
}

export interface AlertContent {
    title?: string
    content?: string
    buttons?: {
        yes?: string
        no?: string
    }
    callback: () => void
}
