import { createStyles, FormControl, FormHelperText, FormLabel, PropTypes, Theme } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { DataGrid, DataGridProps } from '@material-ui/data-grid'
import React, { useState } from 'react'
import { Field } from 'react-final-form'

interface Props extends DataGridProps {
    name: string
    margin?: PropTypes.Margin
    required?: boolean
    label?: string | React.ReactNode
}

const DataGridField: React.FC<Props> = ({ name, margin = 'normal', required, label, ...dataGridProps }) => {
    const classes = useStyles()
    const [pageSize, setPageSize] = useState(10)

    return (
        <Field name={name} type="checkbox">
            {({ input: { onChange, value }, meta }) => {
                const hasError =
                    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched
                return (
                    <FormControl
                        component="fieldset"
                        fullWidth
                        margin={margin}
                        required={required}
                        error={hasError}
                    >
                        {label !== undefined && (
                            <FormLabel component="legend" className={classes.label}>
                                {label}
                            </FormLabel>
                        )}
                        <div className={classes.tableContainer}>
                            <DataGrid
                                checkboxSelection
                                selectionModel={value}
                                pageSize={pageSize}
                                onPageSizeChange={(pageSize) => setPageSize(pageSize)}
                                rowsPerPageOptions={[10, 50, 100, 1000]}
                                rowHeight={32}
                                rowCount={dataGridProps.rows.length}
                                onSelectionModelChange={(selectionModel) => {
                                    onChange(selectionModel)
                                }}
                                {...dataGridProps}
                            />
                        </div>
                        {hasError && <FormHelperText error={hasError}>{meta.error}</FormHelperText>}
                    </FormControl>
                )
            }}
        </Field>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableContainer: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            minHeight: 432,
        },
        label: {
            flexGrow: 1,
            fontWeight: theme.fontsWeight.semiBold,
            marginBottom: theme.spacing(2),
        },
    }),
)

export default DataGridField
