import {
    Button,
    Card,
    CardMedia,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    Link as MuiLink,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Switch,
    Typography,
} from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import FacebookIcon from '@material-ui/icons/Facebook'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import Skeleton from '@material-ui/lab/Skeleton'
import {
    AddressModel,
    EstablishmentModel,
    GroupFacebookModel,
    LocationModel,
} from '@sugg-gestion/react-onvaauresto'
import { validateAWSEmail, validateAWSURL } from 'appsync-scalar-utils'
import clsx from 'clsx'
import SubmitButton from 'components/common/submitButton'
import { useNominatim } from 'core/services/address/useNominatim'
import { Image } from 'core/services/images/useImages'
import { usePhoneNumber } from 'core/services/phone/usePhoneNumber'
import { isValidUrl } from 'core/services/url/isValidUrl'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { CountryCode } from 'libphonenumber-js/types'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { Field, Form, FormSpy, FormSpyRenderProps } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import CheckboxField from './components/checkboxField'
import ImageField from './components/imageField'
import LocationField from './components/locationField'
import PhoneField from './components/phoneField'
import SelectField, { SelectValue } from './components/selectField'
import TextField from './components/textField'
import { useStyles } from './establishmentForm.css'
import { useFormsStyles } from './forms.css'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: EstablishmentFormState) => void
    initialValues: EstablishmentFormState
    disableExtraField: boolean
    inProgress: boolean
    isPhotoShared: boolean
    showPhotoShare: boolean
    groupsInProgress: boolean
    getEstablishmentGroups: (establishment: Pick<EstablishmentModel, 'location'>) => Array<GroupFacebookModel>
}

export interface EstablishmentFormState {
    name: string
    address: AddressModel
    location: LocationModel
    facebookPage?: string
    email?: string
    phone?: string
    delivery?: string
    description?: string
    url?: string
    image?: Image
    isFacebookShared: boolean
    remplisVert: boolean
    remplisVertComment?: string
}

export interface EstablishmentCreateFormInternalState {
    name: string
    address_line1: string
    address_line2?: string
    address_zipCode: string
    address_city: string
    address_country: string
    location: LocationModel
    facebookPage?: string
    email?: string
    phone?: string
    delivery?: string
    description?: string
    url?: string
    image?: Image
    isFacebookShared: boolean
    remplisVert: Array<boolean>
    remplisVertComment?: string
}

export interface EstablishmentCreateFormInternalError {
    name?: string
    address_line1?: string
    address_line2?: string
    address_zipCode?: string
    address_city?: string
    address_country?: string
    location?: string
    facebookPage?: string
    email?: string
    phone?: string
    delivery?: string
    description?: string
    url?: string
    image?: string
}

const countries: Array<SelectValue<string>> = [
    {
        label: 'France',
        value: 'FR',
    },
]

const EstablishmentForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
        errors: true,
    },
    onSubmit,
    initialValues,
    disableExtraField,
    inProgress,
    isPhotoShared,
    showPhotoShare,
    groupsInProgress,
    getEstablishmentGroups,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const formsClasses = useFormsStyles()
    const { displayPhone } = usePhoneNumber()
    const { geocode } = useNominatim()
    const { enqueueSnackbar } = useSnackbar()
    const [localizeMyAddressInProgress, setLocalizeMyAddressInProgress] = useState<boolean>(false)

    const initialValuesInternal: EstablishmentCreateFormInternalState = {
        name: initialValues.name,
        address_line1: initialValues.address.line1,
        address_line2: initialValues.address.line2,
        address_zipCode: initialValues.address.zipCode,
        address_city: initialValues.address.city,
        address_country: initialValues.address.country,
        location: initialValues.location,
        facebookPage: initialValues.facebookPage,
        email: initialValues.email,
        phone: displayPhone(initialValues.phone),
        delivery: initialValues.delivery,
        description: initialValues.description,
        url: initialValues.url,
        image: initialValues.image,
        isFacebookShared: initialValues.isFacebookShared,
        remplisVert: initialValues.remplisVert ? [true] : [],
        remplisVertComment: initialValues.remplisVertComment,
    }

    const submit = (values: EstablishmentCreateFormInternalState) => {
        return onSubmit({
            name: values.name,
            address: {
                line1: values.address_line1,
                line2: values.address_line2,
                zipCode: values.address_zipCode,
                city: values.address_city,
                country: values.address_country,
            },
            location: values.location,
            facebookPage: values.facebookPage,
            email: values.email,
            phone: values.phone,
            delivery: values.delivery,
            description: values.description,
            url: values.url,
            image: values.image,
            isFacebookShared: values.isFacebookShared,
            remplisVert: values.remplisVert && values.remplisVert.length > 0,
            remplisVertComment: values.remplisVertComment,
        })
    }

    const validate = (values: EstablishmentCreateFormInternalState) => {
        const errors: EstablishmentCreateFormInternalError = {}
        if (!values.name) {
            errors.name = t('form-validation.establishmentName')
        } else if (values.name.length > 80) {
            errors.name = t('form-validation.establishmentNameTooLong')
        }

        if (!values.email) {
            errors.email = t('form-validation.email')
        } else if (!validateAWSEmail(values.email)) {
            errors.email = t('form-validation.emailValid')
        }

        if (!values.phone) {
            errors.phone = t('form-validation.phoneRequired')
        } else {
            const phone = parsePhoneNumberFromString(
                values.phone,
                (values.address_country ?? 'FR') as CountryCode,
            )
            if (phone === undefined || !phone.isValid()) {
                errors.phone = t('form-validation.phone')
            }
        }

        if (
            values.facebookPage &&
            !(isValidUrl(values.facebookPage) && validateAWSURL(values.facebookPage))
        ) {
            errors.facebookPage = t('form-validation.url')
        }

        if (
            !values.location ||
            !values.location.latitude ||
            !values.location.longitude ||
            (values.location.latitude === 0 && values.location.longitude === 0) ||
            (values.location.latitude === 46.7111 && values.location.longitude === 1.7191)
        ) {
            errors.location = t('form-validation.location')
        }

        if (!values.address_line1 || values.address_line1 === '') {
            errors.address_line1 = t('form-validation.addressStreet1')
        }

        if (!values.address_zipCode || values.address_zipCode === '') {
            errors.address_zipCode = t('form-validation.addressZipCode')
        }

        if (!values.address_city || values.address_city === '') {
            errors.address_city = t('form-validation.addressCity')
        }

        if (!values.address_country || values.address_country === '') {
            errors.address_country = t('form-validation.addressCountry')
        }

        if (values.delivery && !(isValidUrl(values.delivery) && validateAWSURL(values.delivery))) {
            errors.delivery = t('form-validation.url')
        }

        if (!values.url && !values.image?.url) {
            errors.url = t('form-validation.imageOrUrl')
            errors.image = t('form-validation.imageOrUrl')
        }
        if (values.url && !(isValidUrl(values.url) && validateAWSURL(values.url))) {
            errors.url = t('form-validation.url')
        }

        return errors
    }

    return (
        <Form
            initialValues={initialValuesInternal}
            subscription={subscription}
            // debug={console.log}
            onSubmit={submit}
            validate={validate}
            mutators={{
                setPosition: (args, state, tools) => {
                    const position = args[0] as LocationModel
                    tools.changeValue(state, 'location', () => position)
                },
                updateImage: (args, state, tools) => {
                    const shouldShareOnFacebook = args[0] as boolean
                    tools.changeValue(state, 'isFacebookShared', () => shouldShareOnFacebook)
                },
                changeRemplisVertComment: (args, state, tools) => {
                    const newRemplisVertComment = args[0] as string | undefined
                    if (state.lastFormState && newRemplisVertComment) {
                        const { remplisVertComment } = state.lastFormState.values
                        if (
                            remplisVertComment === undefined ||
                            newRemplisVertComment?.length > remplisVertComment?.length
                        ) {
                            tools.changeValue(state, 'remplisVert', () => [true])
                        }
                    }
                },
            }}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting, form: { mutators }, errors }) => {
                const submit = (event: any) => {
                    handleSubmit(event)
                    // location is fetched during submit: so, we don't know if error is real
                    delete errors?.location
                    if (!_.isEmpty(errors)) {
                        enqueueSnackbar(t('error.validation'), {
                            variant: 'error',
                        })
                    }
                }
                const localizeAddress = (props: FormSpyRenderProps) => {
                    setLocalizeMyAddressInProgress(true)
                    let street = undefined
                    let city = undefined
                    let country = undefined
                    if (props.values.address_line1) {
                        street = props.values.address_line1
                    }
                    if (street && props.values.address_line2) {
                        street += props.values.address_line2
                    }
                    if (props.values.address_city) {
                        city = props.values.address_city
                    }
                    if (props.values.address_country) {
                        country = t('countries.' + props.values.address_country)
                    }
                    if (!(street && city)) {
                        setLocalizeMyAddressInProgress(false)
                        enqueueSnackbar(t('form.localizationFailed'), {
                            variant: 'info',
                        })
                        return new Promise<void>((resolve) => resolve())
                    }
                    return geocode(street, city, country)
                        .then((result) => {
                            setLocalizeMyAddressInProgress(false)
                            if (result[0]) {
                                const position: LocationModel = {
                                    longitude: parseFloat(result[0].lon),
                                    latitude: parseFloat(result[0].lat),
                                }
                                mutators.setPosition(position)
                            } else {
                                enqueueSnackbar(t('form.localizationFailed'), {
                                    variant: 'info',
                                })
                            }
                        })
                        .catch((_) => {
                            enqueueSnackbar(t('form.localizationFailed'), {
                                variant: 'info',
                            })
                            setLocalizeMyAddressInProgress(false)
                        })
                }
                return (
                    <form noValidate>
                        <Grid container spacing={8}>
                            <Grid item sm={6} xs={12}>
                                <Typography variant="h1" color="textSecondary">
                                    <Trans i18nKey="form.establishment" />
                                </Typography>
                                <TextField
                                    name="name"
                                    label={t('form.establishmentName')}
                                    autoComplete="organization"
                                    required
                                />
                                <TextField name="email" label={t('form.establishmentEmail')} required />
                                <PhoneField name="phone" label={t('form.establishmentPhone')} required />
                                <TextField
                                    name="description"
                                    label={t('form.establishmentDescription')}
                                    multiline
                                    maxRows={4}
                                />
                                <TextField name="facebookPage" label={t('form.establishmentFacebookPage')} />
                                <TextField
                                    name="delivery"
                                    label={t('form.establishmentDelivery')}
                                    disabled={disableExtraField}
                                />

                                <CheckboxField
                                    name="remplisVert"
                                    value={true}
                                    labelPlacement="end"
                                    label={
                                        <Trans
                                            i18nKey="form.remplisVert"
                                            components={[
                                                <span className={formsClasses.remplisVert} />,
                                                <MuiLink
                                                    className={formsClasses.remplisVertLink}
                                                    href="https://info.onvaauresto.fr/remplisvert/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                />,
                                            ]}
                                        />
                                    }
                                    helper={
                                        <Trans
                                            i18nKey="form.remplisVertHelper"
                                            components={[<span className={formsClasses.remplisVert} />]}
                                        />
                                    }
                                />
                                <FormControl fullWidth margin="none">
                                    <Typography component="label" htmlFor="remplisVertComment">
                                        <Trans
                                            i18nKey="form.remplisVertComment"
                                            components={[
                                                <span className={formsClasses.remplisVertComment} />,
                                            ]}
                                        />
                                    </Typography>
                                    <TextField
                                        margin="dense"
                                        variant="outlined"
                                        name="remplisVertComment"
                                        multiline
                                        onChange={(e) => {
                                            mutators.changeRemplisVertComment(e.currentTarget.value)
                                        }}
                                        minRows={3}
                                        maxRows={6}
                                        inputProps={{
                                            id: 'remplisVertComment',
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Typography variant="h1" color="textSecondary">
                                    <Trans i18nKey="form.photo" />
                                </Typography>
                                <div>
                                    <TextField
                                        name="url"
                                        label={t('form.imageUrl')}
                                        onChange={(event) =>
                                            mutators.updateImage(event.currentTarget.value !== '')
                                        }
                                    />
                                    <div className={formsClasses.or}>
                                        <Divider className={formsClasses.orDivider} variant="middle" />
                                        <Typography color="textPrimary">
                                            <Trans i18nKey="common.or" />
                                        </Typography>
                                        <Divider className={formsClasses.orDivider} variant="middle" />
                                    </div>
                                    <ImageField
                                        name="image"
                                        onValueChange={() => mutators.updateImage(showPhotoShare)}
                                    >
                                        {(Uploader, hasError, error, image) => {
                                            return (
                                                <Uploader
                                                    classes={{
                                                        root: clsx(
                                                            formsClasses.photoField,
                                                            classes.photoField,
                                                        ),
                                                        container: formsClasses.photoFieldContainer,
                                                    }}
                                                >
                                                    <Card
                                                        elevation={0}
                                                        classes={{
                                                            root: image
                                                                ? formsClasses.photoFieldCard
                                                                : formsClasses.photoFieldCardWithoutImage,
                                                        }}
                                                    >
                                                        <CardMedia
                                                            image={image?.url}
                                                            classes={{
                                                                root: formsClasses.photoFieldCardMedia,
                                                            }}
                                                        >
                                                            {!image && (
                                                                <div
                                                                    className={
                                                                        formsClasses.photoFieldAddContainer
                                                                    }
                                                                >
                                                                    <AddCircleIcon
                                                                        className={clsx(
                                                                            formsClasses.photoFieldAdd,
                                                                            classes.photoFieldAdd,
                                                                        )}
                                                                        color="primary"
                                                                    />
                                                                </div>
                                                            )}
                                                        </CardMedia>
                                                    </Card>
                                                </Uploader>
                                            )
                                        }}
                                    </ImageField>
                                    {showPhotoShare && (
                                        <FormSpy
                                            subscription={{
                                                values: true,
                                            }}
                                        >
                                            {(props) => {
                                                const location = props.values.location
                                                const establishmentGroups = getEstablishmentGroups({
                                                    location,
                                                })
                                                return (
                                                    <List>
                                                        <Divider
                                                            className={clsx({
                                                                [formsClasses.disabled]:
                                                                    isPhotoShared &&
                                                                    !(
                                                                        props.values.image?.file ||
                                                                        props.values.url
                                                                    ),
                                                            })}
                                                        />
                                                        <Field name="isFacebookShared">
                                                            {({ input: { value, onChange } }) => (
                                                                <ListItem
                                                                    divider
                                                                    button
                                                                    onClick={() => onChange(!value)}
                                                                    disabled={
                                                                        isPhotoShared &&
                                                                        !(
                                                                            props.values.image?.file ||
                                                                            props.values.url
                                                                        )
                                                                    }
                                                                >
                                                                    <ListItemIcon>
                                                                        <Switch
                                                                            color="primary"
                                                                            edge="start"
                                                                            checked={value}
                                                                            disableRipple
                                                                        />
                                                                    </ListItemIcon>
                                                                    <ListItemIcon
                                                                        classes={{
                                                                            root: formsClasses.shareIcon,
                                                                        }}
                                                                    >
                                                                        <FacebookIcon
                                                                            className={
                                                                                formsClasses.facebookIcon
                                                                            }
                                                                        />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary={t('actions.shareFacebook')}
                                                                    />
                                                                </ListItem>
                                                            )}
                                                        </Field>
                                                        {groupsInProgress && (
                                                            <Skeleton variant="rect" width="100%">
                                                                <ListItem divider>
                                                                    <ListItemText
                                                                        primary={t(
                                                                            'establishment.groups.notShared',
                                                                        )}
                                                                    />
                                                                </ListItem>
                                                                <ListItem divider>
                                                                    <ListItemText
                                                                        inset
                                                                        primary={t(
                                                                            'establishment.groups.name',
                                                                        )}
                                                                    />
                                                                </ListItem>
                                                            </Skeleton>
                                                        )}
                                                        {!groupsInProgress &&
                                                            establishmentGroups &&
                                                            establishmentGroups.length > 0 && (
                                                                <>
                                                                    <ListItem divider>
                                                                        <ListItemText
                                                                            primary={t(
                                                                                'establishment.groups.notShared',
                                                                            )}
                                                                        />
                                                                    </ListItem>
                                                                    {establishmentGroups.map(
                                                                        (establishmentGroup) => (
                                                                            <ListItem
                                                                                key={
                                                                                    establishmentGroup.facebookGroupId
                                                                                }
                                                                            >
                                                                                <ListItemText
                                                                                    inset
                                                                                    primary={t(
                                                                                        'establishment.groups.name',
                                                                                        {
                                                                                            name: establishmentGroup
                                                                                                .facebookGroup
                                                                                                .name,
                                                                                        },
                                                                                    )}
                                                                                />
                                                                                <ListItemSecondaryAction
                                                                                    className={clsx(
                                                                                        classes.action,
                                                                                    )}
                                                                                >
                                                                                    <Button
                                                                                        variant="outlined"
                                                                                        href={
                                                                                            'https://www.facebook.com/' +
                                                                                            establishmentGroup.facebookGroupId
                                                                                        }
                                                                                        startIcon={
                                                                                            <FacebookIcon
                                                                                                className={
                                                                                                    classes.facebookIcon
                                                                                                }
                                                                                            />
                                                                                        }
                                                                                        endIcon={
                                                                                            <OpenInNewIcon />
                                                                                        }
                                                                                        target="_blank"
                                                                                    >
                                                                                        <Trans i18nKey="actions.accessGroup" />
                                                                                    </Button>
                                                                                </ListItemSecondaryAction>
                                                                            </ListItem>
                                                                        ),
                                                                    )}
                                                                </>
                                                            )}
                                                    </List>
                                                )
                                            }}
                                        </FormSpy>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={8}>
                            <Grid item sm={6} xs={12}>
                                <Typography variant="h1" color="textSecondary">
                                    <Trans i18nKey="form.address" />
                                </Typography>
                                {/* Address */}
                                <div>
                                    <TextField
                                        name="address_line1"
                                        label={t('form.addressStreet1')}
                                        autoComplete="billing address-line1"
                                        required
                                    />
                                    <TextField
                                        name="address_line2"
                                        label={t('form.addressStreet2')}
                                        autoComplete="billing address-line2"
                                    />
                                    <TextField
                                        name="address_zipCode"
                                        label={t('form.addressZipCode')}
                                        autoComplete="billing postal-code"
                                        required
                                    />
                                    <TextField
                                        name="address_city"
                                        label={t('form.addressCity')}
                                        autoComplete="billing address-level2"
                                        required
                                    />
                                    <SelectField
                                        name="address_country"
                                        label={t('form.addressCountry')}
                                        possibilities={countries}
                                        variant="standard"
                                        fullWidth
                                        required
                                        autoComplete="billing country"
                                    />
                                </div>
                                <div className={classes.actions}>
                                    <FormSpy
                                        subscription={{
                                            values: true,
                                        }}
                                    >
                                        {(props) => (
                                            <SubmitButton
                                                variant="contained"
                                                color="primary"
                                                loading={localizeMyAddressInProgress}
                                                disabled={
                                                    !(
                                                        props.values.address_line1 &&
                                                        props.values.address_zipCode &&
                                                        props.values.address_city
                                                    ) ||
                                                    submitting ||
                                                    inProgress
                                                }
                                                onClick={() => localizeAddress(props)}
                                                className={formsClasses.submit}
                                            >
                                                <Trans i18nKey="form.localizeAddress" />
                                            </SubmitButton>
                                        )}
                                    </FormSpy>
                                </div>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Typography variant="h1" color="textSecondary">
                                    <Trans i18nKey="form.location" />
                                </Typography>
                                {/* Map */}
                                <LocationField name="location" update={0} />
                                <FormHelperText className={formsClasses.helper}>
                                    <Trans i18nKey="form.localizeAddressHelper" />
                                </FormHelperText>
                            </Grid>
                        </Grid>
                        <div className={formsClasses.actions}>
                            <Button variant="contained" component={Link} to="/establishments">
                                <Trans i18nKey="actions.back" />
                            </Button>
                            <FormSpy
                                subscription={{
                                    values: true,
                                }}
                            >
                                {(props) => (
                                    <SubmitButton
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        loading={submitting || inProgress}
                                        disabled={localizeMyAddressInProgress}
                                        className={formsClasses.submit}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            const { values } = props
                                            if (
                                                values.address_line1 &&
                                                values.address_zipCode &&
                                                values.address_city &&
                                                (!values.location ||
                                                    !values.location.latitude ||
                                                    !values.location.longitude ||
                                                    (values.location.latitude === 0 &&
                                                        values.location.longitude === 0) ||
                                                    (values.location.latitude === 46.7111 &&
                                                        values.location.longitude === 1.7191))
                                            ) {
                                                return localizeAddress(props).then(() => submit(event))
                                            } else {
                                                return submit(event)
                                            }
                                        }}
                                    >
                                        <Trans i18nKey="actions.validate" />
                                    </SubmitButton>
                                )}
                            </FormSpy>
                        </div>
                    </form>
                )
            }}
        </Form>
    )
}

export default EstablishmentForm
