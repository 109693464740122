import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        grid: {
            '& .MuiDataGrid-overlay': {
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                zIndex: 1000,
            },
        },
        tableContainer: {
            flexGrow: 1,
            minHeight: 400,
        },
        address: {
            whiteSpace: 'pre',
            fontSize: '0.7rem',
        },
        delete: {
            color: theme.palette.error.main,
        },
        restaurantOwner: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
        },
        header: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: theme.spacing(2),
        },
        actions: {
            '& > button, & > a': {
                'margin': theme.spacing(0, 1),
                '&:first-child': {
                    marginLeft: 0,
                },
                '&:last-child': {
                    marginRight: 0,
                },
            },
        },
    }),
)
