import React from 'react'
import withAuthenticateLayout from 'containers/hoc/withAuthenticateLayout'
import AdminsDataTable from '../../components/views/admins/adminsDataTable'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'core/store/reducers'
import { useHistory } from 'react-router-dom'
import { useAdminDelete } from '@sugg-gestion/react-onvaauresto'
import { useApiErrors } from 'core/services/onvaauresto/useApiErrors'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useAdmins } from 'core/services/admins/useAdmins'

const Admins: React.FC = () => {
    const { admins, inProgress } = useAdmins()
    const { ability, token } = useSelector(({ app, onvaauresto: { auth } }: ApplicationState) => ({
        ability: app.ability,
        token: auth.token,
    }))
    const history = useHistory()
    const { adminDelete, inProgress: adminDeleteInProgress } = useAdminDelete()
    const { displayError } = useApiErrors()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const onEditAdmin = (id: string) => {
        history.push('/admin/:id'.replace(':id', id))
    }

    const onAddAdmin = () => {
        history.push('/admin/add')
    }

    const onDeleteAdmin = (id: string) => {
        if (token) {
            adminDelete(token, id)
                .then(() => {
                    enqueueSnackbar(t('admin.delete.success'), {
                        variant: 'success',
                    })
                })
                .catch((error) => {
                    displayError(error)
                })
        }
    }

    return (
        <AdminsDataTable
            admins={[...(admins ?? [])]}
            onAddAdmin={onAddAdmin}
            onEditAdmin={onEditAdmin}
            onDeleteAdmin={onDeleteAdmin}
            ability={ability}
            loading={inProgress || adminDeleteInProgress}
        />
    )
}

export default withAuthenticateLayout(Admins)
