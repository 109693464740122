import React from 'react'
import withAuthenticateLayout from 'containers/hoc/withAuthenticateLayout'
import { AdminModel, useAdminUpdate } from '@sugg-gestion/react-onvaauresto'
import { useApiErrors } from 'core/services/onvaauresto/useApiErrors'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import withAdmin from 'containers/hoc/withAdmin'
import AdminUpdateForm, { AdminUpdateFormState } from 'components/forms/adminUpdateForm'

interface Props {
    adminUrl: AdminModel
    token: string
}

const AdminUpdate: React.FC<Props> = ({ adminUrl, token }) => {
    const { adminUpdate, inProgress } = useAdminUpdate()
    const { displayError } = useApiErrors()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const history = useHistory()

    const onSubmit = (values: AdminUpdateFormState) => {
        if (token) {
            const updateFields = ['username', 'permissions', 'givenName', 'familyName', 'email']
            if (values.password) {
                updateFields.push('password')
            }
            return adminUpdate(token, adminUrl.id, {
                ...values,
                updateFields,
            })
                .then(() => {
                    history.push('/admins')
                    enqueueSnackbar(t('admin.update.success'), {
                        variant: 'success',
                    })
                })
                .catch((error) => {
                    displayError(error)
                })
        }
    }

    return (
        <div>
            <AdminUpdateForm
                inProgress={inProgress}
                onSubmit={onSubmit}
                initialValues={{
                    ...adminUrl,
                    password: undefined,
                }}
            />
        </div>
    )
}

export default withAuthenticateLayout(withAdmin(AdminUpdate))
