import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    Grid,
    LinearProgress,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import { ButtonProps } from '@material-ui/core/Button/Button'
import { LinearProgressProps } from '@material-ui/core/LinearProgress/LinearProgress'
import DoneIcon from '@material-ui/icons/Done'
import ErrorIcon from '@material-ui/icons/Error'
import { Alert } from '@material-ui/lab'
import { useApiErrors } from 'core/services/onvaauresto/useApiErrors'
import React from 'react'
import { Trans } from 'react-i18next'
import { useStyles } from './dialogLoading.css'

export interface LoadingAction {
    href?: ButtonProps['href']
    target?: string
    rel?: string
    size?: ButtonProps['size']
    endIcon?: ButtonProps['endIcon']
    children?: ButtonProps['children']
}

export interface LoadingState {
    title: string | React.ReactNode
    start: boolean
    percent?: number
    finish: boolean
    error?: string | React.ReactNode
    hasError: boolean
    action?: LoadingAction
    allowClosing: boolean
}

interface Props extends Partial<DialogProps> {
    loading: Array<LoadingState>
    title?: string
    onClose: () => void
}

const DialogMultipleLoading: React.FC<Props> = ({ loading, title, onClose, ...dialogProps }) => {
    const classes = useStyles()
    const { showMessage } = useApiErrors()
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

    const progress = (state: LoadingState): LinearProgressProps => {
        if (state.finish) {
            if (state.hasError) {
                return {
                    variant: 'determinate',
                    value: 100,
                    className: classes.failed,
                }
            }
            return {
                variant: 'determinate',
                value: 100,
                className: classes.completed,
            }
        } else if (state.start) {
            if (state.percent !== undefined) {
                return {
                    variant: 'determinate',
                    value: state.percent,
                }
            }
            return {
                variant: 'indeterminate',
                // color: 'primary',
            }
        }
        return {
            variant: 'determinate',
            value: 0,
            color: 'primary',
        }
    }
    return (
        <Dialog
            open
            classes={{
                paper: classes.paper,
            }}
            fullScreen={fullScreen}
            {...dialogProps}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent className={classes.loadingView}>
                {loading.map((state, index) => (
                    <Grid container key={index}>
                        <Grid item xs={12}>
                            <Typography>{state.title}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container alignItems="center">
                                <Grid item style={{ flexGrow: 1 }}>
                                    <LinearProgress {...progress(state)} />
                                </Grid>
                                <Grid item style={{ width: 32, height: 32, textAlign: 'right' }}>
                                    {state.finish && !state.hasError && (
                                        <DoneIcon color="inherit" className={classes.done} />
                                    )}
                                    {state.finish && state.hasError && (
                                        <ErrorIcon color="inherit" className={classes.error} />
                                    )}
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: 'right' }}>
                                    {state.finish && state.action && (
                                        <Button variant="outlined" color="primary" {...state.action} />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.row}>
                            {state.error && (
                                <Alert severity="error" className={classes.alert}>
                                    {showMessage(state.error).message}
                                </Alert>
                            )}
                        </Grid>
                    </Grid>
                ))}
            </DialogContent>
            {loading.every((state) => state.allowClosing || state.finish) && (
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={onClose}>
                        <Trans i18nKey="actions.back" />
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    )
}

export default DialogMultipleLoading
