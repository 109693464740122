import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: '80vw',
            width: 600,
        },
        close: {
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
    }),
)
