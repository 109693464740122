import { AnyAbility } from '@casl/ability'
import { Can } from '@casl/react'
import { Button } from '@material-ui/core'
import { DataGrid, GridColDef, GridSortModel, GridValueFormatterParams } from '@material-ui/data-grid'
import AddIcon from '@material-ui/icons/Add'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import RefreshIcon from '@material-ui/icons/Refresh'
import clsx from 'clsx'
import SubmitButton from 'components/common/submitButton'
import AlertDialog from 'components/dialogs/alertDialog'
import { GroupModel } from 'core/model/group.model'
import { useGridTranslation } from 'core/services/grid/useGidTranslation'
import _ from 'lodash'
import moment from 'moment'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useStyles } from './groupsDataTable.css'

interface Props {
    groups: Array<GroupModel>
    onRefreshGroups: () => void
    onAddGroup: () => void
    onEditGroup: (id: string) => void
    onDeleteGroup: (id: string) => void
    ability: AnyAbility
    loading: boolean
}

const GroupsDataTable: React.FC<Props> = ({
    groups,
    onAddGroup,
    onEditGroup,
    onDeleteGroup,
    ability,
    loading,
    onRefreshGroups,
}) => {
    const classes = useStyles()
    const [deleteDialog, setDeleteDialog] = useState<string>()
    const { t } = useTranslation()
    const gridTranslation = useGridTranslation()
    const [pageSize, setPageSize] = useState(50)

    const deleteGroup = (establishment: GroupModel) => {
        setDeleteDialog(establishment.id)
    }

    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
            field: 'createdAt',
            sort: 'desc',
        },
    ])

    const handleUpdateSortModel = (model: GridSortModel) => {
        if (!_.isEqual(model, sortModel)) {
            setSortModel(model)
        }
    }

    const columns: GridColDef[] = [
        {
            field: 'createdAt',
            headerName: t('common.createdAt'),
            width: 100,
            type: 'dateTime',
            renderCell: ({ value }) => {
                const date = moment(value as string)
                return <Trans i18nKey="common.date" values={{ date: date.toDate() }} />
            },
        },
        { field: 'name', headerName: t('common.groupName'), width: 250 },
        {
            field: 'memberCount',
            headerName: t('common.memberCount'),
            width: 140,
            type: 'number',
        },
        {
            field: 'sharedFacebookPageName',
            headerName: t('common.groupFacebookPage'),
            width: 250,
        },
        {
            field: 'sharedFacebookGroupName',
            headerName: t('common.groupFacebookGroup'),
            width: 250,
        },
        {
            field: 'actions',
            filterable: false,
            sortable: false,
            headerName: t('common.actions'),
            width: 280,
            renderCell: (params: GridValueFormatterParams) => {
                const group = params.row as GroupModel
                return (
                    <div className={classes.actions}>
                        {group.url && (
                            <Button
                                href={group.url}
                                variant="outlined"
                                size="small"
                                endIcon={<OpenInNewIcon />}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Trans i18nKey="actions.check" />
                            </Button>
                        )}
                        <Can ability={ability} I="update" a="group">
                            <Button onClick={() => onEditGroup(group.id)} variant="outlined" size="small">
                                <Trans i18nKey="actions.edit" />
                            </Button>
                        </Can>

                        <Can ability={ability} I="delete" a="group">
                            <Button
                                onClick={() => deleteGroup(group)}
                                variant="outlined"
                                color="inherit"
                                size="small"
                                className={classes.delete}
                            >
                                <Trans i18nKey="actions.delete" />
                            </Button>
                        </Can>
                    </div>
                )
            },
        },
    ]

    return (
        <div className={classes.root}>
            <div className={clsx(classes.header, classes.actions)}>
                <SubmitButton
                    loading={loading}
                    onClick={onRefreshGroups}
                    variant="outlined"
                    startIcon={<RefreshIcon />}
                >
                    <Trans i18nKey="actions.refresh" />
                </SubmitButton>

                <Can ability={ability} I="create" a="group">
                    <Button onClick={onAddGroup} variant="contained" color="primary" startIcon={<AddIcon />}>
                        <Trans i18nKey="actions.addGroup" />
                    </Button>
                </Can>
            </div>
            <div className={classes.tableContainer}>
                <DataGrid
                    className={classes.grid}
                    rows={groups}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(pageSize) => setPageSize(pageSize)}
                    rowsPerPageOptions={[10, 50, 100, 1000]}
                    loading={loading}
                    localeText={gridTranslation}
                    disableSelectionOnClick
                    sortModel={sortModel}
                    onSortModelChange={handleUpdateSortModel}
                    getRowClassName={(params) => {
                        return params.row.public ? '' : classes.hidden
                    }}
                />
            </div>
            <AlertDialog
                open={deleteDialog !== undefined}
                title={t('groups.delete.question')}
                buttons={{ yes: t('common.yes'), no: t('common.no') }}
                onClose={() => setDeleteDialog(undefined)}
                callback={() => {
                    if (deleteDialog) {
                        onDeleteGroup(deleteDialog)
                    }
                }}
            />
        </div>
    )
}

export default GroupsDataTable
