import { decode as decodeBase64 } from 'base-64'

export const getExpirationDate = (token: string) => {
    const base64TokenData = token.split('.')
    let expiration = 0
    if (base64TokenData.length > 1) {
        const tokenData = decodeBase64(base64TokenData[1])
        const tokenObject = JSON.parse(tokenData)
        expiration = tokenObject.exp
    }
    return expiration
}

export const getExpirationRemainingTime = (token: string) => {
    return getExpirationDate(token) - Math.floor(Date.now() / 1000)
}

export const getNextTimeRenew = (token: string) => {
    return (((getExpirationDate(token) - Math.floor(Date.now() / 1000)) * 3) / 4) * 1000
}
