import { Reducer } from 'redux'
import { AdminsState } from './types'
import { getType } from 'typesafe-actions'
import actions from '../actions'
import { AdminCreateResponse } from '../../resources/admins/types'
import { AdminModel, PermissionActions, PermissionModel, PermissionSubjects } from '../../models'

const initialState: AdminsState = {}

const convertPermissions = (permissions: Array<PermissionModel>) => {
    return permissions.map((permission) => {
        let action = permission.action
        switch (permission.action) {
            case PermissionActions.Read:
                action = PermissionActions.Read
                break
            case PermissionActions.Create:
                action = PermissionActions.Create
                break
            case PermissionActions.Update:
                action = PermissionActions.Update
                break
            case PermissionActions.Delete:
                action = PermissionActions.Delete
                break
            case PermissionActions.Manage:
                action = PermissionActions.Manage
                break
            case PermissionActions.Export:
                action = PermissionActions.Export
                break
            case PermissionActions.Link:
                action = PermissionActions.Link
                break
            case PermissionActions.Unlink:
                action = PermissionActions.Unlink
                break
            case PermissionActions.PostPage:
                action = PermissionActions.PostPage
                break
            case PermissionActions.PostGroup:
                action = PermissionActions.PostGroup
                break
        }
        let subject = permission.subject
        switch (permission.subject) {
            case PermissionSubjects.Admin:
                subject = PermissionSubjects.Admin
                break
            case PermissionSubjects.Establishment:
                subject = PermissionSubjects.Establishment
                break
            case PermissionSubjects.RestaurantOwner:
                subject = PermissionSubjects.RestaurantOwner
                break
            case PermissionSubjects.Group:
                subject = PermissionSubjects.Group
                break
            case PermissionSubjects.Facebook:
                subject = PermissionSubjects.Facebook
                break
            case PermissionSubjects.Instagram:
                subject = PermissionSubjects.Instagram
                break
            case PermissionSubjects.Google:
                subject = PermissionSubjects.Google
                break
            case PermissionSubjects.Twitter:
                subject = PermissionSubjects.Twitter
                break
        }
        return {
            ...permission,
            action,
            subject,
        }
    })
}

const reducer: Reducer<AdminsState> = (state = initialState, action) => {
    switch (action.type) {
        case getType(actions.addAdmin): {
            const admin: AdminCreateResponse = action.payload
            const { admins } = state
            if (admins !== undefined) {
                admin.permissions = convertPermissions(admin.permissions)
                admins.push(admin)
            }
            return {
                ...state,
                admins,
            }
        }
        case getType(actions.setAdmin): {
            const admin: AdminModel = action.payload
            let { admins } = state
            let found = false
            if (admins !== undefined) {
                admins = admins.map((searchAdmin) => {
                    if (searchAdmin.id === admin.id) {
                        found = true
                        admin.permissions = convertPermissions(admin.permissions)
                        return admin
                    }
                    return searchAdmin
                })
            }
            if (!found) {
                if (admins !== undefined) {
                    admins.push(admin)
                } else {
                    admins = [admin]
                }
            }
            return {
                ...state,
                admins,
            }
        }
        case getType(actions.setAdmins): {
            let admins: Array<AdminModel> = action.payload
            admins = admins.map((admin) => {
                admin.permissions = convertPermissions(admin.permissions)
                return admin
            })
            return {
                ...state,
                admins,
            }
        }
        case getType(actions.updateAdmin): {
            const admin: AdminModel = action.payload
            let { admins } = state
            if (admins !== undefined) {
                admins = admins.map((searchAdmin) => {
                    if (searchAdmin.id === admin.id) {
                        admin.permissions = convertPermissions(admin.permissions)
                        return admin
                    }
                    return searchAdmin
                })
            }
            return {
                ...state,
                admins,
            }
        }
        case getType(actions.deleteAdmin): {
            const id: string = action.payload
            const { admins } = state
            if (admins !== undefined) {
                const adminIndex = admins.findIndex((admin) => admin.id === id)
                if (adminIndex !== -1) {
                    admins.splice(adminIndex, 1)
                }
            }
            return {
                ...state,
                admins,
            }
        }
        case getType(actions.start): {
            const started = action.payload
            if (!started) {
                return initialState
            }
            return state
        }
        default:
            return state
    }
}

export { reducer as adminsReducer }
