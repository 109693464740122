import { SocialGraphApiProvider } from '@sugg-gestion/social-graph-api-module'
import React, { ComponentType } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../core/store/reducers'

export interface WithSocialGraphApiProps {
    isSocialGraphApiInitialized: boolean
}

const withSocialGraphApi = <T extends WithSocialGraphApiProps>(ComposedComponent: ComponentType<T>) => {
    const WithSocialGraphApi: React.FC<T> = ({ ...props }) => {
        const { token } = useSelector(({ onvaauresto: { auth } }: ApplicationState) => ({
            token: auth.token,
        }))

        return (
            <SocialGraphApiProvider
                apiEndpoint={process.env.REACT_APP_SOCIAL_GRAPH_API_ENDPOINT ?? ''}
                apiRegion={'eu-west-1'}
                token={token}
            >
                {(isInit) => <ComposedComponent {...props} isSocialGraphApiInitialized={isInit} />}
            </SocialGraphApiProvider>
        )
    }
    return WithSocialGraphApi
}

export default withSocialGraphApi
