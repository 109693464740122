import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import { Trans } from 'react-i18next'

interface Props {
    title?: string
    imageUrl: string
    open?: boolean
    onClose: () => void
    classes?: DialogProps['classes']
}

const ImageDialog: React.FC<Props> = ({ open = true, title, onClose, imageUrl, classes: dialogClasses }) => {
    const classes = useStyles()
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
            classes={dialogClasses}
        >
            {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
            <DialogContent
                classes={{
                    root: classes.container,
                }}
            >
                <img src={imageUrl} alt={title} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    <Trans i18nKey="actions.close" />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            height: '100%',
        },
        container: {
            'height': '100%',
            'width': '100%',
            'textAlign': 'center',
            '& img': {
                width: 'auto',
                maxWidth: 'calc(100% - 8px)',
                height: 'auto',
                maxHeight: 'calc(100% - 8px)',
            },
        },
    }),
)

export default ImageDialog
