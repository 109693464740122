import actions from '../../../core/store/actions'
import { useState } from 'react'
import { mutations } from '../../resources'
import { generateError } from '../error'
import { useDispatch } from '../redux'

export const useRestaurantOwnerDelete = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)

    const restaurantOwnerDelete = (token: string, id: string): Promise<void> => {
        setInProgress(true)
        return mutations
            .restaurantOwnerDelete(token, id)
            .then(() => {
                setInProgress(false)
                dispatch(actions.deleteRestaurantOwner(id))
                return
            })
            .catch((error) => {
                setInProgress(false)
                throw generateError(error)
            })
    }

    return {
        restaurantOwnerDelete,
        inProgress,
    }
}
