import { useEstablishments } from 'core/services/establishments/useEstablishments'
import React from 'react'
import { useTranslation } from 'react-i18next'
import SelectField, { SelectValue } from './selectField'

interface Props {
    name: string
    label: string
    required?: boolean
}

const EstablishmentField: React.FC<Props> = (props) => {
    const { establishments } = useEstablishments()
    const { t } = useTranslation()

    const possibilities: Array<SelectValue<string>> = [
        {
            label: t('form.establishmentLabelEmpty'),
            value: '',
        },
    ]

    establishments?.forEach((establishment) => {
        possibilities.push({
            label: t('form.establishmentLabel', {
                name: establishment.name,
                city: establishment.address.city,
            }),
            value: establishment.id,
        })
    })

    return <SelectField possibilities={possibilities} variant="standard" {...props} />
}

export default EstablishmentField
