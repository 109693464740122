import React from 'react'
import withAuthenticateLayout from 'containers/hoc/withAuthenticateLayout'
import EstablishmentForm, { EstablishmentFormState } from 'components/forms/establishmentForm'
import {
    EstablishmentModel,
    useEstablishmentSharePhoto,
    useEstablishmentUpdate,
} from '@sugg-gestion/react-onvaauresto'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'core/store/reducers'
import { useApiErrors } from 'core/services/onvaauresto/useApiErrors'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import withEstablishment from '../hoc/withEstablishment'
import { useEstablishmentGroups } from '../../core/services/groups/useEstablishmentGroups'

interface Props {
    establishment: EstablishmentModel
}

const EstablishmentUpdate: React.FC<Props> = ({ establishment }) => {
    const { ability, token } = useSelector(({ app, onvaauresto: { auth } }: ApplicationState) => ({
        ability: app.ability,
        token: auth.token,
    }))
    const { establishmentUpdate, inProgress } = useEstablishmentUpdate()
    const { getEstablishmentGroups, inProgress: groupsInProgress } = useEstablishmentGroups()
    const { establishmentSharePhoto, inProgress: establishmentSharePhotoInProgress } =
        useEstablishmentSharePhoto()
    const { displayError } = useApiErrors()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const history = useHistory()

    const shareOnFacebook = (token: string, id: string, isFacebookShared: boolean) => {
        if (isFacebookShared) {
            return establishmentSharePhoto(token, id).then(() => true)
        } else {
            return new Promise<boolean>((resolve) => resolve(false))
        }
    }
    const onSubmit = (values: EstablishmentFormState) => {
        if (token) {
            const updateFields = [
                'name',
                'address',
                'location',
                'facebookPage',
                'email',
                'phone',
                'delivery',
                'description',
                'remplisVert',
                'remplisVertComment',
            ]
            if (values.image?.file || values.url) {
                updateFields.push('photo')
            }
            return establishmentUpdate(token, establishment.id, {
                ...values,
                image: values.image?.file,
                updateFields,
            })
                .then(() => {
                    enqueueSnackbar(t('establishment.update.success'), {
                        variant: 'success',
                    })
                })
                .then(() => shareOnFacebook(token, establishment.id, values.isFacebookShared))
                .then((sharedOnFacebook) => {
                    if (sharedOnFacebook) {
                        enqueueSnackbar(t('establishment.share.success'), {
                            variant: 'success',
                        })
                    }
                    history.push('/establishments')
                })
                .catch((error) => {
                    displayError(error)
                })
        }
    }

    const initialValues: EstablishmentFormState = {
        ...establishment,
        isFacebookShared:
            ability.can('share', 'establishment') &&
            !establishment.photo?.isFacebookShared &&
            !establishment.isDisabled,
    }
    if (establishment.photo?.url) {
        initialValues.image = {
            id: 0,
            url: establishment.photo?.url,
        }
    }

    return (
        <div>
            <EstablishmentForm
                disableExtraField={!establishment.restaurantOwner}
                inProgress={inProgress || establishmentSharePhotoInProgress}
                onSubmit={onSubmit}
                initialValues={initialValues}
                isPhotoShared={establishment.photo?.isFacebookShared ?? false}
                showPhotoShare={ability.can('share', 'establishment') && !establishment.isDisabled}
                getEstablishmentGroups={getEstablishmentGroups}
                groupsInProgress={groupsInProgress}
            />
        </div>
    )
}

export default withAuthenticateLayout(withEstablishment(EstablishmentUpdate))
