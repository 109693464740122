import { AnyAbility } from '@casl/ability'
import { Can } from '@casl/react'
import { Button, Typography } from '@material-ui/core'
import { DataGrid, GridColDef, GridValueFormatterParams } from '@material-ui/data-grid'
import FacebookIcon from '@material-ui/icons/Facebook'
import ImageIcon from '@material-ui/icons/Image'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import ShareIcon from '@material-ui/icons/Share'
import SubmitButton from 'components/common/submitButton'
import ImageDialog from 'components/dialogs/imageDialog'
import { FacebookPageModel } from 'core/model/facebookPage.model'
import { SharedFacebookPageModel } from 'core/model/sharedFacebookPage.model'
import { useGridTranslation } from 'core/services/grid/useGidTranslation'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useStyles } from './facebookProfilesDataTable.css'

interface Props {
    facebookPages: FacebookPageModel[]
    sharedFacebookPages: SharedFacebookPageModel[]
    loadingSharedFacebookPages: boolean
    loading: boolean
    loadingShareFacebookPage: boolean
    ability: AnyAbility
    onSharePage: (pageId: string, shared: boolean) => void
}

const FacebookPagesDataTable: React.FC<Props> = ({
    facebookPages,
    loading,
    ability,
    onSharePage,
    sharedFacebookPages,
    loadingSharedFacebookPages,
    loadingShareFacebookPage,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const gridTranslation = useGridTranslation()

    const [imageDialog, setImageDialog] = useState<string>('')
    const [pageSize, setPageSize] = useState(50)

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: t('common.id'),
            width: 140,
            sortable: false,
        },
        { field: 'name', headerName: t('common.name'), width: 240 },
        { field: 'category', headerName: t('common.category'), width: 160 },
        {
            field: 'fanCount',
            headerName: t('common.memberCount'),
            width: 200,
            type: 'number',
        },
        {
            field: 'actions',
            filterable: false,
            sortable: false,
            headerName: t('common.actions'),
            width: 660,
            renderCell: (params: GridValueFormatterParams) => {
                const facebookPage = params.row as FacebookPageModel
                return (
                    <div className={classes.actions}>
                        <Can ability={ability} I="share" a="facebook">
                            {sharedFacebookPages.findIndex(
                                (sharedFacebookPage) => sharedFacebookPage.pageId === facebookPage.id,
                            ) !== -1 ? (
                                <SubmitButton
                                    loading={loadingShareFacebookPage || loadingSharedFacebookPages}
                                    onClick={() => {
                                        onSharePage(facebookPage.id, false)
                                    }}
                                    variant="outlined"
                                    size="small"
                                    color="inherit"
                                    className={classes.delete}
                                    startIcon={<ShareIcon />}
                                >
                                    <Trans i18nKey="actions.removeSharePage" />
                                </SubmitButton>
                            ) : (
                                <SubmitButton
                                    loading={loadingShareFacebookPage || loadingSharedFacebookPages}
                                    onClick={() => {
                                        onSharePage(facebookPage.id, true)
                                    }}
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    startIcon={<ShareIcon />}
                                >
                                    <Trans i18nKey="actions.sharePage" />
                                </SubmitButton>
                            )}
                        </Can>
                        <Button
                            href={facebookPage.link}
                            variant="outlined"
                            size="small"
                            startIcon={<FacebookIcon className={classes.facebookIcon} />}
                            endIcon={<OpenInNewIcon />}
                            target="_blank"
                        >
                            <Trans i18nKey="actions.accessPage" />
                        </Button>
                        {facebookPage.picture && (
                            <Button
                                onClick={() => {
                                    if (facebookPage.picture) {
                                        setImageDialog(facebookPage.picture)
                                    }
                                }}
                                variant="outlined"
                                size="small"
                                startIcon={<ImageIcon />}
                            >
                                <Trans i18nKey="actions.seeProfilePicture" />
                            </Button>
                        )}
                    </div>
                )
            },
        },
    ]

    return (
        <div className={classes.root}>
            <div className={classes.headerContainer}>
                <Typography variant="h1">
                    <Trans i18nKey="facebookProfiles.pages" />
                </Typography>
            </div>
            <div className={classes.tableContainer}>
                <DataGrid
                    rows={facebookPages}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(pageSize) => setPageSize(pageSize)}
                    rowsPerPageOptions={[10, 50, 100, 1000]}
                    loading={loading}
                    localeText={gridTranslation}
                    disableSelectionOnClick
                    getRowClassName={(params) => {
                        if (ability.can('share', 'facebook')) {
                            const facebookPage = params.row as FacebookPageModel
                            return sharedFacebookPages.findIndex(
                                (sharedFacebookPage) => sharedFacebookPage.pageId === facebookPage.id,
                            ) !== -1
                                ? classes.shared
                                : ''
                        }
                        return ''
                    }}
                />
            </div>
            <ImageDialog
                open={imageDialog !== ''}
                imageUrl={imageDialog}
                onClose={() => setImageDialog('')}
            />
        </div>
    )
}

export default FacebookPagesDataTable
