import { Grid, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { PermissionModel } from '@sugg-gestion/react-onvaauresto'
import SubmitButton from 'components/common/submitButton'
import _ from 'lodash'
import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import PasswordField from './components/passwordField'
import PermissionsField from './components/permissionsField'
import TextField from './components/textField'
import { useFormsStyles } from './forms.css'
import { usePermissions } from './helpers/usePermissions'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: AdminUpdateFormState) => void
    initialValues: AdminUpdateFormState
    inProgress: boolean
}

export interface AdminUpdateFormState {
    username: string
    password?: string
    givenName?: string
    familyName?: string
    email?: string
    permissions: Array<PermissionModel>
}

export interface AdminUpdateFormStateInternal {
    username: string
    password?: string
    givenName?: string
    familyName?: string
    email?: string
    permissionsForAll: Array<PermissionModel>
    permissionsForMe: Array<PermissionModel>
}

export interface AdminUpdateFormStateError {
    username?: string
    permissionsForAll?: string
    permissionsForMe?: string
}

const AdminUpdateForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    initialValues,
    inProgress,
}) => {
    const formsClasses = useFormsStyles()
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const [resourceTab, setResourceTab] = useState('*')

    const {
        updatePermissions: updatePermissionsForAll,
        transformRawPermissions: transformRawPermissionsForAll,
    } = usePermissions('*')
    const {
        updatePermissions: updatePermissionsForMe,
        transformRawPermissions: transformRawPermissionsForMe,
    } = usePermissions('me')

    const submit = (values: AdminUpdateFormStateInternal) => {
        if (_.isEmpty(values.password)) {
            values.password = undefined
        }
        return onSubmit({
            ...values,
            permissions: [...values.permissionsForMe, ...values.permissionsForAll],
        })
    }

    const initialValuesInternal: AdminUpdateFormStateInternal = {
        ...initialValues,
        permissionsForAll: transformRawPermissionsForAll(initialValues.permissions),
        permissionsForMe: transformRawPermissionsForMe(initialValues.permissions),
    }

    const validate = (values: AdminUpdateFormStateInternal) => {
        const errors: AdminUpdateFormStateError = {}

        if (!values.username) {
            errors.username = t('form-validation.username')
        }
        if (!values.permissionsForAll && !values.permissionsForMe) {
            errors.permissionsForAll = t('form-validation.permissions')
            errors.permissionsForMe = t('form-validation.permissions')
        }
        if ([...(values.permissionsForAll || []), ...(values.permissionsForMe || [])].length < 1) {
            errors.permissionsForMe = t('form-validation.permissions')
        }

        return errors
    }

    return (
        <Form
            initialValues={initialValuesInternal}
            subscription={subscription}
            // debug={console.log}
            onSubmit={submit}
            mutators={{
                updatePermissionsForAll: (args, state, tools) => {
                    const event = args[0] as React.ChangeEvent<HTMLInputElement>
                    const checked = args[1] as boolean
                    const {
                        formState: { values },
                    } = state
                    let { permissionsForAll } = values as AdminUpdateFormStateInternal
                    let disabledPermission: PermissionModel | undefined = undefined
                    if (!checked) {
                        disabledPermission = JSON.parse(event.currentTarget.value) as PermissionModel
                    }
                    const newPermissions = updatePermissionsForAll(permissionsForAll, disabledPermission)
                    tools.changeValue(state, 'permissionsForAll', () => _.uniq(newPermissions))
                },
                updatePermissionsForMe: (args, state, tools) => {
                    const event = args[0] as React.ChangeEvent<HTMLInputElement>
                    const checked = args[1] as boolean
                    const {
                        formState: { values },
                    } = state
                    let { permissionsForMe } = values as AdminUpdateFormStateInternal
                    let disabledPermission: PermissionModel | undefined = undefined
                    if (!checked) {
                        disabledPermission = JSON.parse(event.currentTarget.value) as PermissionModel
                    }
                    const newPermissions = updatePermissionsForMe(permissionsForMe, disabledPermission)
                    tools.changeValue(state, 'permissionsForMe', () => _.uniq(newPermissions))
                },
            }}
            validate={validate}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting, form: { mutators } }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={8} justifyContent="center">
                        <Grid item sm={10} xs={12}>
                            <Typography variant="h1" color="textSecondary">
                                <Trans i18nKey="actions.updateAdmin" />
                            </Typography>
                            <div>
                                <TextField name="username" label={t('form.username')} required />
                                <Grid container spacing={isMobile ? 0 : 6}>
                                    <Grid item sm={6} xs={12}>
                                        <TextField name="givenName" label={t('form.givenName')} />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <TextField name="familyName" label={t('form.familyName')} />
                                    </Grid>
                                </Grid>
                                <TextField name="email" label={t('form.email')} />
                                <PasswordField
                                    name="password"
                                    label={t('form.newPassword')}
                                    helperText={t('form.passwordUpdate')}
                                />
                                <Tabs
                                    variant="fullWidth"
                                    value={resourceTab}
                                    onChange={(_, newValue) => setResourceTab(newValue)}
                                >
                                    <Tab value="*" label={<Trans i18nKey="form.permissionsOnAll" />} />
                                    <Tab value="me" label={<Trans i18nKey="form.permissionsOnMe" />} />
                                </Tabs>
                                {resourceTab === '*' && (
                                    <PermissionsField
                                        name="permissionsForAll"
                                        label={t('form.permissions')}
                                        resource={'*'}
                                        possibilities={[
                                            'admins',
                                            'establishments',
                                            'restaurantOwners',
                                            'groups',
                                            'facebook',
                                        ]}
                                        required
                                        onUpdate={(event, checked) =>
                                            mutators.updatePermissionsForAll(event, checked)
                                        }
                                    />
                                )}
                                {resourceTab === 'me' && (
                                    <PermissionsField
                                        name="permissionsForMe"
                                        label={t('form.permissions')}
                                        resource={'me'}
                                        possibilities={['facebook']}
                                        required
                                        onUpdate={(event, checked) =>
                                            mutators.updatePermissionsForMe(event, checked)
                                        }
                                    />
                                )}
                            </div>
                            <div className={formsClasses.actions}>
                                <SubmitButton
                                    variant="contained"
                                    color="primary"
                                    className={formsClasses.submit}
                                    type="submit"
                                    loading={submitting || inProgress}
                                >
                                    <Trans i18nKey="actions.validate" />
                                </SubmitButton>
                            </div>
                        </Grid>
                    </Grid>
                </form>
            )}
        </Form>
    )
}

export default AdminUpdateForm
