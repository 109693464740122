import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainLayout: {
            display: 'flex',
            minHeight: '100%',
            minWidth: '100%',
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            position: 'relative',
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(12),
            marginTop: 30,
        },
        logo: {
            width: 300,
            marginBottom: theme.spacing(4),
        },
    }),
)
