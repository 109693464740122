import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'core/store/reducers'
import { useHistory } from 'react-router-dom'
import Loader from 'components/common/loader'

const withAuthentication = (ComposedComponent: any) => {
    const WithAuthentication: React.FC = (props) => {
        const history = useHistory()
        const { admin, token } = useSelector(({ onvaauresto: { auth } }: ApplicationState) => ({
            admin: auth.admin,
            token: auth.token,
        }))
        useEffect(() => {
            if (!admin) {
                history.push('/sign-in')
            }
        }, [admin, history])
        if (admin && token) {
            return <ComposedComponent admin={admin} token={token} {...props} />
        } else {
            return <Loader />
        }
    }
    return WithAuthentication
}

export default withAuthentication
