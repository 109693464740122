import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import _ from 'lodash'
import React from 'react'
import { Field, FormSpy } from 'react-final-form'
import TextField from './textField'

interface Props<T> {
    name: string
    label: string | React.ReactNode
    placeholder?: string
    values: Array<CheckboxValue<T>>
    includeOther?: boolean
    column?: 1 | 2 | 4
    required?: boolean
    onUpdate?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

export interface CheckboxValue<T> {
    label: React.ReactNode
    value: T
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            flexGrow: 1,
            fontWeight: theme.fontsWeight.semiBold,
            marginBottom: theme.spacing(2),
        },
        item: {
            display: 'flex',
            flexDirection: 'column',
            fontWeight: theme.fontsWeight.semiBold,
        },

        itemtxt: {
            color: '#000000',
            fontWeight: theme.fontsWeight.semiBold,
        },

        checkbox: {
            'width': '100%',
            'border': '1px solid #EBE6EF',
            'borderRadius': theme.spacing(0.5),
            'padding': theme.spacing(0.5),
            'margin': 0,
            '& > .MuiFormControlLabel-label': {
                padding: theme.spacing(0, 0, 0, 2),
                fontWeight: theme.fontsWeight.semiBold,
                flexGrow: 1,
            },
        },
        error: {
            'borderColor': theme.palette.error.main,
            '& > .MuiFormControlLabel-label': {
                color: theme.palette.error.main,
            },
        },
        checkboxSelected: {
            width: '100%',
            border: '1px solid #EBE6EF',
            borderRadius: theme.spacing(0.5),
            padding: theme.spacing(0.5),
            margin: 0,
            backgroundColor: '#F0F0F0',
        },
        hidden: {
            visibility: 'hidden',
        },
        carder: {
            'borderRadius': 5,
            'border': '1px solid #E2DFDF ',
            '&:is checked': {
                backgroundColor: '#E2DFDF',
            },
            'color': 'black',
            'display': 'flex',
            'justifyContent': 'left',
            'textAlign': 'left',
            'alignItems': 'Right',
        },
    }),
)

const CheckboxGroupField = <T,>({
    name,
    label,
    values,
    includeOther = false,
    placeholder,
    column = 1,
    required = false,
    onUpdate,
}: Props<T>) => {
    const classes = useStyles()
    let itemSize: 12 | 6 | 3 = 12
    if (column === 2) {
        itemSize = 6
    }
    if (column === 4) {
        itemSize = 3
    }
    return (
        <FormSpy subscription={{ errors: true, touched: true, values: true }}>
            {(props) => {
                const hasError =
                    props.errors !== undefined &&
                    props.errors[name] &&
                    props.touched !== undefined &&
                    props.touched[name]
                return (
                    <FormControl
                        component="fieldset"
                        fullWidth
                        margin="normal"
                        required={required}
                        error={hasError}
                    >
                        {label !== undefined && (
                            <FormLabel component="legend" className={classes.label}>
                                {label}
                            </FormLabel>
                        )}
                        {values.length > 0 ? (
                            <FormGroup>
                                <Grid container spacing={2}>
                                    {values.map((checkboxValue, index) => (
                                        <Grid item xs={itemSize} className={classes.item} key={index}>
                                            <Field<T> name={name} type="checkbox" value={checkboxValue.value}>
                                                {({ input: { name, value, onChange } }) => {
                                                    const checked =
                                                        _.findIndex(props.values[name], (findValue) =>
                                                            _.isEqual(findValue, value),
                                                        ) !== -1
                                                    return (
                                                        <FormControlLabel
                                                            className={clsx(classes.checkbox, {
                                                                [classes.checkboxSelected]: checked,
                                                                [classes.error]: hasError,
                                                            })}
                                                            control={
                                                                <Checkbox
                                                                    name={name}
                                                                    value={JSON.stringify(value)}
                                                                    color="primary"
                                                                    checked={checked}
                                                                    className={checked ? '' : classes.hidden}
                                                                    onChange={(event, checked) => {
                                                                        onChange(event)
                                                                        if (onUpdate) {
                                                                            onUpdate(event, checked)
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            label={checkboxValue.label}
                                                            labelPlacement={'start'}
                                                        />
                                                    )
                                                }}
                                            </Field>
                                        </Grid>
                                    ))}
                                    {includeOther && (
                                        <Grid item xs={5}>
                                            <FormControlLabel
                                                classes={{
                                                    label: classes.label,
                                                }}
                                                control={
                                                    <Field name={name} type="checkbox">
                                                        {({ input: { name, onChange } }) => (
                                                            <Checkbox
                                                                name={name}
                                                                value={props.values[name]}
                                                                color="primary"
                                                                checked={
                                                                    _.findIndex(values, {
                                                                        value: props.values[name],
                                                                    }) === -1
                                                                }
                                                                onChange={() =>
                                                                    onChange(props.values[name + 'Other'])
                                                                }
                                                            />
                                                        )}
                                                    </Field>
                                                }
                                                label={
                                                    <Field name={name} type="checkbox">
                                                        {({ input: { name, onChange } }) => (
                                                            <TextField
                                                                name={name + 'Other'}
                                                                label={undefined}
                                                                onChange={onChange}
                                                                placeholder={placeholder}
                                                            />
                                                        )}
                                                    </Field>
                                                }
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                                {hasError && !!props.errors && (
                                    <FormHelperText error={hasError}>{props.errors[name]}</FormHelperText>
                                )}
                            </FormGroup>
                        ) : (
                            <TextField name={name} label={placeholder} />
                        )}
                    </FormControl>
                )
            }}
        </FormSpy>
    )
}

export default CheckboxGroupField
