import SubmitButton from 'components/common/submitButton'
import React from 'react'
import { Form } from 'react-final-form'
import TextField from './components/textField'
import PasswordField from './components/passwordField'
import _ from 'lodash'
import { Trans, useTranslation } from 'react-i18next'
import { Grid, Typography } from '@material-ui/core'
import { useFormsStyles } from './forms.css'
import { validateAWSEmail } from 'appsync-scalar-utils'
import EstablishmentField from './components/establishmentField'
import CountryField from './components/countryField'
import PhoneField from './components/phoneField'
import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: RestaurantOwnerUpdateFormState) => void
    initialValues: RestaurantOwnerUpdateFormState
    inProgress: boolean
    disableEstablishment: boolean
}

export interface RestaurantOwnerUpdateFormState {
    givenName: string
    familyName: string
    email: string
    country: CountryCode
    phone: string
    password?: string
    establishment?: string
}

export interface RestaurantOwnerUpdateFormError {
    givenName?: string
    familyName?: string
    email?: string
    country?: string
    phone?: string
    password?: string
    establishment?: string
}

const RestaurantOwnerForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    initialValues,
    inProgress,
    disableEstablishment,
}) => {
    const formsClasses = useFormsStyles()
    const { t } = useTranslation()

    const submit = (values: RestaurantOwnerUpdateFormState) => {
        if (_.isEmpty(values.password)) {
            values.password = undefined
        }
        if (_.isEmpty(values.establishment)) {
            values.establishment = undefined
        }
        return onSubmit(values)
    }

    const validate = (values: RestaurantOwnerUpdateFormState) => {
        const errors: RestaurantOwnerUpdateFormError = {}

        if (!values.email) {
            errors.email = t('form-validation.email')
        } else if (!validateAWSEmail(values.email)) {
            errors.email = t('form-validation.emailValid')
        }

        if (!values.givenName) {
            errors.givenName = t('form-validation.givenName')
        }

        if (!values.familyName) {
            errors.familyName = t('form-validation.familyName')
        }

        if (values.phone !== '') {
            const phone = parsePhoneNumberFromString(values.phone, values.country)
            if (phone === undefined || !phone.isValid()) {
                errors.phone = t('form-validation.phone')
            }
        } else {
            errors.phone = t('form-validation.phoneRequired')
        }
        return errors
    }

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={submit}
            validate={validate}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={8} justifyContent="center">
                        <Grid item sm={6} xs={12}>
                            <Typography variant="h1" color="textSecondary">
                                <Trans i18nKey="actions.updateRestaurantOwner" />
                            </Typography>
                            <div>
                                <TextField name="givenName" label={t('form.givenName')} required />
                                <TextField name="familyName" label={t('form.familyName')} required />
                                <TextField name="email" label={t('form.email')} required />
                                <CountryField label={t('form.country')} name="country" required />
                                <PhoneField label={t('form.phone')} name="phone" required />
                                <PasswordField
                                    name="password"
                                    label={t('form.newPassword')}
                                    helperText={t('form.passwordUpdate')}
                                />
                                {!disableEstablishment && (
                                    <EstablishmentField
                                        label={t('form.establishment')}
                                        name="establishment"
                                    />
                                )}
                            </div>
                            <div className={formsClasses.actions}>
                                <SubmitButton
                                    variant="contained"
                                    color="primary"
                                    className={formsClasses.submit}
                                    type="submit"
                                    loading={submitting || inProgress}
                                >
                                    <Trans i18nKey="actions.validate" />
                                </SubmitButton>
                            </div>
                        </Grid>
                    </Grid>
                </form>
            )}
        </Form>
    )
}

export default RestaurantOwnerForm
