import React from 'react'
import { useStyles } from './signInForm.css'
import { useFormsStyles } from './forms.css'
import { Form } from 'react-final-form'
import TextField from './components/textField'
import PasswordField from './components/passwordField'
import SubmitButton from 'components/common/submitButton'
import { Trans, useTranslation } from 'react-i18next'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: SignInFormState) => void
}

export interface SignInFormState {
    username: string
    password: string
}

interface SignInFormError {
    username?: string
    password?: string
}

const SignInForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
}) => {
    const classes = useStyles()
    const formsClasses = useFormsStyles()
    const { t } = useTranslation()

    let initialValues: SignInFormState = {
        username: '',
        password: '',
    }

    const validate = (values: SignInFormState) => {
        const errors: SignInFormError = {}
        if (!values.username) {
            errors.username = t('form-validation.username')
        }
        if (!values.password) {
            errors.password = t('form-validation.password')
        }
        return errors
    }

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={onSubmit}
            validate={validate}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.formContent}>
                        <TextField name="username" label={t('form.username')} required />
                        <PasswordField name="password" label={t('form.password')} required />
                    </div>
                    <div className={formsClasses.actions}>
                        <SubmitButton
                            variant="contained"
                            color="primary"
                            type="submit"
                            loading={submitting}
                            className={formsClasses.submit}
                        >
                            <Trans i18nKey="actions.validate" />
                        </SubmitButton>
                    </div>
                </form>
            )}
        </Form>
    )
}
export default SignInForm
