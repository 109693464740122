import React from 'react'
import { Card, CardContent, Container, Typography } from '@material-ui/core'
import { Trans } from 'react-i18next'

const SignInCard: React.FC = ({ children }) => {
    return (
        <Container maxWidth="xs">
            <Card>
                <CardContent>
                    <Typography color="textPrimary" variant="h1">
                        <Trans i18nKey="sign-in.title" />
                    </Typography>
                    <Typography color="textPrimary">
                        <Trans i18nKey="sign-in.message" />
                    </Typography>
                    {children}
                </CardContent>
            </Card>
        </Container>
    )
}

export default SignInCard
