import { Reducer } from 'redux'
import { RestaurantOwnersState } from './types'
import { getType } from 'typesafe-actions'
import actions from '../actions'
import { RestaurantOwnerModel } from '../../models'

const initialState: RestaurantOwnersState = {}

const reducer: Reducer<RestaurantOwnersState> = (state = initialState, action) => {
    switch (action.type) {
        case getType(actions.setRestaurantOwner): {
            const restaurantOwner: RestaurantOwnerModel = action.payload
            let { restaurantOwners } = state
            let found = false
            if (restaurantOwners !== undefined) {
                restaurantOwners = restaurantOwners.map((searchRestaurantOwner) => {
                    if (searchRestaurantOwner.id === restaurantOwner.id) {
                        found = true
                        return restaurantOwner
                    }
                    return searchRestaurantOwner
                })
            }
            if (!found) {
                if (restaurantOwners !== undefined) {
                    restaurantOwners.push(restaurantOwner)
                } else {
                    restaurantOwners = [restaurantOwner]
                }
            }
            return {
                ...state,
                restaurantOwners,
            }
        }
        case getType(actions.setRestaurantOwners): {
            const restaurantOwners: Array<RestaurantOwnerModel> = action.payload
            return {
                ...state,
                restaurantOwners,
            }
        }
        case getType(actions.updateRestaurantOwner): {
            const restaurantOwner: RestaurantOwnerModel = action.payload
            let { restaurantOwners } = state
            if (restaurantOwners !== undefined) {
                restaurantOwners = restaurantOwners.map((searchRestaurantOwner) => {
                    if (searchRestaurantOwner.id === restaurantOwner.id) {
                        return restaurantOwner
                    }
                    return searchRestaurantOwner
                })
            }
            return {
                ...state,
                restaurantOwners,
            }
        }
        case getType(actions.deleteRestaurantOwner): {
            const id: string = action.payload
            const { restaurantOwners } = state
            if (restaurantOwners !== undefined) {
                const restaurantOwnerIndex = restaurantOwners.findIndex(
                    (restaurantOwner) => restaurantOwner.id === id,
                )
                if (restaurantOwnerIndex !== -1) {
                    restaurantOwners.splice(restaurantOwnerIndex, 1)
                }
            }
            return {
                ...state,
                restaurantOwners,
            }
        }
        case getType(actions.start): {
            const started = action.payload
            if (!started) {
                return initialState
            }
            return state
        }
        case getType(actions.adminSignOut): {
            return {
                ...state,
                restaurantOwners: undefined,
            }
        }
        default:
            return state
    }
}

export { reducer as restaurantOwnersReducer }
