import APIError from '../../../core/resources/error'
import { AxiosError } from 'axios'

export const generateError = (error: AxiosError | undefined = undefined) => {
    if (error === undefined) {
        return new APIError()
    }

    const errorType = error.code
    const message = error.message
    const data = error.response?.data
    return new APIError(errorType, message, data)
}
