import { Maybe } from '@sugg-gestion/common-graph-api-module'

export enum NotificationType {
    PublishingGroup = 'PUBLISHING_GROUP',
    PublishingPage = 'PUBLISHING_PAGE',
    Refreshing = 'REFRESHING',
}

export enum Language {
    En = 'EN',
    Fr = 'FR',
}

export interface MessageByLanguage {
    language: Language
    text: string
}

export enum NotificationActionType {
    Open = 'OPEN',
}

export interface NotificationAction {
    text?: Maybe<Array<MessageByLanguage>>
    type: NotificationActionType
    url?: Maybe<string>
}

export interface NotificationModel {
    actions?: Maybe<Array<Maybe<NotificationAction>>>
    errorMessage?: Maybe<Array<MessageByLanguage>>
    id: string
    message?: Maybe<Array<MessageByLanguage>>
    notificationId: string
    succeed: boolean
    type: NotificationType
    createdAt: string
    updatedAt?: Maybe<string>
}

export interface RefreshingNotificationModel extends NotificationModel {
    percent?: Maybe<number>
    profileId: string
}

export interface PublishingNotificationModel extends NotificationModel {
    started: boolean
    post: PostModel
}

export interface PostModel {
    from?: Maybe<string>
    fromUrl?: Maybe<string>
    fromPicture?: Maybe<string>
    message?: Maybe<string>
    photos?: Maybe<Array<Maybe<PostPhotoModel>>>
    shared?: Maybe<string>
    to: string
    toUrl?: Maybe<string>
    publishedAt?: Maybe<string>
}

export interface PostPhotoModel {
    url: string
    comment?: Maybe<string>
}
