import { AxiosResponse } from 'axios'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'
import Client from '../../client'
import { prefix } from '../../config'
import { mutations } from '../../resources'
import {
    AdminSignInResponse,
    RefreshTokenResponse,
    RestaurantOwnerSignInResponse,
} from '../../resources/auth/types'
import { getExpirationRemainingTime, getNextTimeRenew } from '../../services/token/token'
import actions from '../actions'
import { ApplicationState } from '../reducers'

function* sessionSaga(init: boolean) {
    yield takeLatest(getType(actions.start), loadSession)

    yield takeLatest(getType(actions.adminSignIn), saveSessionSignIn)
    yield takeLatest(getType(actions.adminSignIn), saveSessionAdmin)
    yield takeLatest(getType(actions.adminSignIn), scheduleRenewToken)
    yield takeLatest(getType(actions.adminSignOut), destroySession)

    yield takeLatest(getType(actions.restaurantOwnerSignIn), saveSessionSignIn)
    yield takeLatest(getType(actions.restaurantOwnerSignIn), saveSessionRestaurantOwner)
    yield takeLatest(getType(actions.restaurantOwnerSignIn), scheduleRestaurantOwnerRenewToken)
    yield takeLatest(getType(actions.restaurantOwnerSignOut), destroySession)

    if (init) {
        yield put(actions.start(false))
    }
}

function* loadSession(action: ActionType<typeof actions.start>) {
    const started = action.payload
    if (!started) {
        const persistentStorage = Client.getPersistentStorage()
        const sessionStorage = Client.getSessionStorage()

        const admin = yield persistentStorage.getItem(prefix + '/auth/admin')
        const restaurantOwner = yield persistentStorage.getItem(prefix + '/auth/restaurantOwner')
        const token = yield sessionStorage.getItem(prefix + '/auth/token')
        const longLifeToken = yield persistentStorage.getItem(prefix + '/auth/longLifeToken')
        if (admin !== null) {
            if (token !== null && token !== '') {
                const remainingTime = getExpirationRemainingTime(token)
                if (remainingTime > 10) {
                    yield put(
                        actions.adminSignIn({
                            admin: JSON.parse(admin),
                            authentication: {
                                token,
                                longLifeToken: longLifeToken == null ? undefined : longLifeToken,
                            },
                        }),
                    )
                }
            }
            if (longLifeToken !== null && longLifeToken !== '') {
                try {
                    const response: AxiosResponse<AdminSignInResponse> = yield call(
                        mutations.adminSignInByToken,
                        {
                            token: longLifeToken,
                        },
                    )
                    if (response.data) {
                        yield put(actions.adminSignIn(response.data))
                    }
                } catch (_) {}
            }
        } else if (restaurantOwner !== null) {
            if (token !== null && token !== '') {
                const remainingTime = getExpirationRemainingTime(token)
                if (remainingTime > 10) {
                    yield put(
                        actions.restaurantOwnerSignIn({
                            restaurantOwner: JSON.parse(restaurantOwner),
                            authentication: {
                                token,
                                longLifeToken: longLifeToken == null ? undefined : longLifeToken,
                            },
                        }),
                    )
                }
            }
            if (longLifeToken !== null && longLifeToken !== '') {
                try {
                    const response: AxiosResponse<RestaurantOwnerSignInResponse> = yield call(
                        mutations.restaurantOwnerSignInByToken,
                        {
                            token: longLifeToken,
                        },
                    )
                    if (response.data) {
                        yield put(actions.restaurantOwnerSignIn(response.data))
                    }
                } catch (_) {}
            }
        }
        yield put(actions.start(true))
    }
}

function* saveSessionSignIn(
    action: ActionType<typeof actions.adminSignIn | typeof actions.restaurantOwnerSignIn>,
) {
    const {
        authentication: { token, longLifeToken },
    } = action.payload
    const persistentStorage = Client.getPersistentStorage()
    const sessionStorage = Client.getSessionStorage()

    yield sessionStorage.setItem(prefix + '/auth/token', token !== null ? token : '')
    if (longLifeToken) {
        yield persistentStorage.setItem(prefix + '/auth/longLifeToken', longLifeToken)
    }
}

function* saveSessionAdmin(action: ActionType<typeof actions.adminSignIn>) {
    const { admin } = action.payload
    const persistentStorage = Client.getPersistentStorage()

    yield persistentStorage.setItem(prefix + '/auth/admin', admin !== null ? JSON.stringify(admin) : '')
}

function* saveSessionRestaurantOwner(action: ActionType<typeof actions.restaurantOwnerSignIn>) {
    const { restaurantOwner } = action.payload
    const persistentStorage = Client.getPersistentStorage()

    yield persistentStorage.setItem(
        prefix + '/auth/restaurantOwner',
        restaurantOwner !== null ? JSON.stringify(restaurantOwner) : '',
    )
}

function* destroySession() {
    const sessionStorage = Client.getSessionStorage()
    const persistentStorage = Client.getPersistentStorage()

    yield sessionStorage.removeItem(prefix + '/auth/token')
    yield persistentStorage.removeItem(prefix + '/auth/admin')
    yield persistentStorage.removeItem(prefix + '/auth/restaurantOwner')
    yield persistentStorage.removeItem(prefix + '/auth/longLifeToken')
}

let renewTimeout: NodeJS.Timeout | undefined = undefined
const delay = (ms: number) =>
    new Promise((res) => {
        renewTimeout = setTimeout(res, ms)
    })

function* scheduleRenewToken(action: ActionType<typeof actions.adminSignIn>) {
    const {
        authentication: { token },
    } = action.payload
    let nextTimeRenew = getNextTimeRenew(token)
    if (nextTimeRenew > 0) {
        if (renewTimeout !== undefined) {
            clearTimeout(renewTimeout)
        }
        yield delay(nextTimeRenew)
        yield refreshToken(action)
    }
}

function* refreshToken(action: ActionType<typeof actions.adminSignIn>) {
    const {
        admin,
        authentication: { token, longLifeToken },
    } = action.payload
    try {
        const response: AxiosResponse<RefreshTokenResponse> = yield call(mutations.refreshAdminToken, {
            token,
        })
        if (response.data) {
            yield put(
                actions.adminSignIn({
                    admin: admin,
                    authentication: {
                        token: response.data.token,
                        longLifeToken,
                    },
                }),
            )
        }
    } catch (ignore) {
        yield put(actions.adminSignOut())
    }
}

function* scheduleRestaurantOwnerRenewToken(action: ActionType<typeof actions.restaurantOwnerSignIn>) {
    const {
        authentication: { token },
    } = action.payload
    let nextTimeRenew = getNextTimeRenew(token)
    if (nextTimeRenew > 0) {
        if (renewTimeout !== undefined) {
            clearTimeout(renewTimeout)
        }
        yield delay(nextTimeRenew)
        yield refreshRestaurantOwnerToken()
    }
}

function* refreshRestaurantOwnerToken() {
    const { restaurantOwner, token, longLifeToken } = yield select(
        ({ onvaauresto: { auth } }: ApplicationState) => ({
            restaurantOwner: auth.restaurantOwner,
            token: auth.token,
            longLifeToken: auth.longLifeToken,
        }),
    )
    try {
        const response: AxiosResponse<RefreshTokenResponse> = yield call(
            mutations.refreshRestaurantOwnerToken,
            {
                token,
            },
        )
        if (response.data) {
            yield put(
                actions.restaurantOwnerSignIn({
                    restaurantOwner,
                    authentication: {
                        token: response.data.token,
                        longLifeToken,
                    },
                }),
            )
        }
    } catch (ignore) {
        if (longLifeToken) {
            try {
                const response: AxiosResponse<RestaurantOwnerSignInResponse> = yield call(
                    mutations.restaurantOwnerSignInByToken,
                    {
                        token: longLifeToken,
                    },
                )
                if (response.data) {
                    yield put(actions.restaurantOwnerSignIn(response.data))
                }
            } catch (ignore) {
                yield put(actions.restaurantOwnerSignOut())
            }
        } else {
            yield put(actions.restaurantOwnerSignOut())
        }
    }
}

export default sessionSaga
