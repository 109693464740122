import React from 'react'
import withAuthenticateLayout from 'containers/hoc/withAuthenticateLayout'
import { RestaurantOwnerModel, useRestaurantOwnerUpdate } from '@sugg-gestion/react-onvaauresto'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'core/store/reducers'
import { useApiErrors } from 'core/services/onvaauresto/useApiErrors'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import withRestaurantOwner from 'containers/hoc/withRestaurantOwner'
import RestaurantOwnerForm, { RestaurantOwnerUpdateFormState } from 'components/forms/restaurantOwnerForm'
import { CountryCode } from 'libphonenumber-js'
import { usePhoneNumber } from 'core/services/phone/usePhoneNumber'

interface Props {
    restaurantOwner: RestaurantOwnerModel
}

const RestaurantOwnerUpdate: React.FC<Props> = ({ restaurantOwner }) => {
    const { token, ability } = useSelector(({ onvaauresto: { auth }, app }: ApplicationState) => ({
        token: auth.token,
        ability: app.ability,
    }))
    const { restaurantOwnerUpdate, inProgress } = useRestaurantOwnerUpdate()
    const { displayError } = useApiErrors()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const history = useHistory()
    const { displayPhone } = usePhoneNumber()

    const onSubmit = (values: RestaurantOwnerUpdateFormState) => {
        if (token) {
            const updateFields = ['givenName', 'familyName', 'email', 'phone', 'country']
            if (values.password !== undefined) {
                updateFields.push('password')
            }
            if (ability.can('manage', 'establishment')) {
                updateFields.push('establishment')
            }
            return restaurantOwnerUpdate(token, restaurantOwner.id, {
                ...values,
                updateFields,
            })
                .then(() => {
                    history.push('/restaurant-owners')
                    enqueueSnackbar(t('restaurant-owner.update.success'), {
                        variant: 'success',
                    })
                })
                .catch((error) => {
                    displayError(error)
                })
        }
    }

    const initialValues: RestaurantOwnerUpdateFormState = {
        ...restaurantOwner,
        country: (restaurantOwner.country as CountryCode) ?? 'FR',
        phone: displayPhone(restaurantOwner.phone, restaurantOwner?.country as CountryCode) ?? '',
    }

    return (
        <div>
            <RestaurantOwnerForm
                inProgress={inProgress}
                onSubmit={onSubmit}
                initialValues={initialValues}
                disableEstablishment={ability.cannot('manage', 'establishment')}
            />
        </div>
    )
}

export default withAuthenticateLayout(withRestaurantOwner(RestaurantOwnerUpdate))
