import { useGroups } from '@sugg-gestion/onvaauresto-graph-api-module'
import Loader from 'components/common/loader'
import NotFound from 'components/views/main/notFound'
import { GroupModel } from 'core/model/group.model'
import React, { ComponentType } from 'react'
import { useParams } from 'react-router-dom'

interface GroupParams {
    id: string
}

const withGroup = (ComposedComponent: ComponentType<any>) => {
    const WithGroup: React.FC = ({ ...props }) => {
        const { id } = useParams<GroupParams>()
        const { data } = useGroups()

        let group = undefined
        if (data?.groups !== undefined) {
            group = data?.groups.find((group: GroupModel) => group.id === id)
        }
        if (group) {
            return <ComposedComponent group={group} {...props} />
        } else if (data?.groups === undefined) {
            return <Loader />
        } else {
            return <NotFound />
        }
    }
    return WithGroup
}

export default withGroup
