import {
    useFacebookProfiles,
    useInstalledGroups,
    useRefreshFacebookGroupInstalled,
    useRefreshFacebookProfiles,
    useRegisterFacebookProfile,
    useSharedFacebookGroups,
    useSharedFacebookPages,
    useShareFacebookGroup,
    useShareFacebookPage,
    useStopSharingFacebookGroup,
    useStopSharingFacebookPage,
    useUnlinkFacebookProfile,
} from '@sugg-gestion/social-graph-api-module'
import AlertDialog from 'components/dialogs/alertDialog'
import SharingDialog, { Profile } from 'components/dialogs/sharingDialog'
import FacebookProfilesLayout from 'components/layouts/facebookProfilesLayout'
import FacebookGroupsDataTable from 'components/views/facebook/profiles/facebookGroupsDataTable'
import FacebookPagesDataTable from 'components/views/facebook/profiles/facebookPagesDataTable'
import FacebookProfilesDataTable from 'components/views/facebook/profiles/facebookProfilesDataTable'
import withAuthenticateLayout, { WithAuthenticateLayoutProps } from 'containers/hoc/withAuthenticateLayout'
import withFacebook from 'containers/hoc/withFacebook'
import withRequiredSocialGraphApi from 'containers/hoc/withRequiredSocialGraphApi'
import { useFacebookProfile } from 'core/services/facebook/useFacebookProfile'
import { useApiErrors } from 'core/services/onvaauresto/useApiErrors'
import { ApplicationState } from 'core/store/reducers'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import InstalledGroupsDataTable from '../../components/views/facebook/profiles/installedGroupsDataTable'

interface ShareFacebookPage {
    pageId: string
    profiles: Profile[]
}

interface ShareFacebookGroup {
    groupId: string
    profiles: Profile[]
}

const FacebookProfiles: React.FC<WithAuthenticateLayoutProps> = ({ admin }) => {
    const { ability } = useSelector(({ app }: ApplicationState) => ({
        ability: app.ability,
    }))

    const { enqueueSnackbar } = useSnackbar()
    const { displayError } = useApiErrors()
    const { t } = useTranslation()

    const { data, loading } = useFacebookProfiles()
    const { data: sharedFacebookPages, loading: loadingSharedFacebookPages } = useSharedFacebookPages()
    const { data: sharedFacebookGroups, loading: loadingSharedFacebookGroups } = useSharedFacebookGroups()
    const { data: installedGroups, loading: loadingInstalledGroups } = useInstalledGroups()
    const [registerFacebookProfile, { loading: loadingRegisterFacebookProfile }] =
        useRegisterFacebookProfile()
    const [unlinkFacebookProfile, { loading: loadingUnlinkFacebookProfile }] = useUnlinkFacebookProfile()
    const [shareFacebookGroup, { loading: loadingShareFacebookGroup }] = useShareFacebookGroup()
    const [shareFacebookPage, { loading: loadingShareFacebookPage }] = useShareFacebookPage()
    const [refreshFacebookGroupInstalled, { loading: loadingRefreshFacebookGroupInstalled }] =
        useRefreshFacebookGroupInstalled()
    const [stopSharingFacebookPage, { loading: loadingStopSharingFacebookPage }] =
        useStopSharingFacebookPage()
    const [stopSharingFacebookGroup, { loading: loadingStopSharingFacebookGroup }] =
        useStopSharingFacebookGroup()
    const [refreshFacebookProfiles, { loading: loadingRefreshFacebookProfiles }] =
        useRefreshFacebookProfiles()

    const { login } = useFacebookProfile()

    const [sharePageDialog, setSharePageDialog] = useState<ShareFacebookPage>()
    const [stopSharingPageDialog, setStopSharingPageDialog] = useState<string>()

    const [shareGroupDialog, setShareGroupDialog] = useState<ShareFacebookGroup>()
    const [stopSharingGroupDialog, setStopSharingGroupDialog] = useState<string>()

    const handleRegisterFacebookProfile = async () => {
        try {
            const accessToken = await login()
            await registerFacebookProfile({ variables: { accessToken } })
            enqueueSnackbar(t('facebookProfiles.link.success'), {
                variant: 'success',
            })
        } catch (error) {
            displayError(error)
        }
    }

    const handleRefreshFacebookProfiles = async () => {
        try {
            await refreshFacebookProfiles()
            enqueueSnackbar(t('facebookProfiles.refresh.success'), {
                variant: 'success',
            })
        } catch (error) {
            displayError(error)
        }
    }

    const handleRefreshFacebookGroupInstalled = async () => {
        try {
            await refreshFacebookGroupInstalled()
            enqueueSnackbar(t('facebookProfiles.refresh.success'), {
                variant: 'success',
            })
        } catch (error) {
            displayError(error)
        }
    }

    const handleUnlinkFacebookProfile = async (id: string) => {
        try {
            await unlinkFacebookProfile({
                variables: {
                    id,
                },
            })
            enqueueSnackbar(t('facebookProfiles.unlink.success'), {
                variant: 'success',
            })
        } catch (error) {
            displayError(error)
        }
    }

    const handleSharePage = async (pageId: string, profileId: string) => {
        try {
            await shareFacebookPage({
                variables: {
                    pageId,
                    profileId,
                },
            })
            enqueueSnackbar(t('facebookProfiles.sharePage.success'), {
                variant: 'success',
            })
        } catch (error) {
            displayError(error)
        }
    }

    const handleStopSharingPage = async (sharingPageId: string) => {
        try {
            await stopSharingFacebookPage({
                variables: {
                    id: sharingPageId,
                },
            })
            enqueueSnackbar(t('facebookProfiles.shareRemovePage.success'), {
                variant: 'success',
            })
        } catch (error) {
            displayError(error)
        }
    }

    const handleShareGroup = async (groupId: string, profileId: string) => {
        try {
            await shareFacebookGroup({
                variables: {
                    groupId,
                    profileId,
                },
            })
            enqueueSnackbar(t('facebookProfiles.shareGroup.success'), {
                variant: 'success',
            })
        } catch (error) {
            displayError(error)
        }
    }

    const handleStopSharingGroup = async (sharingGroupId: string) => {
        try {
            await stopSharingFacebookGroup({
                variables: {
                    id: sharingGroupId,
                },
            })
            enqueueSnackbar(t('facebookProfiles.shareRemoveGroup.success'), {
                variant: 'success',
            })
        } catch (error) {
            displayError(error)
        }
    }

    return (
        <>
            <FacebookProfilesLayout
                sections={[
                    <FacebookProfilesDataTable
                        facebookProfiles={data?.facebookProfiles ?? []}
                        onRegisterFacebookProfile={handleRegisterFacebookProfile}
                        onRefreshFacebookProfiles={handleRefreshFacebookProfiles}
                        onUnlinkFacebookProfile={handleUnlinkFacebookProfile}
                        loadingRegisterFacebookProfile={loadingRegisterFacebookProfile}
                        loadingUnlinkFacebookProfile={loadingUnlinkFacebookProfile}
                        loadingRefreshFacebookProfiles={loadingRefreshFacebookProfiles}
                        ability={ability}
                        loading={loading}
                    />,
                    <FacebookPagesDataTable
                        facebookPages={
                            data?.facebookProfiles
                                .flatMap((profile) => profile.pages)
                                .filter((v, i, a) => a.indexOf(v) === i) ?? []
                        }
                        sharedFacebookPages={sharedFacebookPages?.sharedFacebookPages ?? []}
                        loadingSharedFacebookPages={loadingSharedFacebookPages}
                        loading={loading}
                        loadingShareFacebookPage={loadingShareFacebookPage || loadingStopSharingFacebookPage}
                        ability={ability}
                        onSharePage={(pageId, shared) => {
                            if (shared && data?.facebookProfiles) {
                                const dialogData = {
                                    pageId,
                                    profiles: data?.facebookProfiles
                                        .filter(
                                            (profile) =>
                                                profile.pages.find((page) => page.id === pageId) !==
                                                undefined,
                                        )
                                        .map((profile) => {
                                            return {
                                                id: profile.id,
                                                name: profile.name,
                                            }
                                        }),
                                }
                                setSharePageDialog(dialogData)
                            } else if (!shared && sharedFacebookPages?.sharedFacebookPages) {
                                const sharedFacebookPage = sharedFacebookPages.sharedFacebookPages.find(
                                    (sharedFacebookPage) => sharedFacebookPage.pageId === pageId,
                                )
                                if (sharedFacebookPage) {
                                    setStopSharingPageDialog(sharedFacebookPage.id)
                                }
                            }
                        }}
                    />,
                    <FacebookGroupsDataTable
                        facebookGroups={
                            data?.facebookProfiles
                                .flatMap((profile) => profile.groups)
                                .filter((v, i, a) => a.indexOf(v) === i) ?? []
                        }
                        sharedFacebookGroups={sharedFacebookGroups?.sharedFacebookGroups ?? []}
                        loadingShareFacebookGroup={
                            loadingShareFacebookGroup || loadingStopSharingFacebookGroup
                        }
                        loading={loading}
                        loadingSharedFacebookGroups={loadingSharedFacebookGroups}
                        ability={ability}
                        onShareGroup={(groupId, shared) => {
                            if (shared && data?.facebookProfiles) {
                                const dialogData = {
                                    groupId,
                                    profiles: data?.facebookProfiles
                                        .filter(
                                            (profile) =>
                                                profile.groups.find((group) => group.id === groupId) !==
                                                undefined,
                                        )
                                        .map((profile) => {
                                            return {
                                                id: profile.id,
                                                name: profile.name,
                                            }
                                        }),
                                }
                                setShareGroupDialog(dialogData)
                            } else if (!shared && sharedFacebookGroups?.sharedFacebookGroups) {
                                const sharedFacebookGroup = sharedFacebookGroups.sharedFacebookGroups.find(
                                    (sharedFacebookGroup) => sharedFacebookGroup.groupId === groupId,
                                )
                                if (sharedFacebookGroup) {
                                    setStopSharingGroupDialog(sharedFacebookGroup.id)
                                }
                            } else {
                                setStopSharingGroupDialog(groupId)
                            }
                        }}
                    />,
                    <InstalledGroupsDataTable
                        installedGroups={installedGroups?.installedGroups ?? []}
                        sharedFacebookGroups={sharedFacebookGroups?.sharedFacebookGroups ?? []}
                        loadingShareFacebookGroup={
                            loadingShareFacebookGroup || loadingStopSharingFacebookGroup
                        }
                        loading={loadingInstalledGroups}
                        loadingSharedFacebookGroups={loadingSharedFacebookGroups}
                        ability={ability}
                        onRefresh={handleRefreshFacebookGroupInstalled}
                        loadingRefresh={loadingRefreshFacebookGroupInstalled}
                        onShareGroup={(groupId, shared) => {
                            if (shared && data?.facebookProfiles) {
                                const dialogData = {
                                    groupId,
                                    profiles: data?.facebookProfiles.map((profile) => {
                                        return {
                                            id: profile.id,
                                            name: profile.name,
                                        }
                                    }),
                                }
                                setShareGroupDialog(dialogData)
                            } else if (!shared && sharedFacebookGroups?.sharedFacebookGroups) {
                                const sharedFacebookGroup = sharedFacebookGroups.sharedFacebookGroups.find(
                                    (sharedFacebookGroup) => sharedFacebookGroup.groupId === groupId,
                                )
                                if (sharedFacebookGroup) {
                                    setStopSharingGroupDialog(sharedFacebookGroup.id)
                                }
                            } else {
                                setStopSharingGroupDialog(groupId)
                            }
                        }}
                    />,
                ]}
            />

            <SharingDialog
                open={sharePageDialog !== undefined}
                title={t('facebookProfiles.sharePage.question')}
                content={t('facebookProfiles.sharePage.content')}
                buttons={{ yes: t('common.yes'), no: t('common.no') }}
                onClose={() => setSharePageDialog(undefined)}
                callback={(profileId) => {
                    if (sharePageDialog) {
                        handleSharePage(sharePageDialog.pageId, profileId).catch(() => {})
                    }
                }}
                possibilities={sharePageDialog?.profiles || []}
            />
            <AlertDialog
                open={stopSharingPageDialog !== undefined}
                title={t('facebookProfiles.shareRemovePage.question')}
                buttons={{ yes: t('common.yes'), no: t('common.no') }}
                onClose={() => setStopSharingPageDialog(undefined)}
                callback={() => {
                    if (stopSharingPageDialog) {
                        handleStopSharingPage(stopSharingPageDialog).catch(() => {})
                    }
                }}
            />

            <SharingDialog
                open={shareGroupDialog !== undefined}
                title={t('facebookProfiles.shareGroup.question')}
                content={t('facebookProfiles.shareGroup.content')}
                buttons={{ yes: t('common.yes'), no: t('common.no') }}
                onClose={() => setShareGroupDialog(undefined)}
                callback={(profileId) => {
                    if (shareGroupDialog) {
                        handleShareGroup(shareGroupDialog.groupId, profileId).catch(() => {})
                    }
                }}
                possibilities={shareGroupDialog?.profiles || []}
            />
            <AlertDialog
                open={stopSharingGroupDialog !== undefined}
                title={t('facebookProfiles.shareRemoveGroup.question')}
                buttons={{ yes: t('common.yes'), no: t('common.no') }}
                onClose={() => setStopSharingGroupDialog(undefined)}
                callback={() => {
                    if (stopSharingGroupDialog) {
                        handleStopSharingGroup(stopSharingGroupDialog).catch(() => {})
                    }
                }}
            />
        </>
    )
}

export default withAuthenticateLayout(withFacebook(withRequiredSocialGraphApi(FacebookProfiles)))
