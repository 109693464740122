import { AdminModel } from '../../models'

export enum AuthActionTypes {
    ADD_ADMIN = 'admins/ADD_ADMIN',
    SET_ADMIN = 'admins/SET_ADMIN',
    SET_ADMINS = 'admins/SET_ADMINS',
    UPDATE_ADMIN = 'admins/UPDATE_ADMIN',
    DELETE_ADMIN = 'admins/DELETE_ADMIN',
}

export interface AdminsState {
    admins?: Array<AdminModel>
}
