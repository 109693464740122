import { actions, AdminModel } from '@sugg-gestion/react-onvaauresto'
import AuthenticateLayout from 'components/layouts/authenticateLayout'
import { ApplicationState } from 'core/store/reducers'
import { useSnackbar } from 'notistack'
import React, { ComponentType } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import withAuthentication from './withAuthentication'
import withCommonGraphApi, { WithCommonGraphApiProps } from './withCommonGraphApi'

export interface WithAuthenticateLayoutProps {
    token: string
    admin: AdminModel
}

const withAuthenticateLayout = <T extends WithAuthenticateLayoutProps & WithCommonGraphApiProps>(
    ComposedComponent: ComponentType<any>,
) => {
    const WithAuthenticateLayout: React.FC<T> = ({ admin, isCommonGraphApiInitialized, ...props }) => {
        const dispatch = useDispatch()
        const { enqueueSnackbar } = useSnackbar()
        const { t } = useTranslation()
        const { ability } = useSelector(({ app }: ApplicationState) => ({
            ability: app.ability,
        }))
        const signOut = () => {
            dispatch(actions.adminSignOut())
            enqueueSnackbar(t('sign-out.success'), {
                variant: 'success',
            })
        }

        return (
            <AuthenticateLayout signOut={signOut} admin={admin} ability={ability}>
                <ComposedComponent admin={admin} {...props} />
            </AuthenticateLayout>
        )
    }
    return withAuthentication(withCommonGraphApi(WithAuthenticateLayout))
}

export default withAuthenticateLayout
