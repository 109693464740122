import {
    AdminSignInByTokenRequest,
    AdminSignInByUsernameRequest,
    AdminSignInResponse,
    RefreshTokenRequest,
    RefreshTokenResponse,
    RestaurantOwnerCheckRequest,
    RestaurantOwnerCheckResponse,
    RestaurantOwnerConfirmRequest,
    RestaurantOwnerForgottenPasswordConfirmRequest,
    RestaurantOwnerForgottenPasswordRequest,
    RestaurantOwnerResendEstablishmentCodeRequest,
    RestaurantOwnerSignInByEmailRequest,
    RestaurantOwnerSignInByTokenRequest,
    RestaurantOwnerSignInResponse,
    RestaurantOwnerSignUpRequest,
} from './types'
import { AxiosResponse } from 'axios'
import Client from '../../../core/client'

export const adminSignInByUsername = (
    parameters: AdminSignInByUsernameRequest,
): Promise<AxiosResponse<AdminSignInResponse>> => {
    const axiosInstance = Client.axiosInstance

    return axiosInstance.post('/api/admin/sign-in', parameters)
}

export const adminSignInByToken = (
    parameters: AdminSignInByTokenRequest,
): Promise<AxiosResponse<AdminSignInResponse>> => {
    const axiosInstance = Client.axiosInstance

    return axiosInstance.post('/api/admin/sign-in', parameters)
}

export const refreshAdminToken = (
    parameters: RefreshTokenRequest,
): Promise<AxiosResponse<RefreshTokenResponse>> => {
    const axiosInstance = Client.axiosInstance

    return axiosInstance.post('/api/admin/refresh', parameters, {
        headers: {
            Authorization: 'Bearer ' + parameters.token,
        },
    })
}

export const restaurantOwnerSignInByEmail = (
    parameters: RestaurantOwnerSignInByEmailRequest,
): Promise<AxiosResponse<RestaurantOwnerSignInResponse>> => {
    const axiosInstance = Client.axiosInstance

    return axiosInstance.post('/api/restaurant-owner/sign-in', parameters)
}

export const restaurantOwnerSignInByToken = (
    parameters: RestaurantOwnerSignInByTokenRequest,
): Promise<AxiosResponse<RestaurantOwnerSignInResponse>> => {
    const axiosInstance = Client.axiosInstance

    return axiosInstance.post('/api/restaurant-owner/sign-in', parameters)
}

export const refreshRestaurantOwnerToken = (
    parameters: RefreshTokenRequest,
): Promise<AxiosResponse<RefreshTokenResponse>> => {
    const axiosInstance = Client.axiosInstance

    return axiosInstance.post('/api/restaurant-owner/refresh', parameters, {
        headers: {
            Authorization: 'Bearer ' + parameters.token,
        },
    })
}

export const restaurantOwnerSignUp = (
    parameters: RestaurantOwnerSignUpRequest,
): Promise<AxiosResponse<RestaurantOwnerSignInResponse>> => {
    const axiosInstance = Client.axiosInstance

    return axiosInstance.post('/api/restaurant-owner/sign-up', parameters)
}

export const restaurantOwnerForgottenPassword = (
    parameters: RestaurantOwnerForgottenPasswordRequest,
): Promise<AxiosResponse<void>> => {
    const axiosInstance = Client.axiosInstance

    return axiosInstance.post('/api/restaurant-owner/forgotten-password', parameters)
}

export const restaurantOwnerForgottenPasswordConfirm = (
    parameters: RestaurantOwnerForgottenPasswordConfirmRequest,
): Promise<AxiosResponse<void>> => {
    const axiosInstance = Client.axiosInstance

    return axiosInstance.post('/api/restaurant-owner/forgotten-password/confirm', parameters)
}

export const restaurantOwnerConfirm = (
    token: string,
    parameters: RestaurantOwnerConfirmRequest,
): Promise<AxiosResponse<RestaurantOwnerSignInResponse>> => {
    const axiosInstance = Client.axiosInstance

    return axiosInstance.post('/api/restaurant-owner/confirm', parameters, {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    })
}

export const restaurantOwnerResend = (token: string): Promise<AxiosResponse<void>> => {
    const axiosInstance = Client.axiosInstance

    return axiosInstance.post(
        '/api/restaurant-owner/resend',
        {},
        {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        },
    )
}

export const restaurantOwnerCheck = (
    parameters: RestaurantOwnerCheckRequest,
): Promise<AxiosResponse<RestaurantOwnerCheckResponse>> => {
    const axiosInstance = Client.axiosInstance

    return axiosInstance.post('/api/restaurant-owner/check', parameters)
}

export const restaurantOwnerResendEstablishmentCode = (
    parameters: RestaurantOwnerResendEstablishmentCodeRequest,
): Promise<AxiosResponse<void>> => {
    const axiosInstance = Client.axiosInstance

    return axiosInstance.post('/api/restaurant-owner/resend-establishment-code', parameters)
}
