import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
        },
        action: {
            color: theme.palette.primary.main + ' !important',
        },
        title: {
            color: theme.palette.text.secondary,
        },
        subheader: {
            color: theme.palette.text.primary,
        },
        avatar: {
            padding: theme.spacing(0.25),
        },
        avatarImage: {
            '.MuiAvatar-circular &': {
                borderRadius: '50%',
            },
        },
    }),
)
