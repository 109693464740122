import { AdminModel, RestaurantOwnerModel } from '../../models'

export enum AuthActionTypes {
    ADMIN_SIGN_IN = 'auth/ADMIN_SIGN_IN',
    ADMIN_SIGN_OUT = 'auth/ADMIN_SIGN_OUT',
    RESTAURANT_OWNER_SIGN_IN = 'auth/RESTAURANT_OWNER_SIGN_IN',
    RESTAURANT_OWNER_SIGN_OUT = 'auth/RESTAURANT_OWNER_SIGN_OUT',
}

export interface AuthState {
    admin?: AdminModel
    restaurantOwner?: RestaurantOwnerModel
    token?: string
    longLifeToken?: string
}
