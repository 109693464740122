import { combineReducers } from 'redux'
import { AuthState } from './auth/types'
import { authReducer } from './auth/reducer'
import { SessionState } from './session/types'
import { sessionReducer } from './session/reducer'
import { AdminsState } from './admins/types'
import { adminsReducer } from './admins/reducer'
import { EstablishmentsState } from './establishments/types'
import { establishmentsReducer } from './establishments/reducer'
import { restaurantOwnersReducer } from './restaurant-owners/reducer'
import { RestaurantOwnersState } from './restaurant-owners/types'
import { GroupsState } from './groups/types'
import { groupsReducer } from './groups/reducer'

export interface OnVaAuRestoState {
    auth: AuthState
    session: SessionState
    admins: AdminsState
    establishments: EstablishmentsState
    restaurantOwners: RestaurantOwnersState
    groups: GroupsState
}

export interface ApplicationState {
    onvaauresto: OnVaAuRestoState
}

export const onvaaurestoReducers = {
    auth: authReducer,
    session: sessionReducer,
    admins: adminsReducer,
    establishments: establishmentsReducer,
    restaurantOwners: restaurantOwnersReducer,
    groups: groupsReducer,
}

export const onvaaurestoReducer = combineReducers<OnVaAuRestoState>(onvaaurestoReducers)
