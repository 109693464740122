import React, { ComponentType } from 'react'
import { useParams } from 'react-router-dom'
import Loader from 'components/common/loader'
import NotFound from 'components/views/main/notFound'
import { AdminModel } from '@sugg-gestion/react-onvaauresto'
import { useAdmins } from 'core/services/admins/useAdmins'

interface AdminParams {
    id: string
}

const withAdmin = (ComposedComponent: ComponentType<any>) => {
    const WithAdmin: React.FC = ({ ...props }) => {
        const { id } = useParams<AdminParams>()
        const { admins } = useAdmins()

        let admin = undefined
        if (admins !== undefined) {
            admin = admins.find((admin: AdminModel) => admin.id === id)
        }
        if (admin) {
            return <ComposedComponent adminUrl={admin} {...props} />
        } else if (admins === undefined) {
            return <Loader />
        } else {
            return <NotFound />
        }
    }
    return WithAdmin
}

export default withAdmin
