import React from 'react'
import { Container } from '@material-ui/core'
import { useStyles } from './containerLeft.css'

interface Props {
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
}

const ContainerLeft: React.FC<Props> = ({ maxWidth, children }) => {
    const classes = useStyles()
    return (
        <Container maxWidth={maxWidth} className={classes.container}>
            <>{children}</>
        </Container>
    )
}

export default ContainerLeft
