import authSaga, * as authSagas from './auth/sagas'
import sessionSaga, * as sessionSagas from './session/sagas'
import { all, call } from 'redux-saga/effects'
import establishmentsSaga, * as establishmentsSagas from './establishments/sagas'

export function* onvaaurestoSaga(init: boolean = true) {
    yield all([call(authSaga), call(sessionSaga, init), call(establishmentsSaga)])
}

export const onvaaurestoSagas = {
    ...authSagas,
    ...sessionSagas,
    ...establishmentsSagas,
}

export default onvaaurestoSaga
