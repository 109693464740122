import { Reducer } from 'redux'
import { AuthState } from './types'
import { getType } from 'typesafe-actions'
import actions from '../actions'
import { AdminSignInResponse, RestaurantOwnerSignInResponse } from '../../resources/auth/types'

const initialState: AuthState = {}

const reducer: Reducer<AuthState> = (state = initialState, action) => {
    switch (action.type) {
        case getType(actions.adminSignIn): {
            const response: AdminSignInResponse = action.payload
            const {
                admin,
                authentication: { token, longLifeToken },
            } = response
            return {
                ...state,
                admin,
                token,
                longLifeToken,
                restaurantOwner: undefined,
            }
        }
        case getType(actions.adminSignOut): {
            return {
                ...state,
                admin: undefined,
                token: undefined,
                longLifeToken: undefined,
            }
        }
        case getType(actions.restaurantOwnerSignIn): {
            const response: RestaurantOwnerSignInResponse = action.payload
            const {
                restaurantOwner,
                authentication: { token, longLifeToken },
            } = response
            if (longLifeToken !== undefined) {
                return {
                    ...state,
                    restaurantOwner,
                    token,
                    longLifeToken,
                    admin: undefined,
                }
            } else {
                return {
                    ...state,
                    restaurantOwner,
                    token,
                    admin: undefined,
                }
            }
        }
        case getType(actions.restaurantOwnerSignOut): {
            return {
                ...state,
                restaurantOwner: undefined,
                token: undefined,
                longLifeToken: undefined,
            }
        }
        case getType(actions.start): {
            const started = action.payload
            if (!started) {
                return initialState
            }
            return state
        }
        default:
            return state
    }
}

export { reducer as authReducer }
