import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 400,
            maxWidth: '100%',
            padding: theme.spacing(1, 1),
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        container: {
            height: '100%',
        },
        logoContainer: {
            'display': 'inline-flex',
            'marginBottom': '10%',
            'justifyContent': 'center',
            'alignItems': 'center',
            'maxHeight': '100%',
            '& > img': {
                height: '100%',
                maxWidth: '100%',
            },
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(0.5, 0.5),
            },
        },
    }),
)
