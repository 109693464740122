import React, { createRef, useEffect, useState } from 'react'
import Map from 'ol/Map'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import { fromLonLat, toLonLat } from 'ol/proj'
import { Coordinate } from 'ol/coordinate'
import { Icon, Style } from 'ol/style'
import IconAnchorUnits from 'ol/style/IconAnchorUnits'
import VectorSource from 'ol/source/Vector'
import { Vector as VectorLayer } from 'ol/layer'
import View from 'ol/View'
import olms from 'ol-mapbox-style'
import 'ol/ol.css'
import pin from 'assets/map/location.png'
import { defaults as defaultInteractions, Translate } from 'ol/interaction'
import { Collection } from 'ol'

interface Props {
    coordinate?: Coordinate
    zoomLevel?: number
    onChange?: (coordinate: Coordinate) => void
    update: number
}

const LocationMap: React.FC<Props> = ({
    coordinate = [1.7191, 46.7111],
    zoomLevel = 16,
    onChange,
    update,
}) => {
    const mapContainer = createRef<HTMLDivElement>()
    const [map, setMap] = useState<Map>()
    const [icon, setIcon] = useState<Feature<any>>()

    useEffect(() => {
        if (mapContainer.current !== null && map === undefined) {
            const iconFeature = new Feature({
                geometry: new Point(fromLonLat(coordinate)),
            })

            const iconStyle = new Style({
                zIndex: 100,
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: IconAnchorUnits.FRACTION,
                    anchorYUnits: IconAnchorUnits.PIXELS,
                    src: pin,
                }),
            })

            iconFeature.setStyle(iconStyle)

            const vectorSource = new VectorSource({
                features: [iconFeature],
            })

            const vectorLayer = new VectorLayer({
                source: vectorSource,
                zIndex: 10,
            })

            const styleJson =
                'https://api.maptiler.com/maps/streets/style.json?key=' + process.env.REACT_APP_MAPTILER_KEY
            const translateInteraction = new Translate({
                features: new Collection([iconFeature]),
            })
            const map = new Map({
                layers: [vectorLayer],
                target: mapContainer.current,
                interactions: defaultInteractions().extend([translateInteraction]),
                view: new View({
                    center: fromLonLat(coordinate),
                    zoom: zoomLevel,
                }),
            })
            translateInteraction.on('translateend', () => {
                // @ts-ignore
                const coordinate = iconFeature.getGeometry().getCoordinates()
                const lonLat = toLonLat(coordinate)
                if (onChange !== undefined) {
                    onChange(lonLat)
                }
                setIcon(iconFeature)
            })
            olms(map, styleJson)
            setIcon(iconFeature)
            setMap(map)
        }
    }, [onChange, zoomLevel, coordinate, map, mapContainer, setIcon])

    useEffect(() => {
        const dataCoordinate = fromLonLat(coordinate)
        if (map !== undefined) {
            const extent = map.getView().calculateExtent(map.getSize())
            if (
                dataCoordinate[0] < extent[0] ||
                dataCoordinate[0] > extent[2] ||
                dataCoordinate[1] < extent[1] ||
                dataCoordinate[1] > extent[3]
            ) {
                map.getView().setCenter(dataCoordinate)
            }
        }
        if (icon !== undefined) {
            const iconGeo = icon.getGeometry()
            // @ts-ignore
            const coordinate = iconGeo.getCoordinates()
            if (
                Math.abs(coordinate[0] - dataCoordinate[0]) > 0.00000001 ||
                Math.abs(coordinate[1] - dataCoordinate[1]) > 0.00000001
            ) {
                // @ts-ignore
                iconGeo.setCoordinates(dataCoordinate)
            }
        }
    }, [coordinate, map, icon])

    useEffect(() => {
        if (map !== undefined) {
            map.updateSize()
        }
    }, [map, update])
    return <div style={{ height: '100%', width: '100%' }} ref={mapContainer} />
}

export default LocationMap
