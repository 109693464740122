import React from 'react'
import withAuthenticateLayout from 'containers/hoc/withAuthenticateLayout'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'core/store/reducers'
import { useHistory } from 'react-router-dom'
import { useRestaurantOwnerDelete, useRestaurantOwnersExport } from '@sugg-gestion/react-onvaauresto'
import { useApiErrors } from 'core/services/onvaauresto/useApiErrors'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useRestaurantOwners } from 'core/services/restaurantOwners/useRestaurantOwners'
import RestaurantOwnersDataTable from 'components/views/restaurantOwners/restaurantOwnersDataTable'
import { CardActions, IconButton } from '@material-ui/core'
import DownloadButton from 'components/common/downloadButton'
import CloseIcon from '@material-ui/icons/Close'

const RestaurantOwners: React.FC = () => {
    const { restaurantOwners, inProgress, refresh } = useRestaurantOwners()
    const { ability, token } = useSelector(({ app, onvaauresto: { auth } }: ApplicationState) => ({
        ability: app.ability,
        token: auth.token,
    }))
    const history = useHistory()
    const { restaurantOwnerDelete, inProgress: restaurantOwnerDeleteInProgress } = useRestaurantOwnerDelete()
    const { restaurantOwnersExport, inProgress: restaurantOwnerExportsInProgress } =
        useRestaurantOwnersExport()
    const { displayError } = useApiErrors()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const onEditRestaurantOwner = (id: string) => {
        history.push('/restaurant-owner/:id'.replace(':id', id))
    }
    const onEditEstablishment = (id: string) => {
        history.push('/establishment/:id'.replace(':id', id))
    }

    const onDeleteRestaurantOwner = (id: string) => {
        if (token) {
            restaurantOwnerDelete(token, id)
                .then(() => {
                    enqueueSnackbar(t('admin.delete.success'), {
                        variant: 'success',
                    })
                })
                .catch((error) => {
                    displayError(error)
                })
        }
    }

    const onExportRestaurantOwners = () => {
        if (token) {
            restaurantOwnersExport(token)
                .then((response) => {
                    enqueueSnackbar(t('restaurant-owner.export.success'), {
                        variant: 'success',
                        persist: true,
                        key: 'restaurantOwnersExport',
                        action: (
                            <CardActions>
                                <DownloadButton url={response.url} />
                                <IconButton
                                    color="inherit"
                                    onClick={() => closeSnackbar('restaurantOwnersExport')}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </CardActions>
                        ),
                    })
                })
                .catch((error) => {
                    displayError(error)
                })
        }
    }

    const onRefreshRestaurantOwners = () => {
        refresh()
    }

    return (
        <RestaurantOwnersDataTable
            restaurantOwners={restaurantOwners ?? []}
            onEditEstablishment={onEditEstablishment}
            onEditRestaurantOwner={onEditRestaurantOwner}
            onExportRestaurantOwners={onExportRestaurantOwners}
            onDeleteRestaurantOwner={onDeleteRestaurantOwner}
            onRefreshRestaurantOwners={onRefreshRestaurantOwners}
            ability={ability}
            loading={inProgress || restaurantOwnerDeleteInProgress}
            exportInProgress={restaurantOwnerExportsInProgress}
        />
    )
}

export default withAuthenticateLayout(RestaurantOwners)
