import { NotificationModel } from 'core/model/notification.model'

export enum CommonActionTypes {
    SET_LOADING = '@@common/SET_LOADING',
    SET_NOTIFICATIONS = '@@common/SET_NOTIFICATIONS',
}

export interface CommonState {
    loading: boolean
    notifications: NotificationModel[]
}
