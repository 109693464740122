import { useState } from 'react'
import { mutations } from '../../resources'
import { generateError } from '../error'
import { EstablishmentsExportResponse } from '../../resources/establishments/types'

export const useEstablishmentsExport = () => {
    const [inProgress, setInProgress] = useState(false)

    const establishmentsExport = (token: string): Promise<EstablishmentsExportResponse> => {
        setInProgress(true)
        return mutations
            .establishmentsExport(token)
            .then(({ data }) => {
                if (data) {
                    setInProgress(false)
                    return data
                }
                setInProgress(false)
                throw generateError()
            })
            .catch((error) => {
                setInProgress(false)
                throw generateError(error)
            })
    }

    return {
        establishmentsExport,
        inProgress,
    }
}
