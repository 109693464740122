import React, { ComponentType } from 'react'
import Loader from 'components/common/loader'
import useFacebookAuth from 'core/services/facebook/useFacebookAuth'

const withFacebook = (ComposedComponent: ComponentType<any>, loader: React.ReactNode = <Loader />) => {
    const WithFacebook: React.FC<any> = ({ ...props }) => {
        const isInitialized = useFacebookAuth()
        if (isInitialized) {
            return <ComposedComponent {...props} />
        } else {
            return <>{loader}</>
        }
    }
    return WithFacebook
}

export default withFacebook
