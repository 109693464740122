import axios, { AxiosInstance } from 'axios'
import { ClientOptions } from './options'
import { StorageInterface } from './storage'

const Client = class {
    static axiosInstance: AxiosInstance
    static sessionStorage: StorageInterface
    static persistentStorage: StorageInterface

    static init(options: ClientOptions) {
        this.axiosInstance = axios.create({
            baseURL: options.apiEndpoint,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        this.sessionStorage = options.sessionStorage
        this.persistentStorage = options.persistentStorage
    }

    static getAxiosInstance(): AxiosInstance {
        if (!this.axiosInstance) {
            throw Error('Provider must be used')
        }
        return this.axiosInstance
    }

    static getSessionStorage(): StorageInterface {
        if (!this.sessionStorage) {
            throw Error('Provider must be used')
        }
        return this.sessionStorage
    }

    static getPersistentStorage(): StorageInterface {
        if (!this.persistentStorage) {
            throw Error('Provider must be used')
        }
        return this.persistentStorage
    }
}

export default Client
