import { AnyAbility } from '@casl/ability'
import { Can } from '@casl/react'
import { Button, Typography } from '@material-ui/core'
import { DataGrid, GridColDef, GridValueFormatterParams } from '@material-ui/data-grid'
import AddIcon from '@material-ui/icons/Add'
import { AdminModel, PermissionModel } from '@sugg-gestion/react-onvaauresto'
import clsx from 'clsx'
import AlertDialog from 'components/dialogs/alertDialog'
import { useGridTranslation } from 'core/services/grid/useGidTranslation'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { usePermissions } from '../../forms/helpers/usePermissions'
import { useStyles } from './adminsDataTable.css'

interface Props {
    admins: Array<AdminModel>
    onAddAdmin: () => void
    onEditAdmin: (id: string) => void
    onDeleteAdmin: (id: string) => void
    ability: AnyAbility
    loading: boolean
}

const AdminsDataTable: React.FC<Props> = ({
    admins,
    onAddAdmin,
    onEditAdmin,
    onDeleteAdmin,
    ability,
    loading,
}) => {
    const classes = useStyles()
    const [deleteDialog, setDeleteDialog] = useState<string>()
    const { t } = useTranslation()
    const gridTranslation = useGridTranslation()
    const { permissionsBySubjects: permissionsBySubjectsForAll } = usePermissions('*')
    const { permissionsBySubjects: permissionsBySubjectsForMe } = usePermissions('me')
    const [pageSize, setPageSize] = useState(50)

    const deleteAdmin = (admin: AdminModel) => {
        setDeleteDialog(admin.id)
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: t('common.id'), width: 300, sortable: false },
        { field: 'username', headerName: t('common.username'), width: 200, sortable: false },
        {
            field: 'permissions',
            filterable: false,
            sortable: false,
            headerName: t('common.permissions'),
            width: 440,
            renderCell: ({ value }) => {
                const permissions = value as Array<PermissionModel>
                const subjectsForAll = permissionsBySubjectsForAll(permissions)
                let permissionsTextsForAll = []
                for (const subjectForAll in subjectsForAll) {
                    if (subjectsForAll.hasOwnProperty(subjectForAll)) {
                        const { actions } = subjectsForAll[subjectForAll]
                        const actionsTexts: Array<string> = []
                        actions.forEach((action, index) => {
                            if (index > 0) {
                                actionsTexts.push(t('permissions.actions.' + action).toLowerCase())
                            } else {
                                actionsTexts.push(t('permissions.actions.' + action))
                            }
                        })
                        permissionsTextsForAll.push(
                            t('permissions.display', {
                                action: actionsTexts.join(', '),
                                subject: t('permissions.subjects.' + subjectForAll),
                            }),
                        )
                    }
                }
                const subjectsForMe = permissionsBySubjectsForMe(permissions)
                let permissionsTextsForMe = []
                for (const subjectForMe in subjectsForMe) {
                    if (subjectsForMe.hasOwnProperty(subjectForMe)) {
                        const { actions } = subjectsForMe[subjectForMe]
                        const actionsTexts: Array<string> = []
                        actions.forEach((action, index) => {
                            if (index > 0) {
                                actionsTexts.push(t('permissions.actions.' + action).toLowerCase())
                            } else {
                                actionsTexts.push(t('permissions.actions.' + action))
                            }
                        })
                        permissionsTextsForMe.push(
                            t('permissions.display', {
                                action: actionsTexts.join(', '),
                                subject: t('permissions.subjects.' + subjectForMe),
                            }),
                        )
                    }
                }

                let permissionsText = permissionsTextsForAll.join('\n') + permissionsTextsForMe.join('\n')
                return (
                    <Typography className={classes.address} title={permissionsText}>
                        {permissionsTextsForAll.length > 0 && (
                            <>
                                <Trans i18nKey="form.permissionsOnAll" />
                                <br />
                                {permissionsTextsForAll.map((permissionText, index) => (
                                    <React.Fragment key={'permission-' + index}>
                                        {permissionText}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </>
                        )}
                        {permissionsTextsForMe.length > 0 && (
                            <>
                                <Trans i18nKey="form.permissionsOnMe" />
                                <br />
                                {permissionsTextsForMe.map((permissionText, index) => (
                                    <React.Fragment key={'permission-' + index}>
                                        {permissionText}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </>
                        )}
                    </Typography>
                )
            },
        },
        {
            field: 'actions',
            filterable: false,
            sortable: false,
            headerName: t('common.actions'),
            width: 300,
            renderCell: (params: GridValueFormatterParams) => {
                const admin = params.row as AdminModel
                return (
                    <div className={classes.actions}>
                        <Can ability={ability} I="update" a="admin">
                            <Button onClick={() => onEditAdmin(admin.id)} variant="outlined" size="small">
                                <Trans i18nKey="actions.edit" />
                            </Button>
                        </Can>

                        <Can ability={ability} I="delete" a="admin">
                            <Button
                                onClick={() => deleteAdmin(admin)}
                                variant="outlined"
                                color="inherit"
                                size="small"
                                className={classes.delete}
                            >
                                <Trans i18nKey="actions.delete" />
                            </Button>
                        </Can>
                    </div>
                )
            },
        },
    ]

    return (
        <div className={classes.root}>
            <Can ability={ability} I="create" a="admin">
                <div className={clsx(classes.header, classes.actions)}>
                    <Button onClick={onAddAdmin} variant="contained" color="primary" startIcon={<AddIcon />}>
                        <Trans i18nKey="actions.addAdmin" />
                    </Button>
                </div>
            </Can>
            <div className={classes.tableContainer}>
                <DataGrid
                    rows={admins}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(pageSize) => setPageSize(pageSize)}
                    rowsPerPageOptions={[10, 50, 100, 1000]}
                    loading={loading}
                    localeText={gridTranslation}
                    rowHeight={160}
                    disableSelectionOnClick
                />
            </div>
            <AlertDialog
                open={deleteDialog !== undefined}
                title={t('admin.delete.question')}
                buttons={{ yes: t('common.yes'), no: t('common.no') }}
                onClose={() => setDeleteDialog(undefined)}
                callback={() => {
                    if (deleteDialog) {
                        onDeleteAdmin(deleteDialog)
                    }
                }}
            />
        </div>
    )
}

export default AdminsDataTable
