import { CardActions, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import {
    useEstablishmentDelete,
    useEstablishmentsExport,
    useEstablishmentSharePhoto,
    useEstablishmentUpdate,
} from '@sugg-gestion/react-onvaauresto'
import DownloadButton from 'components/common/downloadButton'
import EstablishmentsDataTable from 'components/views/establishments/establishmentsDataTable'
import withAuthenticateLayout from 'containers/hoc/withAuthenticateLayout'
import { useEstablishments } from 'core/services/establishments/useEstablishments'
import { useApiErrors } from 'core/services/onvaauresto/useApiErrors'
import { ApplicationState } from 'core/store/reducers'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const Establishments: React.FC = () => {
    const { establishments, inProgress, refresh } = useEstablishments()
    const { ability, token } = useSelector(({ app, onvaauresto: { auth } }: ApplicationState) => ({
        ability: app.ability,
        token: auth.token,
    }))
    const history = useHistory()
    const { establishmentDelete, inProgress: establishmentDeleteInProgress } = useEstablishmentDelete()
    const { establishmentsExport, inProgress: establishmentsExportInProgress } = useEstablishmentsExport()
    const { establishmentUpdate, inProgress: establishmentUpdateInProgress } = useEstablishmentUpdate()
    const { establishmentSharePhoto, inProgress: establishmentSharePhotoInProgress } =
        useEstablishmentSharePhoto()
    const { displayError } = useApiErrors()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const onEditEstablishment = (id: string) => {
        history.push('/establishment/:id'.replace(':id', id))
    }

    const onEditRestaurantOwner = (id: string) => {
        history.push('/restaurant-owner/:id'.replace(':id', id))
    }

    const onAddEstablishment = () => {
        history.push('/establishment/add')
    }

    const onExportEstablishments = () => {
        if (token) {
            establishmentsExport(token)
                .then((response) => {
                    enqueueSnackbar(t('establishment.export.success'), {
                        variant: 'success',
                        persist: true,
                        key: 'establishmentsExport',
                        action: (
                            <CardActions>
                                <DownloadButton url={response.url} />
                                <IconButton
                                    color="inherit"
                                    onClick={() => closeSnackbar('establishmentsExport')}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </CardActions>
                        ),
                    })
                })
                .catch((error) => {
                    displayError(error)
                })
        }
    }

    const onDeleteEstablishment = (id: string) => {
        if (token) {
            establishmentDelete(token, id)
                .then(() => {
                    enqueueSnackbar(t('establishment.delete.success'), {
                        variant: 'success',
                    })
                })
                .catch((error) => {
                    displayError(error)
                })
        }
    }

    const onConfirmEstablishment = (id: string) => {
        if (token) {
            establishmentUpdate(token, id, {
                isDisabled: false,
                updateFields: ['isDisabled'],
            })
                .then((establishment) => {
                    enqueueSnackbar(t('establishment.enable.success'), {
                        variant: 'success',
                    })
                    if (establishment.photo) {
                        return establishmentSharePhoto(token, id).then(() => {
                            enqueueSnackbar(t('establishment.share.success'), {
                                variant: 'success',
                            })
                        })
                    }
                })
                .catch((error) => {
                    displayError(error)
                })
        }
    }

    const onRefreshEstablishments = () => {
        refresh()
    }

    return (
        <EstablishmentsDataTable
            establishments={[...(establishments ?? [])]}
            onAddEstablishment={onAddEstablishment}
            onExportEstablishments={onExportEstablishments}
            onDeleteEstablishment={onDeleteEstablishment}
            onEditEstablishment={onEditEstablishment}
            onConfirmEstablishment={onConfirmEstablishment}
            onEditRestaurantOwner={onEditRestaurantOwner}
            onRefreshEstablishments={onRefreshEstablishments}
            ability={ability}
            loading={
                inProgress ||
                establishmentDeleteInProgress ||
                establishmentUpdateInProgress ||
                establishmentSharePhotoInProgress
            }
            exportInProgress={establishmentsExportInProgress}
        />
    )
}

export default withAuthenticateLayout(Establishments)
