import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import React, { ComponentType } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../core/store/reducers'

const withMuiPickersUtilsProvider = <T,>(ComposedComponent: ComponentType<T>) => {
    const WithMuiPickersUtilsProvider: React.FC<T> = (props) => {
        const { locale } = useSelector(({ app }: ApplicationState) => ({
            ...app,
        }))
        return (
            <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
                <ComposedComponent {...props} />
            </MuiPickersUtilsProvider>
        )
    }
    return WithMuiPickersUtilsProvider
}

export default withMuiPickersUtilsProvider
