import { createAction } from 'typesafe-actions'
import { prefix } from '../../config'
import { GroupsActionTypes } from './types'
import { GroupFacebookModel } from '../../models'

const actionPrefix = '@' + prefix + '/'

export const setGroups = createAction(actionPrefix + GroupsActionTypes.SET_GROUPS)<
    Array<GroupFacebookModel>
>()
