import React from 'react'
import logoOvar from 'assets/main/ovar.png'
import { useStyles } from './logo.css'
import { Grid, GridSpacing } from '@material-ui/core'
import clsx from 'clsx'

interface Props {
    className?: string
    spacing?: GridSpacing
}

const Logo: React.FC<Props> = ({ className, spacing = 2 }) => {
    const classes = useStyles()
    return (
        <div className={clsx(classes.root, className)}>
            <Grid
                container
                spacing={spacing}
                classes={{
                    root: classes.container,
                }}
            >
                <Grid
                    item
                    classes={{
                        root: classes.logoContainer,
                    }}
                    xs={12}
                >
                    <img src={logoOvar} alt="On va au resto" />
                </Grid>
            </Grid>
        </div>
    )
}

export default Logo
