import { AnyAbility } from '@casl/ability'
import { Can } from '@casl/react'
import { Button } from '@material-ui/core'
import { DataGrid, GridColDef, GridSortModel, GridValueFormatterParams } from '@material-ui/data-grid'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import GetAppIcon from '@material-ui/icons/GetApp'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import RefreshIcon from '@material-ui/icons/Refresh'
import { AdminModel, RestaurantOwnerModel } from '@sugg-gestion/react-onvaauresto'
import clsx from 'clsx'
import SubmitButton from 'components/common/submitButton'
import AlertDialog from 'components/dialogs/alertDialog'
import { useEstablishment } from 'core/services/establishments/useEstablishment'
import { useGridTranslation } from 'core/services/grid/useGidTranslation'
import { useWebsite } from 'core/services/webssite/useWebsite'
import _ from 'lodash'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import React, { memo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { usePhoneNumber } from '../../../core/services/phone/usePhoneNumber'
import { useStyles } from './restaurantOwnersDataTable.css'

interface Props {
    restaurantOwners: Array<RestaurantOwnerModel>
    onEditRestaurantOwner: (id: string) => void
    onEditEstablishment: (id: string) => void
    onDeleteRestaurantOwner: (id: string) => void
    onExportRestaurantOwners: () => void
    onRefreshRestaurantOwners: () => void
    ability: AnyAbility
    loading: boolean
    exportInProgress: boolean
}

const RestaurantOwnersDataTable: React.FC<Props> = ({
    restaurantOwners,
    onEditRestaurantOwner,
    onEditEstablishment,
    onDeleteRestaurantOwner,
    onExportRestaurantOwners,
    onRefreshRestaurantOwners,
    ability,
    loading,
    exportInProgress,
}) => {
    const classes = useStyles()
    const [deleteDialog, setDeleteDialog] = useState<string>()
    const { getEstablishment } = useEstablishment()
    const { displayPhone } = usePhoneNumber()
    const { getUrl } = useWebsite()
    const { t } = useTranslation()
    const gridTranslation = useGridTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const [pageSize, setPageSize] = useState(50)

    const deleteAdmin = (admin: AdminModel) => {
        setDeleteDialog(admin.id)
    }

    const handleOpenEstablishment = (id: string) => {
        return getEstablishment(id)
            .then((establishment) => {
                if (establishment) {
                    const a = document.createElement('a')
                    a.href = getUrl(establishment)
                    a.target = '_blank'
                    a.rel = 'noopener'
                    a.click()
                }
            })
            .catch((reason) => {
                if (reason === 'DISABLED') {
                    enqueueSnackbar(t('error.disabled'), {
                        variant: 'warning',
                    })
                } else if (reason === 'NOT_EXIST') {
                    enqueueSnackbar(t('error.notExist'), {
                        variant: 'error',
                    })
                }
            })
    }

    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
            field: 'createdAt',
            sort: 'desc',
        },
    ])

    const handleUpdateSortModel = (model: GridSortModel) => {
        if (!_.isEqual(model, sortModel)) {
            setSortModel(model)
        }
    }

    const columns: GridColDef[] = [
        {
            field: 'createdAt',
            headerName: t('common.createdAt'),
            width: 100,
            type: 'dateTime',
            renderCell: ({ value }) => {
                const date = moment(value as string)
                return <Trans i18nKey="common.date" values={{ date: date.toDate() }} />
            },
        },
        {
            field: 'updatedAt',
            headerName: t('common.updatedAt'),
            width: 100,
            type: 'dateTime',
            renderCell: ({ value }) => {
                if (value) {
                    const date = moment(value as string)
                    return <Trans i18nKey="common.date" values={{ date: date.toDate() }} />
                } else {
                    return <React.Fragment />
                }
            },
        },
        { field: 'id', headerName: t('common.id'), width: 300, sortable: false },
        { field: 'email', headerName: t('common.email'), width: 250 },
        { field: 'givenName', headerName: t('common.givenName'), width: 140 },
        { field: 'familyName', headerName: t('common.familyName'), width: 140 },
        {
            field: 'phone',
            headerName: t('common.phone'),
            width: 140,
            valueFormatter: ({ value, row }) => {
                return displayPhone(value as string, row?.country)
            },
            sortable: false,
        },
        {
            field: 'establishment',
            filterable: false,
            sortable: false,
            headerName: t('common.establishment'),
            width: 180,
            renderCell: ({ value }) => {
                const establishmentId = value as string
                if (establishmentId) {
                    return (
                        <div className={classes.actions}>
                            <Can ability={ability} I="update" a="establishment">
                                <Button
                                    onClick={() => onEditEstablishment(establishmentId)}
                                    variant="outlined"
                                    size="small"
                                >
                                    <Trans i18nKey="actions.edit" />
                                </Button>
                            </Can>
                            <Can not ability={ability} I="update" a="establishment">
                                <div className={classes.restaurantOwner}>
                                    <CheckBoxIcon color="primary" />
                                </div>
                            </Can>
                            <Button
                                variant="outlined"
                                size="small"
                                endIcon={<OpenInNewIcon />}
                                onClick={() => handleOpenEstablishment(establishmentId)}
                            >
                                <Trans i18nKey="actions.check" />
                            </Button>
                        </div>
                    )
                } else {
                    return <></>
                }
            },
        },
        {
            field: 'actions',
            filterable: false,
            sortable: false,
            headerName: t('common.actions'),
            width: 300,
            renderCell: (params: GridValueFormatterParams) => {
                const admin = params.row as AdminModel
                return (
                    <div className={classes.actions}>
                        <Can ability={ability} I="update" a="restaurant-owner">
                            <Button
                                onClick={() => onEditRestaurantOwner(admin.id)}
                                variant="outlined"
                                size="small"
                            >
                                <Trans i18nKey="actions.edit" />
                            </Button>
                        </Can>

                        <Can ability={ability} I="delete" a="restaurant-owner">
                            <Button
                                onClick={() => deleteAdmin(admin)}
                                variant="outlined"
                                color="inherit"
                                size="small"
                                className={classes.delete}
                            >
                                <Trans i18nKey="actions.delete" />
                            </Button>
                        </Can>
                    </div>
                )
            },
        },
    ]

    return (
        <div className={classes.root}>
            <div className={clsx(classes.header, classes.actions)}>
                <SubmitButton
                    loading={loading}
                    onClick={onRefreshRestaurantOwners}
                    variant="outlined"
                    startIcon={<RefreshIcon />}
                >
                    <Trans i18nKey="actions.refresh" />
                </SubmitButton>
                <Can ability={ability} I="export" a="restaurant-owner">
                    <SubmitButton
                        loading={exportInProgress}
                        onClick={onExportRestaurantOwners}
                        variant="outlined"
                        startIcon={<GetAppIcon />}
                    >
                        <Trans i18nKey="actions.exportRestaurantOwner" />
                    </SubmitButton>
                </Can>
            </div>
            <div className={classes.tableContainer}>
                <DataGrid
                    className={classes.grid}
                    rows={restaurantOwners}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(pageSize) => setPageSize(pageSize)}
                    rowsPerPageOptions={[10, 50, 100, 1000]}
                    loading={loading}
                    localeText={gridTranslation}
                    disableSelectionOnClick
                    sortModel={sortModel}
                    onSortModelChange={handleUpdateSortModel}
                />
            </div>
            <AlertDialog
                open={deleteDialog !== undefined}
                title={t('admin.delete.question')}
                buttons={{ yes: t('common.yes'), no: t('common.no') }}
                onClose={() => setDeleteDialog(undefined)}
                callback={() => {
                    if (deleteDialog) {
                        onDeleteRestaurantOwner(deleteDialog)
                    }
                }}
            />
        </div>
    )
}

export default memo(RestaurantOwnersDataTable)
