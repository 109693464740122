import * as authActions from './auth/actions'
import * as sessionActions from './session/actions'
import * as adminsActions from './admins/actions'
import * as establishmentsActions from './establishments/actions'
import * as restaurantOwnersActions from './restaurant-owners/actions'
import * as commonActions from './common/actions'
import * as groupsActions from './groups/actions'

export const actions = {
    ...authActions,
    ...sessionActions,
    ...adminsActions,
    ...establishmentsActions,
    ...restaurantOwnersActions,
    ...commonActions,
    ...groupsActions,
}

export default actions
