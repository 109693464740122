import { applyMiddleware, compose, Middleware } from 'redux'
import loggerMiddleware from 'redux-logger'
import { routerMiddleware } from 'connected-react-router'
import { History } from 'history'

export const createMiddleware = (history: History, sagaMiddleware: Middleware) => {
    let middlewares: Array<Middleware> = []
    middlewares = [...middlewares, routerMiddleware(history)]
    middlewares = [...middlewares, sagaMiddleware]
    if (process.env.REACT_APP_LOGGER === 'on') {
        middlewares = [...middlewares, loggerMiddleware]
    }
    return compose(applyMiddleware(...middlewares))
}
