import React, { ComponentType } from 'react'
import { useParams } from 'react-router-dom'
import Loader from 'components/common/loader'
import NotFound from 'components/views/main/notFound'
import { EstablishmentModel } from '@sugg-gestion/react-onvaauresto'
import { useEstablishments } from '../../core/services/establishments/useEstablishments'

interface EstablishmentParams {
    id: string
}

const withEstablishment = (ComposedComponent: ComponentType<any>) => {
    const WithEstablishment: React.FC = ({ ...props }) => {
        const { id } = useParams<EstablishmentParams>()
        const { establishments } = useEstablishments()

        let establishment = undefined
        if (establishments !== undefined) {
            establishment = establishments.find(
                (establishment: EstablishmentModel) => establishment.id === id,
            )
        }
        if (establishment) {
            return <ComposedComponent establishment={establishment} {...props} />
        } else if (establishments === undefined) {
            return <Loader />
        } else {
            return <NotFound />
        }
    }
    return WithEstablishment
}

export default withEstablishment
