import { Grid } from '@material-ui/core'
import React from 'react'
import { useStyles } from './facebookProfilesLayout.css'

interface Props {
    sections: React.ReactNode[]
}

const FacebookProfilesLayout: React.FC<Props> = ({ sections }) => {
    const classes = useStyles()
    return (
        <Grid container spacing={4}>
            {sections.map((section, index) => (
                <Grid key={index} item xs={12} className={classes.section}>
                    {section}
                </Grid>
            ))}
        </Grid>
    )
}

export default FacebookProfilesLayout
