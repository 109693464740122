import { FormControl, FormHelperText, InputLabel, MenuItem, PropTypes } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import _ from 'lodash'
import React from 'react'
import { Field } from 'react-final-form'

export interface SelectValue<T> {
    label: React.ReactNode
    value: T
}

interface Props<T> {
    name: string
    label?: string | React.ReactNode
    possibilities: SelectValue<T>[]
    required?: boolean
    autoComplete?: string
    variant?: 'standard' | 'outlined' | 'filled'
    fullWidth?: boolean
    margin?: PropTypes.Margin
}

const SelectField = <T,>({
    name,
    label,
    possibilities,
    required,
    autoComplete,
    variant = 'outlined',
    fullWidth,
    margin = 'normal',
}: Props<T>) => {
    return (
        <Field<T> name={name} type="select" isEqual={_.isEqual}>
            {({ input: { name, onChange, value }, meta }) => {
                const hasError =
                    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched
                return (
                    <FormControl
                        component="fieldset"
                        fullWidth
                        margin={margin}
                        required={required}
                        error={hasError}
                    >
                        {label !== undefined && <InputLabel shrink>{label}</InputLabel>}
                        <Select
                            id={name}
                            name={name}
                            value={JSON.stringify(value)}
                            variant={variant}
                            onChange={(e) => {
                                return onChange(JSON.parse(e.target.value as string))
                            }}
                            required={required}
                            fullWidth={fullWidth}
                            autoComplete={autoComplete}
                        >
                            <MenuItem value='""'></MenuItem>
                            {possibilities.map((possibility, index) => (
                                <MenuItem value={JSON.stringify(possibility.value)} key={index}>
                                    {possibility.label}
                                </MenuItem>
                            ))}
                        </Select>
                        {hasError && <FormHelperText error={hasError}>{meta.error}</FormHelperText>}
                    </FormControl>
                )
            }}
        </Field>
    )
}

export default SelectField
