import React from 'react'
import withAuthenticateLayout from 'containers/hoc/withAuthenticateLayout'
import EstablishmentForm, { EstablishmentFormState } from 'components/forms/establishmentForm'
import { useEstablishmentCreate, useEstablishmentSharePhoto } from '@sugg-gestion/react-onvaauresto'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'core/store/reducers'
import { useApiErrors } from 'core/services/onvaauresto/useApiErrors'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useEstablishmentGroups } from '../../core/services/groups/useEstablishmentGroups'

const EstablishmentCreate: React.FC = () => {
    const { ability, token } = useSelector(({ app, onvaauresto: { auth } }: ApplicationState) => ({
        ability: app.ability,
        token: auth.token,
    }))
    const { establishmentCreate, inProgress } = useEstablishmentCreate()
    const { establishmentSharePhoto, inProgress: establishmentSharePhotoInProgress } =
        useEstablishmentSharePhoto()
    const { displayError } = useApiErrors()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const history = useHistory()
    const { getEstablishmentGroups, inProgress: groupsInProgress } = useEstablishmentGroups()

    const shareOnFacebook = (token: string, id: string, isFacebookShared: boolean) => {
        if (isFacebookShared) {
            return establishmentSharePhoto(token, id).then(() => true)
        } else {
            return new Promise<boolean>((resolve) => resolve(false))
        }
    }
    const onSubmit = (values: EstablishmentFormState) => {
        if (token) {
            return establishmentCreate(token, {
                ...values,
                image: values.image?.file,
            })
                .then((response) => {
                    enqueueSnackbar(t('establishment.create.success'), {
                        variant: 'success',
                    })
                    return response
                })
                .then((response) => {
                    if ('establishment' in response) {
                        return shareOnFacebook(token, response.establishment.id, values.isFacebookShared)
                    } else {
                        return shareOnFacebook(token, response.id, values.isFacebookShared)
                    }
                })
                .then((sharedOnFacebook) => {
                    if (sharedOnFacebook) {
                        enqueueSnackbar(t('establishment.share.success'), {
                            variant: 'success',
                        })
                    }
                    history.push('/establishments')
                })
                .catch((error) => {
                    displayError(error)
                })
        }
    }

    return (
        <div>
            <EstablishmentForm
                disableExtraField={true}
                inProgress={inProgress || establishmentSharePhotoInProgress}
                onSubmit={onSubmit}
                initialValues={{
                    name: '',
                    address: {
                        line1: '',
                        zipCode: '',
                        city: '',
                        country: 'FR',
                    },
                    location: {
                        latitude: 0,
                        longitude: 0,
                    },
                    isFacebookShared: ability.can('share', 'establishment'),
                    remplisVert: false,
                }}
                isPhotoShared={false}
                showPhotoShare={ability.can('share', 'establishment')}
                getEstablishmentGroups={getEstablishmentGroups}
                groupsInProgress={groupsInProgress}
            />
        </div>
    )
}

export default withAuthenticateLayout(EstablishmentCreate)
