import { AxiosResponse } from 'axios'
import Client from '../../client'
import { RestaurantOwnerModel } from '../../models'

export const restaurantOwnersList = (token?: string): Promise<AxiosResponse<Array<RestaurantOwnerModel>>> => {
    const axiosInstance = Client.axiosInstance

    return axiosInstance.get(
        '/api/restaurant-owners/',
        token
            ? {
                  headers: {
                      Authorization: 'Bearer ' + token,
                  },
              }
            : {},
    )
}

export const restaurantOwnerGet = (
    token: string,
    id: string,
): Promise<AxiosResponse<RestaurantOwnerModel>> => {
    const axiosInstance = Client.axiosInstance

    const url = '/api/restaurant-owner/:id'.replace(':id', id)
    return axiosInstance.get(url, {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    })
}
