import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useFormsStyles = makeStyles((theme: Theme) =>
    createStyles({
        form: {
            display: 'contents',
        },
        actions: {
            'display': 'flex',
            'justifyContent': 'flex-end',
            'alignItems': 'center',
            'margin': theme.spacing(6, -1, 0, -1),
            '& a, & button': {
                margin: theme.spacing(0, 1),
            },
        },
        submit: {
            width: 200,
        },
        or: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
        },
        orDivider: {
            flexGrow: 1,
            backgroundColor: theme.palette.text.primary,
        },
        photoField: {
            height: '100% !important',
        },
        photoFieldContainer: {
            width: '100%',
            height: '100%',
        },
        photoFieldCard: {
            border: '1px solid #EBE6EF',
        },
        photoFieldCardImage: {
            marginTop: theme.spacing(2),
        },
        photoFieldCardWithoutImage: {
            border: '1px solid #F38355',
        },
        photoFieldCardMedia: {
            height: 0,
            paddingTop: '133%',
            backgroundSize: 'contain',
            position: 'relative',
        },
        photoFieldAddContainer: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        photoFieldAdd: {
            fontSize: '6rem',
        },
        helper: {
            fontStyle: 'italic',
            fontSize: '1rem',
            fontWeight: theme.fontsWeight.light,
        },
        updatePassword: {
            marginTop: theme.spacing(3),
        },
        shareIcon: {
            justifyContent: 'center',
        },
        facebookIcon: {
            color: theme.socialColors.facebook,
        },
        disabled: {
            opacity: 0.5,
        },
        remplisVertContainer: {
            margin: theme.spacing(4, 0, 1),
        },
        remplisVertTitle: {
            padding: theme.spacing(4, 0, 1),
            fontWeight: theme.fontsWeight.semiBold,
            fontSize: '1.2rem',
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderColor: theme.palette.text.disabled,
        },
        remplisVert: {
            color: '#218a36',
        },
        remplisVertLink: {
            color: 'inherit',
        },
        remplisVertComment: {
            fontWeight: theme.fontsWeight.semiBold,
        },
        formTitle: {
            fontWeight: theme.fontsWeight.semiBold,
            textTransform: 'uppercase',
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(1),
        },
        noPaddingTop: {
            paddingTop: 0,
        },
        error: {
            color: theme.palette.error.main,
        },
        options: {
            '&.MuiFormControlLabel-root': {
                marginLeft: theme.spacing(4),
                marginRight: 0,
            },
            '& .MuiFormControlLabel-label': {
                fontSize: '0.7rem',
                textTransform: 'none',
            },
        },
        imageOptions: {
            '&.MuiFormControlLabel-root': {
                marginLeft: 0,
                width: '100%',
            },
            '& .MuiFormControlLabel-label': {
                fontSize: 'inherit',
                textTransform: 'none',
            },
            'borderColor': theme.palette.border.main,
            'borderBottomWidth': 1,
            'borderBottomStyle': 'solid',
            'marginBottom': theme.spacing(),
        },
        flippableContainer: {
            position: 'relative',
            transition: 'transform 0.6s',
            transformStyle: 'preserve-3d',
        },
        flippable: {
            backfaceVisibility: 'hidden',
        },
        flipFront: {
            backgroundColor: theme.palette.common.white,
        },
        flip: {
            transform: 'rotateY(180deg)',
        },
        flipBack: {
            position: 'absolute',
            inset: 0,
            backfaceVisibility: 'hidden',
            transform: 'rotateY(180deg)',
            backgroundColor: theme.palette.common.white,
            overflow: 'auto',
        },
        show: {
            position: 'static',
            transform: 'none',
        },
        imageContainer: {
            width: '100%',
            height: '100%',
        },
        imageContainerHidden: {
            visibility: 'hidden',
        },
        share: {
            '&.MuiFormControl-root': {
                padding: theme.spacing(0, 2),
            },
        },
        uploader: {
            'height': 0,
            'paddingTop': '100%',
            'marginTop': 0,
            '& > div > div': {
                borderStyle: 'solid',
                borderRadius: 0,
            },
            '& .MuiSvgIcon-root': {
                fontSize: '8rem !important',
                [theme.breakpoints.down('xs')]: {
                    fontSize: '5rem !important',
                },
            },
        },
        uploaderMargin: {
            [theme.breakpoints.up('sm')]: {
                marginTop: 38,
            },
        },
        loading: {
            position: 'relative',
            width: '100%',
            height: 200,
        },
    }),
)
