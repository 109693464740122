import NotificationLayout from 'components/layouts/notificationLayout'
import { useNotifications } from 'core/services/common/useNotifications'
import React, { useState } from 'react'

const NotificationCenter: React.FC = () => {
    const [page, setPage] = useState(1)
    const [pageSize] = useState(5)

    const { notifications, loading, hasMore, setNotificationsPage } = useNotifications(pageSize)

    const pageCount = Math.ceil(notifications.length / pageSize)
    const handleSetPage = (page: number) => {
        setNotificationsPage(page, (page) => setPage(page))
    }

    const start = (page - 1) * pageSize
    const end = start + pageSize
    return (
        <NotificationLayout
            notifications={notifications.slice(start, end)}
            loading={loading}
            page={page}
            pageCount={page > pageCount + (hasMore ? 1 : 0) ? page : pageCount + (hasMore ? 1 : 0)}
            setPage={handleSetPage}
        />
    )
}

export default NotificationCenter
